<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Order Details</h5>
    <a (click)="close()">Close</a>
  </div>
  <div class="modal-body">

    @if (order) {
      <electricity-order-details
        [order]="order"
      />
    }

    <button
      (click)="selectPaymentMethod()"
      class="btn btn-outline-primary btn-block btn-lg mt-2">
      Pay
    </button>
  </div>
</div>

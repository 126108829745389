import { Component, Input, OnInit } from '@angular/core';
import { SmartCardPojo } from '../../../../../sdk/util-sdk';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Utils } from '../../../shared/utils/utils';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'smart-card-details',
  templateUrl: './smart-card-details.component.html',
  styleUrls: ['./smart-card-details.component.scss'],
  imports: [
    DatePipe,
    IonicModule
  ],
  standalone: true
})
export class SmartCardDetailsComponent  implements OnInit {

  @Input() smartCard: SmartCardPojo;
  @Input() showLogo = true;

  constructor() { }

  ngOnInit() {}

  icon(provider: SmartCardPojo.ProviderEnum) {
    return Utils.tvIconPath(provider);
  }

  name(provider: SmartCardPojo.ProviderEnum) {
    return Utils.tvName(provider);
  }

  now(): Date {
    return new Date();
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {HeaderComponent} from "./components/navigation/header/header.component";
import { MenuComponent } from './components/navigation/menu/menu.component';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user/user.model';
import { UnsubscribeOnDestroyAdapter } from './shared/utils/unsubscribe-on-destroy-adapter';
import { addIcons } from 'ionicons';
import {  menuOutline, homeOutline, documentTextOutline, settingsOutline, closeOutline, pieChartOutline, cardOutline, moonOutline, chevronBackOutline, notificationsOutline, appsOutline} from 'ionicons/icons';

declare var Splide: any;

@Component({
    selector: 'app-root',
    imports: [
      RouterOutlet,
      HeaderComponent,
      MenuComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {

  title = 'util-ui';
  user: User

  constructor(private authenticationService: AuthenticationService) {
    super();
    addIcons({
      'menu-outline': menuOutline,
      'home-outline': homeOutline,
      'document-text-outline': documentTextOutline,
      'settings-outline': settingsOutline,
      'close-outline': closeOutline,
      'pie-chart-outline': pieChartOutline,
      'card-outline': cardOutline,
      'moon-outline': moonOutline,
      'chevron-back-outline': chevronBackOutline,
      'notifications-outline': notificationsOutline,
      'apps-outline': appsOutline,
    })
  }

  ngOnInit(): void {
    this.subscriptions.sink = this.authenticationService.getUser().subscribe(user => {
      this.user = user;
    });
  }


  get isAuthenticated(): boolean {
    return this.user !== null && this.user !== undefined;
  }

  ngAfterViewInit(): void {
    document.addEventListener('DOMContentLoaded', function () {

      // Full Carousel
      document.querySelectorAll('.carousel-full').forEach(carousel => new Splide(carousel, {
        perPage: 1,
        rewind: true,
        type: "loop",
        gap: 0,
        arrows: false,
        pagination: false,
      }).mount());

      // Single Carousel
      document.querySelectorAll('.carousel-single').forEach(carousel => new Splide(carousel, {
        perPage: 3,
        rewind: true,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 1
          },
          991: {
            perPage: 2
          }
        }
      }).mount());

      // Multiple Carousel
      document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
        perPage: 4,
        rewind: true,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 2
          },
          991: {
            perPage: 3
          }
        }
      }).mount());

      // Small Carousel
      document.querySelectorAll('.carousel-small').forEach(carousel => new Splide(carousel, {
        perPage: 9,
        rewind: false,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 4
          },
          991: {
            perPage: 7
          }
        }
      }).mount());

      // Slider Carousel
      document.querySelectorAll('.carousel-slider').forEach(carousel => new Splide(carousel, {
        perPage: 1,
        rewind: false,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: true
      }).mount());

      // Stories Carousel
      document.querySelectorAll('.story-block').forEach(carousel => new Splide(carousel, {
        perPage: 16,
        rewind: false,
        type: "slide",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          500: {
            perPage: 4
          },
          768: {
            perPage: 7
          },
          1200: {
            perPage: 11
          }
        }
      }).mount());
    });

  }
}

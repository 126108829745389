
<div id="appCapsule">
  <div class="section mt-3">
    <div class="section-title">Network Provider</div>
    <div class="row">
      @for (provider of providers; track provider) {
        <div class="col-3">
          <a (click)="selectProvider(provider)">
            <div class="service-box" [class.highlighted]="selectedProvider == provider">
              <img [src]=icon(provider) alt=""/>
            </div>
            <h5 class="mt-2 text-center dark-text">{{ name(provider) | titlecase }}</h5>
          </a>
        </div>
      }
    </div>
    <div class="mt-2">
      <div class="title-row">
        <div class="section-title">Phone Number</div>
        <span class="beneficiaries" (click)="selectBeneficiary()"> Beneficiaries
            <ion-icon class="beneficiaries ion-icon" name="chevron-forward-outline" />
        </span>
      </div>
      <form [formGroup]="form">
        <div class="form-group basic">
          <div class="input-wrapper">
            <input
              class="form-control"
              [formControlName]="'phoneNumber'"
              [type]="'text'"
              [placeholder]="'Enter Phone Number'">
            @if (validatingPhoneNumber) {
              <div class="form-spinner-wrapper">
                <i class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></i>
              </div>
            }
          </div>
          <error-message
            [controlName]="'phoneNumber'"
            [singularError]="true"
            [form]="form"
          />
        </div>
      </form>

      <div class="section-title mt-2">Select Amount</div>

      <div class="row mt-2">
        @for (amount of preSelectTotals; track amount) {
          <div class="col-4" (click)="selectTotal(amount)">
            <div class="amount-box" [class.selected]="totalSelected(amount)">
              <div class="value"> {{ amount | currency : '₦' : 'symbol' : '1.0-0'}} </div>
            </div>
          </div>
        }
      </div>
      <form [formGroup]="form" class="mt-3">
        <div class="form-group basic">
          <div class="input-wrapper">
            <input
              MoneyInput
              [formControlName]="'total'"
              type="text" class="form-control" [placeholder]="'Enter Amount'">
            <i class="clear-input">
              <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
            </i>
          </div>
        </div>
      </form>
      <button
        (click)="viewOrder();"
        class="btn btn-outline-primary btn-block btn-lg mt-2">
        @if (validatingOrder) {
          <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
        }
        Pay
      </button>
    </div>
  </div>
</div>

<div id="appCapsule">
  <div class="section mt-2">
    <div class="card">
      <div class="section">
        <div class="listed-detail mt-1">
          <div class="icon-wrapper">
            <img [src]="icon" alt="image" class="imaged w48 rounded">
          </div>
          <h4 class="text-center mt-1">{{ name }}</h4>
          <h2 class="text-center text-primary mt-2">{{ transaction.amount | currency : '₦' : 'symbol' : '1.0-0' }}</h2>
          <h4 class="text-center d-flex align-items-center justify-content-center transaction-status"
              [class]="statusColor">
            @if(statusIcon) {
              <ion-icon [name]="statusIcon" class="icon"></ion-icon>
            }
            <span>{{ status }}</span>
          </h4>
          @if (status != 'Successful') {
            <div class="text-center mt-2">
              <button type="button" class="btn btn-outline-primary btn-sm me-1 mb-1"
                      (click)="doAction()">
                <ion-icon [name]="actionIcon"></ion-icon>
                {{ action }}
              </button>
            </div>
          }
        </div>
      </div>
    </div>

    @if(electricity?.token) {
      <div class="card mt-3">
        <div class="section">
          <ul class="listview flush transparent simple-listview no-space">
            <li>
              <strong>Token</strong>
              <div class="d-flex align-items-center justify-content-between"
                   (click)="copyToClipboard(electricity.token)">
                <span class="token-value">{{ electricity.token }}</span>
                <ion-icon name="copy-outline" class="copy-icon"></ion-icon>
              </div>
            </li>
          </ul>
        </div>
      </div>
    }

    <div class="card mt-2">
      @switch (transaction.serviceHead) {
        @case (ServiceHeadEnum.ELECTRICITY) {
          <electricity-order-details [order]="electricity" [showLogo]="false"/>
        }
        @case (ServiceHeadEnum.AIRTIME) {
          <airtime-order-details [order]="airtime" [showLogo]="false"/>
        }
        @case (ServiceHead.DATA) {
          <data-order-details [order]="data" [showLogo]="false"/>
        }
        @case (ServiceHead.TV) {
          <television-order-details [order]="television" [showLogo]="false"/>
        }
      }
    </div>
  </div>
  <div class="section mt-2">
    <div class="row">
      <div class="col-6">
        <a class="btn btn-lg btn-outline-secondary btn-block">Report Issue</a>

      </div>
      <div class="col-6">
        <a class="btn btn-lg btn-primary btn-block">Share Receipt</a>

      </div>
    </div>
  </div>
</div>

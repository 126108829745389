// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: '/api',
  siteUrl: 'https://sandbox.util.ng/api',
  brandName: 'Util Pay',
  keycloakConfig: {
    clientId: 'util-frontend',
    realm: 'util',
    url: 'https://auth.serverna.com.ng/auth',
  },
  sessionTimeout: 1440,
  defaultCountry: 'NGA',
  recaptcha: {
    siteKey: '6LcaD7soAAAAACRHrPdsCM8BtR3Ax7GW5v7_sWNt'
  },
  squadPublicKey: 'sandbox_pk_77b6dd535a4029f55a91548cea0219e71748df4e2e51',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  dateFormat: 'YYYY-MM-DD',
  darkMode: {
    default: true, // Set dark mode as main theme
    localMode: { // Activate dark mode between certain times of the day
      enable: true, // Enable or disable local dark mode
      startTime: 20, // Start at 20:00
      endTime: 7, // End at 07:00
    },
    autoDetect: { // Auto detect user's preferences and activate dark mode
      enable: true,
    }
  }

};

/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ServicePlan { 
    name?: string;
    amount?: number;
    serviceId?: string;
    variationCode?: string;
    dataAmountMb?: number;
    validityDays?: number;
    provider?: ServicePlan.ProviderEnum;
    serviceHead?: ServicePlan.ServiceHeadEnum;
}
export namespace ServicePlan {
    export type ProviderEnum = 'VT_PASS';
    export const ProviderEnum = {
        VT_PASS: 'VT_PASS' as ProviderEnum
    };
    export type ServiceHeadEnum = 'ELECTRICITY' | 'AIRTIME' | 'DATA' | 'TV' | 'EDUCATION' | 'INSURANCE';
    export const ServiceHeadEnum = {
        ELECTRICITY: 'ELECTRICITY' as ServiceHeadEnum,
        AIRTIME: 'AIRTIME' as ServiceHeadEnum,
        DATA: 'DATA' as ServiceHeadEnum,
        TV: 'TV' as ServiceHeadEnum,
        EDUCATION: 'EDUCATION' as ServiceHeadEnum,
        INSURANCE: 'INSURANCE' as ServiceHeadEnum
    };
}



<div class="appBottomMenu">
  <a [routerLink]="''"
     [routerLinkActive]="'active'"
     [routerLinkActiveOptions]="{ exact: true }"
     class="item">
    <div class="col">
      <ion-icon name="home-outline"></ion-icon>
      <strong>Home</strong>
    </div>
  </a>
  <a [routerLink]="'transactions'"
     [routerLinkActive]="'active'"
     class="item">
    <div class="col">
      <ion-icon name="document-text-outline"></ion-icon>
      <strong>Transactions</strong>
    </div>
  </a>
  <a [routerLink]="'settings'"
     [routerLinkActive]="'active'"
     class="item">
    <div class="col">
      <ion-icon name="settings-outline"></ion-icon>
      <strong>Settings</strong>
    </div>
  </a>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscoPojo } from '../../../../../sdk/util-sdk';
import DiscoEnum = DiscoPojo.DiscoEnum;
import { Utils } from '../../../shared/utils/utils';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-disco-selector',
  templateUrl: './disco-selector.component.html',
  styleUrls: ['./disco-selector.component.scss'],
  standalone: true
})
export class DiscoSelectorComponent  implements OnInit {

  @Input() selectedDisco: DiscoEnum;
  @Output() selectedDiscoChange: EventEmitter<DiscoEnum> = new EventEmitter;

  discos: DiscoEnum[] = Utils.enumValues(DiscoEnum).sort((a, b) => a.localeCompare(b));

  constructor(private modalRef: BsModalRef) {

  }

  selectDisco(disco: DiscoEnum): void {
    this.selectedDiscoChange.emit(disco);
    this.close();
  }

  close(): void {
    this.modalRef.hide();
  }

  ngOnInit() {}

  icon(disco: DiscoPojo.DiscoEnum): string {
    return Utils.discoIconPath(disco);
  }

  name(disco: DiscoPojo.DiscoEnum): string {
    return Utils.discoName(disco);
  }
}

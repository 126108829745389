import { Component, Input, OnInit } from '@angular/core';
import { SmartCardPojo, TvOrderDetailsPojo } from '../../../../../sdk/util-sdk';
import ProviderEnum = SmartCardPojo.ProviderEnum;
import { Utils } from '../../../shared/utils/utils';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'television-order-details',
  templateUrl: './television-order-details.component.html',
  styleUrls: ['./television-order-details.component.scss'],
  imports: [
    DatePipe,
    CurrencyPipe
  ],
  standalone: true
})
export class TelevisionOrderDetailsComponent  implements OnInit {

  @Input() showLogo = true;
  @Input() order: TvOrderDetailsPojo;

  constructor() { }

  ngOnInit() {
  }

  icon(provider: ProviderEnum): string {
    return Utils.tvIconPath(provider);
  }

  name(name: ProviderEnum): string {
    return Utils.tvName(name);
  }


  now() {
    return new Date();
  }
}

import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../shared/utils/utils';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AirtimeOrderDetailsPojo,
  DataOrderDetailsPojo, ServicePlan,
  MeterPojo,
  MeterValidationControllerService, VariationsControllerService
} from '../../../../../sdk/util-sdk';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderDialogComponent } from '../../airtime/order-dialog/order-dialog.component';
import { BeneficiariesComponent } from '../../infra/beneficiaries/beneficiaries.component';
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import ProviderEnum = DataOrderDetailsPojo.ProviderEnum;
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../../shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { EmComponent } from '../../../shared/components/em/em.component';
import { CurrencyPipe, KeyValue, KeyValuePipe, TitleCasePipe } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import ServiceHeadEnum = ServicePlan.ServiceHeadEnum;



@Component({
  selector: 'app-data-order',
  templateUrl: './data-order.component.html',
  styleUrls: ['./data-order.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    SharedModule,
    ToastrModule,
    ReactiveFormsModule,
    EmComponent,
    TitleCasePipe,
    CarouselModule,
    KeyValuePipe,
    CurrencyPipe
  ]
})
export class DataOrderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  form: FormGroup;
  validatingOrder = false;
  loadingPlans: any = false;
  validatingPhoneNumber = false;
  selectedPeriod: KeyValue<number, ServicePlan[]> | null = null;
  beneficiariesSubject: Subject<MeterPojo[]> = new Subject();
  beneficiaries$: Observable<MeterPojo[]> = this.beneficiariesSubject.asObservable();
  providers: ProviderEnum[] = Utils.enumValues(ProviderEnum).filter(p => p != ProviderEnum.SMILE);
  plans: Map<ProviderEnum, Map<number, ServicePlan[]>> = new Map<ProviderEnum, Map<number, ServicePlan[]>>();

  constructor(private fb: FormBuilder,
              private bsModalService: BsModalService,
              private variationsService: VariationsControllerService,
              private meterService: MeterValidationControllerService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      provider: ['', Validators.compose([Validators.required])],
      phoneNumber: ['', Validators.compose([Validators.required])],
      variationCode: ['', Validators.compose([Validators.required])]
    });

    this.form.controls.provider.valueChanges.subscribe(v => {
      if (!this.plans.get(v)) this.getPlans(v);
    });

    this.form.patchValue({ provider: this.providers[0] });
  }

  getPlans(provider: ProviderEnum): void {
    this.loadingPlans = provider;
    this.variationsService.getVtServiceVariations({ serviceCode: Utils.networkDataServiceCodes(provider), serviceType: ServiceHeadEnum.DATA })
      .subscribe({
        next: (v: ServicePlan[]) => {
          this.plans.set(provider, this.groupPlans(v));
        }
      }).add(() => this.loadingPlans = false);
  }


  groupPlans(v) : Map<number, ServicePlan[]>  {
    const groupedPlans = new Map<number, ServicePlan[]>();
    v.forEach(plan => {
      if (!groupedPlans.has(plan.validityDays)) {
        groupedPlans.set(plan.validityDays, []);
      }
      groupedPlans.get(plan.validityDays)!.push(plan);
    });
    return groupedPlans;
  }

  viewOrder(): void {
    if (this.form.invalid) return;
    this.showDetails(this.getDto());
  }

  private getDto(): DataOrderDetailsPojo {
    const dataPlan = this.selectedServicePlan();
    const data: DataOrderDetailsPojo = this.form.getRawValue();
    data.dataAmountMb = dataPlan?.dataAmountMb;
    data.validityDays = dataPlan?.validityDays;
    data.provider = this.selectedProvider;
    data.serviceType = ServiceHeadEnum.DATA;
    data.total = dataPlan?.amount;
    data.serviceId = dataPlan.serviceId;
    data.variationCode = dataPlan.variationCode;
    return data;
  }

  selectedServicePlan(): ServicePlan | undefined {
    const variationCode = this.form.controls.variationCode.value;
    for (const providerPlans of this.plans.values()) {
      for (const periodPlans of providerPlans.values()) {
        const selectedPlan = periodPlans.find(plan => plan.variationCode === variationCode);
        if (selectedPlan) {
          return selectedPlan;
        }
      }
    }
    return undefined;
  }

  showDetails(order: DataOrderDetailsPojo): void {
    this.bsModalService.show(OrderDialogComponent, {
      initialState: {
        order
      },
      class: 'modal-bottom-drawer'
    });
  }

  loadBeneficiaries(): void {
    this.meterService.getMeterBeneficiaries()
      .subscribe({
        next: (v: MeterPojo[]) => {
          this.beneficiariesSubject.next(v);
        }
      });
  }

  selectBeneficiary(): void {
    this.loadBeneficiaries();
    const ref = this.bsModalService.show(BeneficiariesComponent, {
      initialState: {
        beneficiaries$: this.beneficiaries$
      },
      class: 'modal-bottom-drawer'
    });

    ref.content.selectedBeneficiaryChange.subscribe((beneficiary) => {
      this.form.patchValue({ meterNumber: beneficiary.meterNumber });
    });

  }

    //////////////////
   ////UI Helpers////
  //////////////////

  icon(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkIconPath(disco);
  }

  name(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkName(disco);
  }

  periodTitle(key: number) {
    return Utils.periodTitle(key);
  }

  dataPlanTitle(plan: ServicePlan): string {
    return Utils.dataPlanTitle(plan.dataAmountMb);
  }


    //////////////////
   ///Data Helpers///
  //////////////////

  selectPlan(plan: ServicePlan) {
    this.form.patchValue({
      variationCode: plan.variationCode,
    });
  }

  planSelected(plan: ServicePlan): boolean {
    return this.form.controls?.variationCode?.value == plan.variationCode
  }

  get networkPlanPeriods(): ReadonlyMap<number, ServicePlan[]> {
    const plans = this.plans.get(this.selectedProvider);
    return plans || new Map<number, ServicePlan[]>();
  }


  selectProvider(provider: string): void {
    this.form.controls.provider.setValue(provider);
  }

  get selectedProvider(): ProviderEnum {
    return this.form.controls.provider.value;
  }

  selectPeriod(period: KeyValue<number, ServicePlan[]>) {
    if (this.selectedPeriod != period)
      this.form.patchValue({
        variationCode: ''
      });

    this.selectedPeriod = period;
  }

  get selectedPeriodPlans(): ServicePlan[] {
    return this.selectedPeriod?.value || [];
  }

  periodSelected(number: number): boolean {
    return this.selectedPeriod?.key == number;
  }

}

/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Invoice } from './invoice';
import { Currency } from './currency';
import { FwChargeCard } from './fw-charge-card';
import { PortalUser } from './portal-user';


export interface PaymentTransaction { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PaymentTransaction.StatusEnum;
    deactivatedBy?: PortalUser;
    notificationId?: string;
    transactionStatus?: PaymentTransaction.TransactionStatusEnum;
    amount?: number;
    paymentProviderReference?: string;
    paymentDate?: string;
    payerName?: string;
    payerEmail?: string;
    payerPhoneNumber?: string;
    paymentProvider?: PaymentTransaction.PaymentProviderEnum;
    svPaymentReference?: string;
    externalReceiptNumber?: string;
    paymentReference?: string;
    originalTransactionId?: string;
    sourceAmount?: number;
    cancelledAt?: string;
    reversedAt?: string;
    paymentChannel?: PaymentTransaction.PaymentChannelEnum;
    invoice?: Invoice;
    currency?: Currency;
    fwChargeCard?: FwChargeCard;
}
export namespace PaymentTransaction {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type TransactionStatusEnum = 'NOT_YET_RECEIVED' | 'PENDING' | 'SUCCESSFUL' | 'CANCELED' | 'FAILED' | 'REVERSED' | 'PARTIAL';
    export const TransactionStatusEnum = {
        NOT_YET_RECEIVED: 'NOT_YET_RECEIVED' as TransactionStatusEnum,
        PENDING: 'PENDING' as TransactionStatusEnum,
        SUCCESSFUL: 'SUCCESSFUL' as TransactionStatusEnum,
        CANCELED: 'CANCELED' as TransactionStatusEnum,
        FAILED: 'FAILED' as TransactionStatusEnum,
        REVERSED: 'REVERSED' as TransactionStatusEnum,
        PARTIAL: 'PARTIAL' as TransactionStatusEnum
    };
    export type PaymentProviderEnum = 'FLUTTERWAVE' | 'SQUAD';
    export const PaymentProviderEnum = {
        FLUTTERWAVE: 'FLUTTERWAVE' as PaymentProviderEnum,
        SQUAD: 'SQUAD' as PaymentProviderEnum
    };
    export type PaymentChannelEnum = 'USSD' | 'CASH' | 'TRANSFER' | 'CARD';
    export const PaymentChannelEnum = {
        USSD: 'USSD' as PaymentChannelEnum,
        CASH: 'CASH' as PaymentChannelEnum,
        TRANSFER: 'TRANSFER' as PaymentChannelEnum,
        CARD: 'CARD' as PaymentChannelEnum
    };
}



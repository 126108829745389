/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentInvoiceReference { 
    transactionId?: string;
    reference?: string;
    amount?: number;
    providerConstant?: PaymentInvoiceReference.ProviderConstantEnum;
}
export namespace PaymentInvoiceReference {
    export type ProviderConstantEnum = 'FLUTTERWAVE' | 'SQUAD';
    export const ProviderConstantEnum = {
        FLUTTERWAVE: 'FLUTTERWAVE' as ProviderConstantEnum,
        SQUAD: 'SQUAD' as ProviderConstantEnum
    };
}



import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.scss'],
    imports: [
        RouterOutlet
    ]
})
export class DataComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}

<div id="appCapsule">

  @for (entry of groupedTransactions | keyvalue : sortDescending; track entry) {
    <!-- Transactions -->
    <div class="section mt-2">
      <div class="section-title">{{ day(entry.key) }}</div>
      <div class="transactions">
        @for(transaction of entry.value; track $index) {
          <!-- item -->
          <a class="item" (click)="viewDetails(transaction)">
            <div class="detail">
              <img  [src]="getImageIcon(transaction)" alt="img" class="image-block imaged w48">
              <div>
                <strong>{{ transaction.transactionLineItem }}</strong>
                <p>{{ transaction.serviceHead | uppercase }}</p>
              </div>
            </div>
            <div class="right text-end">
              <div class="price"> {{ transaction?.amount | currency : '₦' : 'symbol' : '1.0-0' }} </div>
              <div class="fontsize-sub" [ngClass]="statusColor(transaction)">
                {{ transactionStatus(transaction) }}
              </div>
            </div>
          </a>
          <!-- * item -->
        }
      </div>
    </div>
    <!-- * Transactions -->
  }

  @if(hasMore()) {
    <div class="section mt-2 mb-2">
      <a class="btn btn-primary btn-block btn-lg" (click)="loadMore()">Load More</a>
    </div>
  }

</div>

import * as i0 from '@angular/core';
import { Directive, HostListener, Component, ChangeDetectionStrategy, Attribute, Optional, SkipSelf, ViewChild, ContentChild, ContentChildren, forwardRef, Injectable, inject, Injector, EnvironmentInjector, APP_INITIALIZER, NgZone, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, MaxValidator, MinValidator } from '@angular/forms';
import * as i2$1 from '@ionic/angular/common';
import { ValueAccessor, setIonicClasses, IonRouterOutlet as IonRouterOutlet$1, IonTabs as IonTabs$1, IonBackButton as IonBackButton$1, IonNav as IonNav$1, RouterLinkDelegateDirective as RouterLinkDelegateDirective$1, RouterLinkWithHrefDelegateDirective as RouterLinkWithHrefDelegateDirective$1, IonModal as IonModal$1, IonPopover as IonPopover$1, OverlayBaseController, MenuController as MenuController$1, AngularDelegate, raf, ConfigToken, provideComponentInputBinding } from '@ionic/angular/common';
const _c0 = ["*"];
const _c1 = ["outlet"];
const _c2 = [[["", "slot", "top"]], "*"];
const _c3 = ["[slot=top]", "*"];
function IonModal_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementContainer(1, 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template);
  }
}
function IonPopover_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template);
  }
}
export { AngularDelegate, Config, DomController, IonicRouteStrategy, NavController, NavParams, Platform } from '@ionic/angular/common';
import { __decorate } from 'tslib';
import { fromEvent } from 'rxjs';
import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i2 from '@angular/router';
import { alertController, createAnimation, getTimeGivenProgression, actionSheetController, createGesture, loadingController, menuController, modalController, pickerController, popoverController, toastController, setupConfig } from '@ionic/core';
export { IonicSafeString, IonicSlides, createAnimation, createGesture, getIonPageElement, getPlatforms, getTimeGivenProgression, iosTransitionAnimation, isPlatform, mdTransitionAnimation, openURL } from '@ionic/core';
import { applyPolyfills, defineCustomElements } from '@ionic/core/loader';
class BooleanValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  writeValue(value) {
    this.elementRef.nativeElement.checked = this.lastValue = value;
    setIonicClasses(this.elementRef);
  }
  _handleIonChange(el) {
    this.handleValueChange(el, el.checked);
  }
}
/** @nocollapse */
BooleanValueAccessorDirective.ɵfac = function BooleanValueAccessorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BooleanValueAccessorDirective)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ElementRef));
};
/** @nocollapse */
BooleanValueAccessorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BooleanValueAccessorDirective,
  selectors: [["ion-checkbox"], ["ion-toggle"]],
  hostBindings: function BooleanValueAccessorDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function BooleanValueAccessorDirective_ionChange_HostBindingHandler($event) {
        return ctx._handleIonChange($event.target);
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BooleanValueAccessorDirective,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BooleanValueAccessorDirective, [{
    type: Directive,
    args: [{
      selector: 'ion-checkbox,ion-toggle',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessorDirective,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();
class NumericValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  handleInputEvent(el) {
    this.handleValueChange(el, el.value);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      fn(value === '' ? null : parseFloat(value));
    });
  }
}
/** @nocollapse */
NumericValueAccessorDirective.ɵfac = function NumericValueAccessorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NumericValueAccessorDirective)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ElementRef));
};
/** @nocollapse */
NumericValueAccessorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NumericValueAccessorDirective,
  selectors: [["ion-input", "type", "number"]],
  hostBindings: function NumericValueAccessorDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionInput", function NumericValueAccessorDirective_ionInput_HostBindingHandler($event) {
        return ctx.handleInputEvent($event.target);
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumericValueAccessorDirective,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericValueAccessorDirective, [{
    type: Directive,
    args: [{
      selector: 'ion-input[type=number]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumericValueAccessorDirective,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  }, {
    handleInputEvent: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  });
})();
class RadioValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleIonSelect(el) {
    /**
     * The `el` type is any to access the `checked` state property
     * that is not exposed on the type interface.
     */
    this.handleValueChange(el, el.checked);
  }
}
/** @nocollapse */
RadioValueAccessorDirective.ɵfac = function RadioValueAccessorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadioValueAccessorDirective)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ElementRef));
};
/** @nocollapse */
RadioValueAccessorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RadioValueAccessorDirective,
  selectors: [["ion-radio"]],
  hostBindings: function RadioValueAccessorDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionSelect", function RadioValueAccessorDirective_ionSelect_HostBindingHandler($event) {
        return ctx._handleIonSelect($event.target);
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RadioValueAccessorDirective,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioValueAccessorDirective, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'ion-radio',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: RadioValueAccessorDirective,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleIonSelect: [{
      type: HostListener,
      args: ['ionSelect', ['$event.target']]
    }]
  });
})();
class SelectValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleChangeEvent(el) {
    this.handleValueChange(el, el.value);
  }
}
/** @nocollapse */
SelectValueAccessorDirective.ɵfac = function SelectValueAccessorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SelectValueAccessorDirective)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ElementRef));
};
/** @nocollapse */
SelectValueAccessorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SelectValueAccessorDirective,
  selectors: [["ion-select"], ["ion-radio-group"], ["ion-segment"], ["ion-datetime"]],
  hostBindings: function SelectValueAccessorDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function SelectValueAccessorDirective_ionChange_HostBindingHandler($event) {
        return ctx._handleChangeEvent($event.target);
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SelectValueAccessorDirective,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectValueAccessorDirective, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'ion-select, ion-radio-group, ion-segment, ion-datetime',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectValueAccessorDirective,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleChangeEvent: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();

// TODO(FW-5495): rename class since range isn't a text component
class TextValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleInputEvent(el) {
    this.handleValueChange(el, el.value);
  }
}
/** @nocollapse */
TextValueAccessorDirective.ɵfac = function TextValueAccessorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TextValueAccessorDirective)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ElementRef));
};
/** @nocollapse */
TextValueAccessorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TextValueAccessorDirective,
  selectors: [["ion-input", 3, "type", "number"], ["ion-textarea"], ["ion-searchbar"], ["ion-range"]],
  hostBindings: function TextValueAccessorDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionInput", function TextValueAccessorDirective_ionInput_HostBindingHandler($event) {
        return ctx._handleInputEvent($event.target);
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextValueAccessorDirective,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextValueAccessorDirective, [{
    type: Directive,
    args: [{
      selector: 'ion-input:not([type=number]),ion-textarea,ion-searchbar,ion-range',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessorDirective,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleInputEvent: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  });
})();

/* eslint-disable */
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let IonAccordion = class IonAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAccordion.ɵfac = function IonAccordion_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAccordion)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAccordion.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAccordion,
  selectors: [["ion-accordion"]],
  inputs: {
    disabled: "disabled",
    mode: "mode",
    readonly: "readonly",
    toggleIcon: "toggleIcon",
    toggleIconSlot: "toggleIconSlot",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAccordion_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAccordion = __decorate([ProxyCmp({
  inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
})], IonAccordion);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAccordion, [{
    type: Component,
    args: [{
      selector: 'ion-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonAccordionGroup = class IonAccordionGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonAccordionGroup.ɵfac = function IonAccordionGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAccordionGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAccordionGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAccordionGroup,
  selectors: [["ion-accordion-group"]],
  inputs: {
    animated: "animated",
    disabled: "disabled",
    expand: "expand",
    mode: "mode",
    multiple: "multiple",
    readonly: "readonly",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAccordionGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAccordionGroup = __decorate([ProxyCmp({
  inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
})], IonAccordionGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAccordionGroup, [{
    type: Component,
    args: [{
      selector: 'ion-accordion-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonActionSheet = class IonActionSheet {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionActionSheetDidPresent', 'ionActionSheetWillPresent', 'ionActionSheetWillDismiss', 'ionActionSheetDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonActionSheet.ɵfac = function IonActionSheet_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonActionSheet)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonActionSheet.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonActionSheet,
  selectors: [["ion-action-sheet"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonActionSheet_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonActionSheet = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonActionSheet);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonActionSheet, [{
    type: Component,
    args: [{
      selector: 'ion-action-sheet',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonAlert = class IonAlert {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionAlertDidPresent', 'ionAlertWillPresent', 'ionAlertWillDismiss', 'ionAlertDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonAlert.ɵfac = function IonAlert_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAlert)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAlert.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAlert,
  selectors: [["ion-alert"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    inputs: "inputs",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAlert_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAlert = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonAlert);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAlert, [{
    type: Component,
    args: [{
      selector: 'ion-alert',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonApp = class IonApp {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonApp.ɵfac = function IonApp_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonApp)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonApp.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonApp,
  selectors: [["ion-app"]],
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonApp_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonApp = __decorate([ProxyCmp({})], IonApp);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonApp, [{
    type: Component,
    args: [{
      selector: 'ion-app',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonAvatar = class IonAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAvatar.ɵfac = function IonAvatar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAvatar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAvatar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAvatar,
  selectors: [["ion-avatar"]],
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAvatar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAvatar = __decorate([ProxyCmp({})], IonAvatar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAvatar, [{
    type: Component,
    args: [{
      selector: 'ion-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBackdrop = class IonBackdrop {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionBackdropTap']);
  }
};
/** @nocollapse */
IonBackdrop.ɵfac = function IonBackdrop_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBackdrop)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBackdrop.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBackdrop,
  selectors: [["ion-backdrop"]],
  inputs: {
    stopPropagation: "stopPropagation",
    tappable: "tappable",
    visible: "visible"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBackdrop_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBackdrop = __decorate([ProxyCmp({
  inputs: ['stopPropagation', 'tappable', 'visible']
})], IonBackdrop);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBackdrop, [{
    type: Component,
    args: [{
      selector: 'ion-backdrop',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['stopPropagation', 'tappable', 'visible']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBadge = class IonBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonBadge.ɵfac = function IonBadge_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBadge.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBadge,
  selectors: [["ion-badge"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBadge_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBadge = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBadge, [{
    type: Component,
    args: [{
      selector: 'ion-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBreadcrumb = class IonBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonBreadcrumb.ɵfac = function IonBreadcrumb_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBreadcrumb.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBreadcrumb,
  selectors: [["ion-breadcrumb"]],
  inputs: {
    active: "active",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    separator: "separator",
    target: "target"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBreadcrumb_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBreadcrumb = __decorate([ProxyCmp({
  inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
})], IonBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBreadcrumb, [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBreadcrumbs = class IonBreadcrumbs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCollapsedClick']);
  }
};
/** @nocollapse */
IonBreadcrumbs.ɵfac = function IonBreadcrumbs_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBreadcrumbs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBreadcrumbs.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBreadcrumbs,
  selectors: [["ion-breadcrumbs"]],
  inputs: {
    color: "color",
    itemsAfterCollapse: "itemsAfterCollapse",
    itemsBeforeCollapse: "itemsBeforeCollapse",
    maxItems: "maxItems",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBreadcrumbs_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBreadcrumbs = __decorate([ProxyCmp({
  inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
})], IonBreadcrumbs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBreadcrumbs, [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumbs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonButton = class IonButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonButton.ɵfac = function IonButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonButton,
  selectors: [["ion-button"]],
  inputs: {
    buttonType: "buttonType",
    color: "color",
    disabled: "disabled",
    download: "download",
    expand: "expand",
    fill: "fill",
    form: "form",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    size: "size",
    strong: "strong",
    target: "target",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonButton = __decorate([ProxyCmp({
  inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
})], IonButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonButton, [{
    type: Component,
    args: [{
      selector: 'ion-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonButtons = class IonButtons {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonButtons.ɵfac = function IonButtons_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonButtons)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonButtons.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonButtons,
  selectors: [["ion-buttons"]],
  inputs: {
    collapse: "collapse"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonButtons_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonButtons = __decorate([ProxyCmp({
  inputs: ['collapse']
})], IonButtons);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonButtons, [{
    type: Component,
    args: [{
      selector: 'ion-buttons',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCard = class IonCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCard.ɵfac = function IonCard_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCard.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCard,
  selectors: [["ion-card"]],
  inputs: {
    button: "button",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    target: "target",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCard_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCard = __decorate([ProxyCmp({
  inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
})], IonCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCard, [{
    type: Component,
    args: [{
      selector: 'ion-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardContent = class IonCardContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardContent.ɵfac = function IonCardContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardContent,
  selectors: [["ion-card-content"]],
  inputs: {
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardContent = __decorate([ProxyCmp({
  inputs: ['mode']
})], IonCardContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardContent, [{
    type: Component,
    args: [{
      selector: 'ion-card-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardHeader = class IonCardHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardHeader.ɵfac = function IonCardHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardHeader,
  selectors: [["ion-card-header"]],
  inputs: {
    color: "color",
    mode: "mode",
    translucent: "translucent"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardHeader = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'translucent']
})], IonCardHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardHeader, [{
    type: Component,
    args: [{
      selector: 'ion-card-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'translucent']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardSubtitle = class IonCardSubtitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardSubtitle.ɵfac = function IonCardSubtitle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardSubtitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardSubtitle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardSubtitle,
  selectors: [["ion-card-subtitle"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardSubtitle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardSubtitle = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonCardSubtitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardSubtitle, [{
    type: Component,
    args: [{
      selector: 'ion-card-subtitle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardTitle = class IonCardTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardTitle.ɵfac = function IonCardTitle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardTitle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardTitle,
  selectors: [["ion-card-title"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardTitle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardTitle = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonCardTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardTitle, [{
    type: Component,
    args: [{
      selector: 'ion-card-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCheckbox = class IonCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonCheckbox.ɵfac = function IonCheckbox_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCheckbox,
  selectors: [["ion-checkbox"]],
  inputs: {
    alignment: "alignment",
    checked: "checked",
    color: "color",
    disabled: "disabled",
    indeterminate: "indeterminate",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCheckbox = __decorate([ProxyCmp({
  inputs: ['alignment', 'checked', 'color', 'disabled', 'indeterminate', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
})], IonCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCheckbox, [{
    type: Component,
    args: [{
      selector: 'ion-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'checked', 'color', 'disabled', 'indeterminate', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonChip = class IonChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonChip.ɵfac = function IonChip_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonChip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonChip.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonChip,
  selectors: [["ion-chip"]],
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    outline: "outline"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonChip_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonChip = __decorate([ProxyCmp({
  inputs: ['color', 'disabled', 'mode', 'outline']
})], IonChip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonChip, [{
    type: Component,
    args: [{
      selector: 'ion-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'mode', 'outline']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCol = class IonCol {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCol.ɵfac = function IonCol_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCol)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCol.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCol,
  selectors: [["ion-col"]],
  inputs: {
    offset: "offset",
    offsetLg: "offsetLg",
    offsetMd: "offsetMd",
    offsetSm: "offsetSm",
    offsetXl: "offsetXl",
    offsetXs: "offsetXs",
    pull: "pull",
    pullLg: "pullLg",
    pullMd: "pullMd",
    pullSm: "pullSm",
    pullXl: "pullXl",
    pullXs: "pullXs",
    push: "push",
    pushLg: "pushLg",
    pushMd: "pushMd",
    pushSm: "pushSm",
    pushXl: "pushXl",
    pushXs: "pushXs",
    size: "size",
    sizeLg: "sizeLg",
    sizeMd: "sizeMd",
    sizeSm: "sizeSm",
    sizeXl: "sizeXl",
    sizeXs: "sizeXs"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCol_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCol = __decorate([ProxyCmp({
  inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
})], IonCol);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCol, [{
    type: Component,
    args: [{
      selector: 'ion-col',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonContent = class IonContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionScrollStart', 'ionScroll', 'ionScrollEnd']);
  }
};
/** @nocollapse */
IonContent.ɵfac = function IonContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonContent,
  selectors: [["ion-content"]],
  inputs: {
    color: "color",
    forceOverscroll: "forceOverscroll",
    fullscreen: "fullscreen",
    scrollEvents: "scrollEvents",
    scrollX: "scrollX",
    scrollY: "scrollY"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonContent = __decorate([ProxyCmp({
  inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY'],
  methods: ['getScrollElement', 'scrollToTop', 'scrollToBottom', 'scrollByPoint', 'scrollToPoint']
})], IonContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonContent, [{
    type: Component,
    args: [{
      selector: 'ion-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonDatetime = class IonDatetime {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCancel', 'ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonDatetime.ɵfac = function IonDatetime_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonDatetime)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonDatetime.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonDatetime,
  selectors: [["ion-datetime"]],
  inputs: {
    cancelText: "cancelText",
    clearText: "clearText",
    color: "color",
    dayValues: "dayValues",
    disabled: "disabled",
    doneText: "doneText",
    firstDayOfWeek: "firstDayOfWeek",
    formatOptions: "formatOptions",
    highlightedDates: "highlightedDates",
    hourCycle: "hourCycle",
    hourValues: "hourValues",
    isDateEnabled: "isDateEnabled",
    locale: "locale",
    max: "max",
    min: "min",
    minuteValues: "minuteValues",
    mode: "mode",
    monthValues: "monthValues",
    multiple: "multiple",
    name: "name",
    preferWheel: "preferWheel",
    presentation: "presentation",
    readonly: "readonly",
    showClearButton: "showClearButton",
    showDefaultButtons: "showDefaultButtons",
    showDefaultTimeLabel: "showDefaultTimeLabel",
    showDefaultTitle: "showDefaultTitle",
    size: "size",
    titleSelectedDatesFormatter: "titleSelectedDatesFormatter",
    value: "value",
    yearValues: "yearValues"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonDatetime_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonDatetime = __decorate([ProxyCmp({
  inputs: ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'formatOptions', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues'],
  methods: ['confirm', 'reset', 'cancel']
})], IonDatetime);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonDatetime, [{
    type: Component,
    args: [{
      selector: 'ion-datetime',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'formatOptions', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonDatetimeButton = class IonDatetimeButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonDatetimeButton.ɵfac = function IonDatetimeButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonDatetimeButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonDatetimeButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonDatetimeButton,
  selectors: [["ion-datetime-button"]],
  inputs: {
    color: "color",
    datetime: "datetime",
    disabled: "disabled",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonDatetimeButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonDatetimeButton = __decorate([ProxyCmp({
  inputs: ['color', 'datetime', 'disabled', 'mode']
})], IonDatetimeButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonDatetimeButton, [{
    type: Component,
    args: [{
      selector: 'ion-datetime-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'datetime', 'disabled', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFab = class IonFab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFab.ɵfac = function IonFab_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFab.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFab,
  selectors: [["ion-fab"]],
  inputs: {
    activated: "activated",
    edge: "edge",
    horizontal: "horizontal",
    vertical: "vertical"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFab_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFab = __decorate([ProxyCmp({
  inputs: ['activated', 'edge', 'horizontal', 'vertical'],
  methods: ['close']
})], IonFab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFab, [{
    type: Component,
    args: [{
      selector: 'ion-fab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'edge', 'horizontal', 'vertical']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFabButton = class IonFabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonFabButton.ɵfac = function IonFabButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFabButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFabButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFabButton,
  selectors: [["ion-fab-button"]],
  inputs: {
    activated: "activated",
    closeIcon: "closeIcon",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    show: "show",
    size: "size",
    target: "target",
    translucent: "translucent",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFabButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFabButton = __decorate([ProxyCmp({
  inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
})], IonFabButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFabButton, [{
    type: Component,
    args: [{
      selector: 'ion-fab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFabList = class IonFabList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFabList.ɵfac = function IonFabList_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFabList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFabList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFabList,
  selectors: [["ion-fab-list"]],
  inputs: {
    activated: "activated",
    side: "side"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFabList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFabList = __decorate([ProxyCmp({
  inputs: ['activated', 'side']
})], IonFabList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFabList, [{
    type: Component,
    args: [{
      selector: 'ion-fab-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'side']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFooter = class IonFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFooter.ɵfac = function IonFooter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFooter,
  selectors: [["ion-footer"]],
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFooter = __decorate([ProxyCmp({
  inputs: ['collapse', 'mode', 'translucent']
})], IonFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFooter, [{
    type: Component,
    args: [{
      selector: 'ion-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonGrid = class IonGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonGrid.ɵfac = function IonGrid_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonGrid)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonGrid.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonGrid,
  selectors: [["ion-grid"]],
  inputs: {
    fixed: "fixed"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonGrid_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonGrid = __decorate([ProxyCmp({
  inputs: ['fixed']
})], IonGrid);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonGrid, [{
    type: Component,
    args: [{
      selector: 'ion-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fixed']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonHeader = class IonHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonHeader.ɵfac = function IonHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonHeader,
  selectors: [["ion-header"]],
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonHeader = __decorate([ProxyCmp({
  inputs: ['collapse', 'mode', 'translucent']
})], IonHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonHeader, [{
    type: Component,
    args: [{
      selector: 'ion-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonIcon = class IonIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonIcon.ɵfac = function IonIcon_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonIcon,
  selectors: [["ion-icon"]],
  inputs: {
    color: "color",
    flipRtl: "flipRtl",
    icon: "icon",
    ios: "ios",
    lazy: "lazy",
    md: "md",
    mode: "mode",
    name: "name",
    sanitize: "sanitize",
    size: "size",
    src: "src"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonIcon = __decorate([ProxyCmp({
  inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
})], IonIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonIcon, [{
    type: Component,
    args: [{
      selector: 'ion-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonImg = class IonImg {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionImgWillLoad', 'ionImgDidLoad', 'ionError']);
  }
};
/** @nocollapse */
IonImg.ɵfac = function IonImg_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonImg)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonImg.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonImg,
  selectors: [["ion-img"]],
  inputs: {
    alt: "alt",
    src: "src"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonImg_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonImg = __decorate([ProxyCmp({
  inputs: ['alt', 'src']
})], IonImg);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonImg, [{
    type: Component,
    args: [{
      selector: 'ion-img',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alt', 'src']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonInfiniteScroll = class IonInfiniteScroll {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInfinite']);
  }
};
/** @nocollapse */
IonInfiniteScroll.ɵfac = function IonInfiniteScroll_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonInfiniteScroll)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonInfiniteScroll.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonInfiniteScroll,
  selectors: [["ion-infinite-scroll"]],
  inputs: {
    disabled: "disabled",
    position: "position",
    threshold: "threshold"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonInfiniteScroll_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonInfiniteScroll = __decorate([ProxyCmp({
  inputs: ['disabled', 'position', 'threshold'],
  methods: ['complete']
})], IonInfiniteScroll);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonInfiniteScroll, [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'position', 'threshold']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonInfiniteScrollContent = class IonInfiniteScrollContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonInfiniteScrollContent.ɵfac = function IonInfiniteScrollContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonInfiniteScrollContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonInfiniteScrollContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonInfiniteScrollContent,
  selectors: [["ion-infinite-scroll-content"]],
  inputs: {
    loadingSpinner: "loadingSpinner",
    loadingText: "loadingText"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonInfiniteScrollContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonInfiniteScrollContent = __decorate([ProxyCmp({
  inputs: ['loadingSpinner', 'loadingText']
})], IonInfiniteScrollContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonInfiniteScrollContent, [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['loadingSpinner', 'loadingText']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonInput = class IonInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonInput.ɵfac = function IonInput_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonInput,
  selectors: [["ion-input"]],
  inputs: {
    accept: "accept",
    autocapitalize: "autocapitalize",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    autofocus: "autofocus",
    clearInput: "clearInput",
    clearOnEdit: "clearOnEdit",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    maxlength: "maxlength",
    min: "min",
    minlength: "minlength",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    pattern: "pattern",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    shape: "shape",
    size: "size",
    spellcheck: "spellcheck",
    step: "step",
    type: "type",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonInput = __decorate([ProxyCmp({
  inputs: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'shape', 'size', 'spellcheck', 'step', 'type', 'value'],
  methods: ['setFocus', 'getInputElement']
})], IonInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonInput, [{
    type: Component,
    args: [{
      selector: 'ion-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'shape', 'size', 'spellcheck', 'step', 'type', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItem = class IonItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItem.ɵfac = function IonItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItem,
  selectors: [["ion-item"]],
  inputs: {
    button: "button",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    detail: "detail",
    detailIcon: "detailIcon",
    disabled: "disabled",
    download: "download",
    fill: "fill",
    href: "href",
    lines: "lines",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    target: "target",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItem = __decorate([ProxyCmp({
  inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
})], IonItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItem, [{
    type: Component,
    args: [{
      selector: 'ion-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemDivider = class IonItemDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemDivider.ɵfac = function IonItemDivider_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemDivider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemDivider,
  selectors: [["ion-item-divider"]],
  inputs: {
    color: "color",
    mode: "mode",
    sticky: "sticky"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemDivider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemDivider = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'sticky']
})], IonItemDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemDivider, [{
    type: Component,
    args: [{
      selector: 'ion-item-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'sticky']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemGroup = class IonItemGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemGroup.ɵfac = function IonItemGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemGroup,
  selectors: [["ion-item-group"]],
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemGroup = __decorate([ProxyCmp({})], IonItemGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemGroup, [{
    type: Component,
    args: [{
      selector: 'ion-item-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemOption = class IonItemOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemOption.ɵfac = function IonItemOption_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemOption,
  selectors: [["ion-item-option"]],
  inputs: {
    color: "color",
    disabled: "disabled",
    download: "download",
    expandable: "expandable",
    href: "href",
    mode: "mode",
    rel: "rel",
    target: "target",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemOption = __decorate([ProxyCmp({
  inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
})], IonItemOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemOption, [{
    type: Component,
    args: [{
      selector: 'ion-item-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemOptions = class IonItemOptions {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSwipe']);
  }
};
/** @nocollapse */
IonItemOptions.ɵfac = function IonItemOptions_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemOptions)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemOptions.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemOptions,
  selectors: [["ion-item-options"]],
  inputs: {
    side: "side"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemOptions_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemOptions = __decorate([ProxyCmp({
  inputs: ['side']
})], IonItemOptions);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemOptions, [{
    type: Component,
    args: [{
      selector: 'ion-item-options',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['side']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemSliding = class IonItemSliding {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionDrag']);
  }
};
/** @nocollapse */
IonItemSliding.ɵfac = function IonItemSliding_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemSliding)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemSliding.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemSliding,
  selectors: [["ion-item-sliding"]],
  inputs: {
    disabled: "disabled"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemSliding_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemSliding = __decorate([ProxyCmp({
  inputs: ['disabled'],
  methods: ['getOpenAmount', 'getSlidingRatio', 'open', 'close', 'closeOpened']
})], IonItemSliding);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemSliding, [{
    type: Component,
    args: [{
      selector: 'ion-item-sliding',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonLabel = class IonLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonLabel.ɵfac = function IonLabel_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonLabel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonLabel.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonLabel,
  selectors: [["ion-label"]],
  inputs: {
    color: "color",
    mode: "mode",
    position: "position"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonLabel_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonLabel = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'position']
})], IonLabel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonLabel, [{
    type: Component,
    args: [{
      selector: 'ion-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'position']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonList = class IonList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonList.ɵfac = function IonList_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonList,
  selectors: [["ion-list"]],
  inputs: {
    inset: "inset",
    lines: "lines",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonList = __decorate([ProxyCmp({
  inputs: ['inset', 'lines', 'mode'],
  methods: ['closeSlidingItems']
})], IonList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonList, [{
    type: Component,
    args: [{
      selector: 'ion-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['inset', 'lines', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonListHeader = class IonListHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonListHeader.ɵfac = function IonListHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonListHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonListHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonListHeader,
  selectors: [["ion-list-header"]],
  inputs: {
    color: "color",
    lines: "lines",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonListHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonListHeader = __decorate([ProxyCmp({
  inputs: ['color', 'lines', 'mode']
})], IonListHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonListHeader, [{
    type: Component,
    args: [{
      selector: 'ion-list-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'lines', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonLoading = class IonLoading {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionLoadingDidPresent', 'ionLoadingWillPresent', 'ionLoadingWillDismiss', 'ionLoadingDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonLoading.ɵfac = function IonLoading_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonLoading)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonLoading.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonLoading,
  selectors: [["ion-loading"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    showBackdrop: "showBackdrop",
    spinner: "spinner",
    translucent: "translucent",
    trigger: "trigger"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonLoading_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonLoading = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonLoading);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonLoading, [{
    type: Component,
    args: [{
      selector: 'ion-loading',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonMenu = class IonMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionWillOpen', 'ionWillClose', 'ionDidOpen', 'ionDidClose']);
  }
};
/** @nocollapse */
IonMenu.ɵfac = function IonMenu_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonMenu,
  selectors: [["ion-menu"]],
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    maxEdgeStart: "maxEdgeStart",
    menuId: "menuId",
    side: "side",
    swipeGesture: "swipeGesture",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonMenu = __decorate([ProxyCmp({
  inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type'],
  methods: ['isOpen', 'isActive', 'open', 'close', 'toggle', 'setOpen']
})], IonMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMenu, [{
    type: Component,
    args: [{
      selector: 'ion-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonMenuButton = class IonMenuButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuButton.ɵfac = function IonMenuButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonMenuButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonMenuButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonMenuButton,
  selectors: [["ion-menu-button"]],
  inputs: {
    autoHide: "autoHide",
    color: "color",
    disabled: "disabled",
    menu: "menu",
    mode: "mode",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonMenuButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonMenuButton = __decorate([ProxyCmp({
  inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
})], IonMenuButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMenuButton, [{
    type: Component,
    args: [{
      selector: 'ion-menu-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonMenuToggle = class IonMenuToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuToggle.ɵfac = function IonMenuToggle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonMenuToggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonMenuToggle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonMenuToggle,
  selectors: [["ion-menu-toggle"]],
  inputs: {
    autoHide: "autoHide",
    menu: "menu"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonMenuToggle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonMenuToggle = __decorate([ProxyCmp({
  inputs: ['autoHide', 'menu']
})], IonMenuToggle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMenuToggle, [{
    type: Component,
    args: [{
      selector: 'ion-menu-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'menu']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonNavLink = class IonNavLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNavLink.ɵfac = function IonNavLink_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonNavLink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonNavLink.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonNavLink,
  selectors: [["ion-nav-link"]],
  inputs: {
    component: "component",
    componentProps: "componentProps",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonNavLink_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonNavLink = __decorate([ProxyCmp({
  inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
})], IonNavLink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonNavLink, [{
    type: Component,
    args: [{
      selector: 'ion-nav-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonNote = class IonNote {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNote.ɵfac = function IonNote_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonNote)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonNote.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonNote,
  selectors: [["ion-note"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonNote_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonNote = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonNote);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonNote, [{
    type: Component,
    args: [{
      selector: 'ion-note',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonPicker = class IonPicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionPickerDidPresent', 'ionPickerWillPresent', 'ionPickerWillDismiss', 'ionPickerDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonPicker.ɵfac = function IonPicker_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonPicker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonPicker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonPicker,
  selectors: [["ion-picker"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    columns: "columns",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    showBackdrop: "showBackdrop",
    trigger: "trigger"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonPicker_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonPicker = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss', 'getColumn']
})], IonPicker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonPicker, [{
    type: Component,
    args: [{
      selector: 'ion-picker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonProgressBar = class IonProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonProgressBar.ɵfac = function IonProgressBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonProgressBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonProgressBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonProgressBar,
  selectors: [["ion-progress-bar"]],
  inputs: {
    buffer: "buffer",
    color: "color",
    mode: "mode",
    reversed: "reversed",
    type: "type",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonProgressBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonProgressBar = __decorate([ProxyCmp({
  inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
})], IonProgressBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonProgressBar, [{
    type: Component,
    args: [{
      selector: 'ion-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRadio = class IonRadio {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonRadio.ɵfac = function IonRadio_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRadio)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRadio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRadio,
  selectors: [["ion-radio"]],
  inputs: {
    alignment: "alignment",
    color: "color",
    disabled: "disabled",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRadio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRadio = __decorate([ProxyCmp({
  inputs: ['alignment', 'color', 'disabled', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
})], IonRadio);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRadio, [{
    type: Component,
    args: [{
      selector: 'ion-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'color', 'disabled', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRadioGroup = class IonRadioGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonRadioGroup.ɵfac = function IonRadioGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRadioGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRadioGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRadioGroup,
  selectors: [["ion-radio-group"]],
  inputs: {
    allowEmptySelection: "allowEmptySelection",
    compareWith: "compareWith",
    name: "name",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRadioGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRadioGroup = __decorate([ProxyCmp({
  inputs: ['allowEmptySelection', 'compareWith', 'name', 'value']
})], IonRadioGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRadioGroup, [{
    type: Component,
    args: [{
      selector: 'ion-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['allowEmptySelection', 'compareWith', 'name', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRange = class IonRange {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionFocus', 'ionBlur', 'ionKnobMoveStart', 'ionKnobMoveEnd']);
  }
};
/** @nocollapse */
IonRange.ɵfac = function IonRange_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRange)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRange.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRange,
  selectors: [["ion-range"]],
  inputs: {
    activeBarStart: "activeBarStart",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    dualKnobs: "dualKnobs",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    min: "min",
    mode: "mode",
    name: "name",
    pin: "pin",
    pinFormatter: "pinFormatter",
    snaps: "snaps",
    step: "step",
    ticks: "ticks",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRange_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRange = __decorate([ProxyCmp({
  inputs: ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'label', 'labelPlacement', 'legacy', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value']
})], IonRange);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRange, [{
    type: Component,
    args: [{
      selector: 'ion-range',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'label', 'labelPlacement', 'legacy', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRefresher = class IonRefresher {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionRefresh', 'ionPull', 'ionStart']);
  }
};
/** @nocollapse */
IonRefresher.ɵfac = function IonRefresher_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRefresher)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRefresher.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRefresher,
  selectors: [["ion-refresher"]],
  inputs: {
    closeDuration: "closeDuration",
    disabled: "disabled",
    mode: "mode",
    pullFactor: "pullFactor",
    pullMax: "pullMax",
    pullMin: "pullMin",
    snapbackDuration: "snapbackDuration"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRefresher_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRefresher = __decorate([ProxyCmp({
  inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration'],
  methods: ['complete', 'cancel', 'getProgress']
})], IonRefresher);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRefresher, [{
    type: Component,
    args: [{
      selector: 'ion-refresher',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRefresherContent = class IonRefresherContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRefresherContent.ɵfac = function IonRefresherContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRefresherContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRefresherContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRefresherContent,
  selectors: [["ion-refresher-content"]],
  inputs: {
    pullingIcon: "pullingIcon",
    pullingText: "pullingText",
    refreshingSpinner: "refreshingSpinner",
    refreshingText: "refreshingText"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRefresherContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRefresherContent = __decorate([ProxyCmp({
  inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
})], IonRefresherContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRefresherContent, [{
    type: Component,
    args: [{
      selector: 'ion-refresher-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonReorder = class IonReorder {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonReorder.ɵfac = function IonReorder_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonReorder)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonReorder.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonReorder,
  selectors: [["ion-reorder"]],
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonReorder_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonReorder = __decorate([ProxyCmp({})], IonReorder);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonReorder, [{
    type: Component,
    args: [{
      selector: 'ion-reorder',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonReorderGroup = class IonReorderGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionItemReorder']);
  }
};
/** @nocollapse */
IonReorderGroup.ɵfac = function IonReorderGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonReorderGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonReorderGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonReorderGroup,
  selectors: [["ion-reorder-group"]],
  inputs: {
    disabled: "disabled"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonReorderGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonReorderGroup = __decorate([ProxyCmp({
  inputs: ['disabled'],
  methods: ['complete']
})], IonReorderGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonReorderGroup, [{
    type: Component,
    args: [{
      selector: 'ion-reorder-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRippleEffect = class IonRippleEffect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRippleEffect.ɵfac = function IonRippleEffect_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRippleEffect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRippleEffect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRippleEffect,
  selectors: [["ion-ripple-effect"]],
  inputs: {
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRippleEffect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRippleEffect = __decorate([ProxyCmp({
  inputs: ['type'],
  methods: ['addRipple']
})], IonRippleEffect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRippleEffect, [{
    type: Component,
    args: [{
      selector: 'ion-ripple-effect',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRow = class IonRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRow.ɵfac = function IonRow_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRow,
  selectors: [["ion-row"]],
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRow = __decorate([ProxyCmp({})], IonRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRow, [{
    type: Component,
    args: [{
      selector: 'ion-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSearchbar = class IonSearchbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionCancel', 'ionClear', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonSearchbar.ɵfac = function IonSearchbar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSearchbar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSearchbar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSearchbar,
  selectors: [["ion-searchbar"]],
  inputs: {
    animated: "animated",
    autocapitalize: "autocapitalize",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    cancelButtonIcon: "cancelButtonIcon",
    cancelButtonText: "cancelButtonText",
    clearIcon: "clearIcon",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    inputmode: "inputmode",
    maxlength: "maxlength",
    minlength: "minlength",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    searchIcon: "searchIcon",
    showCancelButton: "showCancelButton",
    showClearButton: "showClearButton",
    spellcheck: "spellcheck",
    type: "type",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSearchbar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSearchbar = __decorate([ProxyCmp({
  inputs: ['animated', 'autocapitalize', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value'],
  methods: ['setFocus', 'getInputElement']
})], IonSearchbar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSearchbar, [{
    type: Component,
    args: [{
      selector: 'ion-searchbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'autocapitalize', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSegment = class IonSegment {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonSegment.ɵfac = function IonSegment_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSegment)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSegment.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSegment,
  selectors: [["ion-segment"]],
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    scrollable: "scrollable",
    selectOnFocus: "selectOnFocus",
    swipeGesture: "swipeGesture",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSegment_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSegment = __decorate([ProxyCmp({
  inputs: ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value']
})], IonSegment);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSegment, [{
    type: Component,
    args: [{
      selector: 'ion-segment',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSegmentButton = class IonSegmentButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSegmentButton.ɵfac = function IonSegmentButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSegmentButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSegmentButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSegmentButton,
  selectors: [["ion-segment-button"]],
  inputs: {
    disabled: "disabled",
    layout: "layout",
    mode: "mode",
    type: "type",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSegmentButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSegmentButton = __decorate([ProxyCmp({
  inputs: ['disabled', 'layout', 'mode', 'type', 'value']
})], IonSegmentButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSegmentButton, [{
    type: Component,
    args: [{
      selector: 'ion-segment-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'layout', 'mode', 'type', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSelect = class IonSelect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionCancel', 'ionDismiss', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonSelect.ɵfac = function IonSelect_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSelect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSelect,
  selectors: [["ion-select"]],
  inputs: {
    cancelText: "cancelText",
    color: "color",
    compareWith: "compareWith",
    disabled: "disabled",
    expandedIcon: "expandedIcon",
    fill: "fill",
    interface: "interface",
    interfaceOptions: "interfaceOptions",
    justify: "justify",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    okText: "okText",
    placeholder: "placeholder",
    selectedText: "selectedText",
    shape: "shape",
    toggleIcon: "toggleIcon",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSelect = __decorate([ProxyCmp({
  inputs: ['cancelText', 'color', 'compareWith', 'disabled', 'expandedIcon', 'fill', 'interface', 'interfaceOptions', 'justify', 'label', 'labelPlacement', 'legacy', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'shape', 'toggleIcon', 'value'],
  methods: ['open']
})], IonSelect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSelect, [{
    type: Component,
    args: [{
      selector: 'ion-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cancelText', 'color', 'compareWith', 'disabled', 'expandedIcon', 'fill', 'interface', 'interfaceOptions', 'justify', 'label', 'labelPlacement', 'legacy', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'shape', 'toggleIcon', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSelectOption = class IonSelectOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSelectOption.ɵfac = function IonSelectOption_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSelectOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSelectOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSelectOption,
  selectors: [["ion-select-option"]],
  inputs: {
    disabled: "disabled",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSelectOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSelectOption = __decorate([ProxyCmp({
  inputs: ['disabled', 'value']
})], IonSelectOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSelectOption, [{
    type: Component,
    args: [{
      selector: 'ion-select-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSkeletonText = class IonSkeletonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSkeletonText.ɵfac = function IonSkeletonText_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSkeletonText)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSkeletonText.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSkeletonText,
  selectors: [["ion-skeleton-text"]],
  inputs: {
    animated: "animated"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSkeletonText_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSkeletonText = __decorate([ProxyCmp({
  inputs: ['animated']
})], IonSkeletonText);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSkeletonText, [{
    type: Component,
    args: [{
      selector: 'ion-skeleton-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSpinner = class IonSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSpinner.ɵfac = function IonSpinner_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSpinner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSpinner.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSpinner,
  selectors: [["ion-spinner"]],
  inputs: {
    color: "color",
    duration: "duration",
    name: "name",
    paused: "paused"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSpinner_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSpinner = __decorate([ProxyCmp({
  inputs: ['color', 'duration', 'name', 'paused']
})], IonSpinner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSpinner, [{
    type: Component,
    args: [{
      selector: 'ion-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'duration', 'name', 'paused']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSplitPane = class IonSplitPane {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSplitPaneVisible']);
  }
};
/** @nocollapse */
IonSplitPane.ɵfac = function IonSplitPane_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSplitPane)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSplitPane.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSplitPane,
  selectors: [["ion-split-pane"]],
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    when: "when"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSplitPane_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSplitPane = __decorate([ProxyCmp({
  inputs: ['contentId', 'disabled', 'when']
})], IonSplitPane);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSplitPane, [{
    type: Component,
    args: [{
      selector: 'ion-split-pane',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'when']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonTabBar = class IonTabBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabBar.ɵfac = function IonTabBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTabBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonTabBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTabBar,
  selectors: [["ion-tab-bar"]],
  inputs: {
    color: "color",
    mode: "mode",
    selectedTab: "selectedTab",
    translucent: "translucent"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTabBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTabBar = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'selectedTab', 'translucent']
})], IonTabBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTabBar, [{
    type: Component,
    args: [{
      selector: 'ion-tab-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'selectedTab', 'translucent']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonTabButton = class IonTabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabButton.ɵfac = function IonTabButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTabButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonTabButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTabButton,
  selectors: [["ion-tab-button"]],
  inputs: {
    disabled: "disabled",
    download: "download",
    href: "href",
    layout: "layout",
    mode: "mode",
    rel: "rel",
    selected: "selected",
    tab: "tab",
    target: "target"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTabButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTabButton = __decorate([ProxyCmp({
  inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
})], IonTabButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTabButton, [{
    type: Component,
    args: [{
      selector: 'ion-tab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonText = class IonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonText.ɵfac = function IonText_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonText)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonText.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonText,
  selectors: [["ion-text"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonText_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonText = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonText);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonText, [{
    type: Component,
    args: [{
      selector: 'ion-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonTextarea = class IonTextarea {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonTextarea.ɵfac = function IonTextarea_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTextarea)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonTextarea.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTextarea,
  selectors: [["ion-textarea"]],
  inputs: {
    autoGrow: "autoGrow",
    autocapitalize: "autocapitalize",
    autofocus: "autofocus",
    clearOnEdit: "clearOnEdit",
    color: "color",
    cols: "cols",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    maxlength: "maxlength",
    minlength: "minlength",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    rows: "rows",
    shape: "shape",
    spellcheck: "spellcheck",
    value: "value",
    wrap: "wrap"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTextarea_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTextarea = __decorate([ProxyCmp({
  inputs: ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'shape', 'spellcheck', 'value', 'wrap'],
  methods: ['setFocus', 'getInputElement']
})], IonTextarea);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTextarea, [{
    type: Component,
    args: [{
      selector: 'ion-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'shape', 'spellcheck', 'value', 'wrap']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonThumbnail = class IonThumbnail {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonThumbnail.ɵfac = function IonThumbnail_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonThumbnail)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonThumbnail.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonThumbnail,
  selectors: [["ion-thumbnail"]],
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonThumbnail_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonThumbnail = __decorate([ProxyCmp({})], IonThumbnail);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonThumbnail, [{
    type: Component,
    args: [{
      selector: 'ion-thumbnail',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonTitle = class IonTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTitle.ɵfac = function IonTitle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonTitle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTitle,
  selectors: [["ion-title"]],
  inputs: {
    color: "color",
    size: "size"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTitle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTitle = __decorate([ProxyCmp({
  inputs: ['color', 'size']
})], IonTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTitle, [{
    type: Component,
    args: [{
      selector: 'ion-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'size']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonToast = class IonToast {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionToastDidPresent', 'ionToastWillPresent', 'ionToastWillDismiss', 'ionToastDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonToast.ɵfac = function IonToast_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonToast)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonToast.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonToast,
  selectors: [["ion-toast"]],
  inputs: {
    animated: "animated",
    buttons: "buttons",
    color: "color",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    icon: "icon",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    layout: "layout",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    position: "position",
    positionAnchor: "positionAnchor",
    swipeGesture: "swipeGesture",
    translucent: "translucent",
    trigger: "trigger"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonToast_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonToast = __decorate([ProxyCmp({
  inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonToast);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonToast, [{
    type: Component,
    args: [{
      selector: 'ion-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonToggle = class IonToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonToggle.ɵfac = function IonToggle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonToggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonToggle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonToggle,
  selectors: [["ion-toggle"]],
  inputs: {
    alignment: "alignment",
    checked: "checked",
    color: "color",
    disabled: "disabled",
    enableOnOffLabels: "enableOnOffLabels",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonToggle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonToggle = __decorate([ProxyCmp({
  inputs: ['alignment', 'checked', 'color', 'disabled', 'enableOnOffLabels', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
})], IonToggle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonToggle, [{
    type: Component,
    args: [{
      selector: 'ion-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'checked', 'color', 'disabled', 'enableOnOffLabels', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonToolbar = class IonToolbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonToolbar.ɵfac = function IonToolbar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonToolbar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonToolbar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonToolbar,
  selectors: [["ion-toolbar"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonToolbar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonToolbar = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonToolbar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonToolbar, [{
    type: Component,
    args: [{
      selector: 'ion-toolbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonRouterOutlet extends IonRouterOutlet$1 {
  /**
   * We need to pass in the correct instance of IonRouterOutlet
   * otherwise parentOutlet will be null in a nested outlet context.
   * This results in APIs such as NavController.pop not working
   * in nested outlets because the parent outlet cannot be found.
   */
  constructor(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet) {
    super(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet);
    this.parentOutlet = parentOutlet;
  }
}
/** @nocollapse */
IonRouterOutlet.ɵfac = function IonRouterOutlet_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRouterOutlet)(i0.ɵɵinjectAttribute('name'), i0.ɵɵinjectAttribute('tabs'), i0.ɵɵdirectiveInject(i1.Location), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(IonRouterOutlet, 12));
};
/** @nocollapse */
IonRouterOutlet.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IonRouterOutlet,
  selectors: [["ion-router-outlet"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRouterOutlet, [{
    type: Directive,
    args: [{
      selector: 'ion-router-outlet'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['name']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Attribute,
        args: ['tabs']
      }]
    }, {
      type: i1.Location
    }, {
      type: i0.ElementRef
    }, {
      type: i2.Router
    }, {
      type: i0.NgZone
    }, {
      type: i2.ActivatedRoute
    }, {
      type: IonRouterOutlet,
      decorators: [{
        type: SkipSelf
      }, {
        type: Optional
      }]
    }];
  }, null);
})();

// eslint-disable-next-line @angular-eslint/component-class-suffix
class IonTabs extends IonTabs$1 {}
/** @nocollapse */
IonTabs.ɵfac = /* @__PURE__ */(() => {
  let ɵIonTabs_BaseFactory;
  return function IonTabs_Factory(__ngFactoryType__) {
    return (ɵIonTabs_BaseFactory || (ɵIonTabs_BaseFactory = i0.ɵɵgetInheritedFactory(IonTabs)))(__ngFactoryType__ || IonTabs);
  };
})();
/** @nocollapse */
IonTabs.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTabs,
  selectors: [["ion-tabs"]],
  contentQueries: function IonTabs_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, IonTabBar, 5);
      i0.ɵɵcontentQuery(dirIndex, IonTabBar, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabBar = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabBars = _t);
    }
  },
  viewQuery: function IonTabs_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c1, 5, IonRouterOutlet);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.outlet = _t.first);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c3,
  decls: 6,
  vars: 0,
  consts: [["tabsInner", ""], ["outlet", ""], [1, "tabs-inner"], ["tabs", "true", 3, "stackWillChange", "stackDidChange"]],
  template: function IonTabs_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c2);
      i0.ɵɵprojection(0);
      i0.ɵɵelementStart(1, "div", 2, 0)(3, "ion-router-outlet", 3, 1);
      i0.ɵɵlistener("stackWillChange", function IonTabs_Template_ion_router_outlet_stackWillChange_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onStackWillChange($event));
      })("stackDidChange", function IonTabs_Template_ion_router_outlet_stackDidChange_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onStackDidChange($event));
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵprojection(5, 1);
    }
  },
  dependencies: [IonRouterOutlet],
  styles: ["[_nghost-%COMP%]{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner[_ngcontent-%COMP%]{position:relative;flex:1;contain:layout size style}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTabs, [{
    type: Component,
    args: [{
      selector: 'ion-tabs',
      template: `
    <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner" #tabsInner>
      <ion-router-outlet
        #outlet
        tabs="true"
        (stackWillChange)="onStackWillChange($event)"
        (stackDidChange)="onStackDidChange($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>
  `,
      styles: [":host{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner{position:relative;flex:1;contain:layout size style}\n"]
    }]
  }], null, {
    outlet: [{
      type: ViewChild,
      args: ['outlet', {
        read: IonRouterOutlet,
        static: false
      }]
    }],
    tabBar: [{
      type: ContentChild,
      args: [IonTabBar, {
        static: false
      }]
    }],
    tabBars: [{
      type: ContentChildren,
      args: [IonTabBar]
    }]
  });
})();

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonBackButton extends IonBackButton$1 {
  constructor(routerOutlet, navCtrl, config, r, z, c) {
    super(routerOutlet, navCtrl, config, r, z, c);
  }
}
/** @nocollapse */
IonBackButton.ɵfac = function IonBackButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBackButton)(i0.ɵɵdirectiveInject(IonRouterOutlet, 8), i0.ɵɵdirectiveInject(i2$1.NavController), i0.ɵɵdirectiveInject(i2$1.Config), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
IonBackButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBackButton,
  selectors: [["ion-back-button"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBackButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBackButton, [{
    type: Component,
    args: [{
      selector: 'ion-back-button',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: IonRouterOutlet,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2$1.NavController
    }, {
      type: i2$1.Config
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonNav extends IonNav$1 {
  constructor(ref, environmentInjector, injector, angularDelegate, z, c) {
    super(ref, environmentInjector, injector, angularDelegate, z, c);
  }
}
/** @nocollapse */
IonNav.ɵfac = function IonNav_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonNav)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.EnvironmentInjector), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i2$1.AngularDelegate), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
IonNav.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonNav,
  selectors: [["ion-nav"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonNav_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonNav, [{
    type: Component,
    args: [{
      selector: 'ion-nav',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.EnvironmentInjector
    }, {
      type: i0.Injector
    }, {
      type: i2$1.AngularDelegate
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * Adds support for Ionic routing directions and animations to the base Angular router link directive.
 *
 * When the router link is clicked, the directive will assign the direction and
 * animation so that the routing integration will transition correctly.
 */
class RouterLinkDelegateDirective extends RouterLinkDelegateDirective$1 {}
/** @nocollapse */
RouterLinkDelegateDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵRouterLinkDelegateDirective_BaseFactory;
  return function RouterLinkDelegateDirective_Factory(__ngFactoryType__) {
    return (ɵRouterLinkDelegateDirective_BaseFactory || (ɵRouterLinkDelegateDirective_BaseFactory = i0.ɵɵgetInheritedFactory(RouterLinkDelegateDirective)))(__ngFactoryType__ || RouterLinkDelegateDirective);
  };
})();
/** @nocollapse */
RouterLinkDelegateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RouterLinkDelegateDirective,
  selectors: [["", "routerLink", "", 5, "a", 5, "area"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RouterLinkDelegateDirective, [{
    type: Directive,
    args: [{
      selector: ':not(a):not(area)[routerLink]'
    }]
  }], null, null);
})();
class RouterLinkWithHrefDelegateDirective extends RouterLinkWithHrefDelegateDirective$1 {}
/** @nocollapse */
RouterLinkWithHrefDelegateDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵRouterLinkWithHrefDelegateDirective_BaseFactory;
  return function RouterLinkWithHrefDelegateDirective_Factory(__ngFactoryType__) {
    return (ɵRouterLinkWithHrefDelegateDirective_BaseFactory || (ɵRouterLinkWithHrefDelegateDirective_BaseFactory = i0.ɵɵgetInheritedFactory(RouterLinkWithHrefDelegateDirective)))(__ngFactoryType__ || RouterLinkWithHrefDelegateDirective);
  };
})();
/** @nocollapse */
RouterLinkWithHrefDelegateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RouterLinkWithHrefDelegateDirective,
  selectors: [["a", "routerLink", ""], ["area", "routerLink", ""]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RouterLinkWithHrefDelegateDirective, [{
    type: Directive,
    args: [{
      selector: 'a[routerLink],area[routerLink]'
    }]
  }], null, null);
})();
class IonModal extends IonModal$1 {}
/** @nocollapse */
IonModal.ɵfac = /* @__PURE__ */(() => {
  let ɵIonModal_BaseFactory;
  return function IonModal_Factory(__ngFactoryType__) {
    return (ɵIonModal_BaseFactory || (ɵIonModal_BaseFactory = i0.ɵɵgetInheritedFactory(IonModal)))(__ngFactoryType__ || IonModal);
  };
})();
/** @nocollapse */
IonModal.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonModal,
  selectors: [["ion-modal"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [["class", "ion-delegate-host ion-page", 4, "ngIf"], [1, "ion-delegate-host", "ion-page"], [3, "ngTemplateOutlet"]],
  template: function IonModal_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, IonModal_div_0_Template, 2, 1, "div", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.isCmpOpen || ctx.keepContentsMounted);
    }
  },
  dependencies: [i1.NgIf, i1.NgTemplateOutlet],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonModal, [{
    type: Component,
    args: [{
      selector: 'ion-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`
    }]
  }], null, null);
})();
class IonPopover extends IonPopover$1 {}
/** @nocollapse */
IonPopover.ɵfac = /* @__PURE__ */(() => {
  let ɵIonPopover_BaseFactory;
  return function IonPopover_Factory(__ngFactoryType__) {
    return (ɵIonPopover_BaseFactory || (ɵIonPopover_BaseFactory = i0.ɵɵgetInheritedFactory(IonPopover)))(__ngFactoryType__ || IonPopover);
  };
})();
/** @nocollapse */
IonPopover.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonPopover,
  selectors: [["ion-popover"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [[3, "ngTemplateOutlet", 4, "ngIf"], [3, "ngTemplateOutlet"]],
  template: function IonPopover_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, IonPopover_ng_container_0_Template, 1, 1, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.isCmpOpen || ctx.keepContentsMounted);
    }
  },
  dependencies: [i1.NgIf, i1.NgTemplateOutlet],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonPopover, [{
    type: Component,
    args: [{
      selector: 'ion-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`
    }]
  }], null, null);
})();

/**
 * @description
 * Provider which adds `MaxValidator` to the `NG_VALIDATORS` multi-provider list.
 */
const ION_MAX_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IonMaxValidator),
  multi: true
};
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonMaxValidator extends MaxValidator {}
/** @nocollapse */
IonMaxValidator.ɵfac = /* @__PURE__ */(() => {
  let ɵIonMaxValidator_BaseFactory;
  return function IonMaxValidator_Factory(__ngFactoryType__) {
    return (ɵIonMaxValidator_BaseFactory || (ɵIonMaxValidator_BaseFactory = i0.ɵɵgetInheritedFactory(IonMaxValidator)))(__ngFactoryType__ || IonMaxValidator);
  };
})();
/** @nocollapse */
IonMaxValidator.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IonMaxValidator,
  selectors: [["ion-input", "type", "number", "max", "", "formControlName", ""], ["ion-input", "type", "number", "max", "", "formControl", ""], ["ion-input", "type", "number", "max", "", "ngModel", ""]],
  hostVars: 1,
  hostBindings: function IonMaxValidator_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("max", ctx._enabled ? ctx.max : null);
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([ION_MAX_VALIDATOR]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMaxValidator, [{
    type: Directive,
    args: [{
      selector: 'ion-input[type=number][max][formControlName],ion-input[type=number][max][formControl],ion-input[type=number][max][ngModel]',
      providers: [ION_MAX_VALIDATOR],
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        '[attr.max]': '_enabled ? max : null'
      }
    }]
  }], null, null);
})();

/**
 * @description
 * Provider which adds `MinValidator` to the `NG_VALIDATORS` multi-provider list.
 */
const ION_MIN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IonMinValidator),
  multi: true
};
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonMinValidator extends MinValidator {}
/** @nocollapse */
IonMinValidator.ɵfac = /* @__PURE__ */(() => {
  let ɵIonMinValidator_BaseFactory;
  return function IonMinValidator_Factory(__ngFactoryType__) {
    return (ɵIonMinValidator_BaseFactory || (ɵIonMinValidator_BaseFactory = i0.ɵɵgetInheritedFactory(IonMinValidator)))(__ngFactoryType__ || IonMinValidator);
  };
})();
/** @nocollapse */
IonMinValidator.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IonMinValidator,
  selectors: [["ion-input", "type", "number", "min", "", "formControlName", ""], ["ion-input", "type", "number", "min", "", "formControl", ""], ["ion-input", "type", "number", "min", "", "ngModel", ""]],
  hostVars: 1,
  hostBindings: function IonMinValidator_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("min", ctx._enabled ? ctx.min : null);
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([ION_MIN_VALIDATOR]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMinValidator, [{
    type: Directive,
    args: [{
      selector: 'ion-input[type=number][min][formControlName],ion-input[type=number][min][formControl],ion-input[type=number][min][ngModel]',
      providers: [ION_MIN_VALIDATOR],
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        '[attr.min]': '_enabled ? min : null'
      }
    }]
  }], null, null);
})();
class AlertController extends OverlayBaseController {
  constructor() {
    super(alertController);
  }
}
/** @nocollapse */
AlertController.ɵfac = function AlertController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AlertController)();
};
/** @nocollapse */
AlertController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AlertController,
  factory: AlertController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class AnimationController {
  /**
   * Create a new animation
   */
  create(animationId) {
    return createAnimation(animationId);
  }
  /**
   * EXPERIMENTAL
   *
   * Given a progression and a cubic bezier function,
   * this utility returns the time value(s) at which the
   * cubic bezier reaches the given time progression.
   *
   * If the cubic bezier never reaches the progression
   * the result will be an empty array.
   *
   * This is most useful for switching between easing curves
   * when doing a gesture animation (i.e. going from linear easing
   * during a drag, to another easing when `progressEnd` is called)
   */
  easingTime(p0, p1, p2, p3, progression) {
    return getTimeGivenProgression(p0, p1, p2, p3, progression);
  }
}
/** @nocollapse */
AnimationController.ɵfac = function AnimationController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AnimationController)();
};
/** @nocollapse */
AnimationController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AnimationController,
  factory: AnimationController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimationController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ActionSheetController extends OverlayBaseController {
  constructor() {
    super(actionSheetController);
  }
}
/** @nocollapse */
ActionSheetController.ɵfac = function ActionSheetController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetController)();
};
/** @nocollapse */
ActionSheetController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ActionSheetController,
  factory: ActionSheetController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class GestureController {
  constructor(zone) {
    this.zone = zone;
  }
  /**
   * Create a new gesture
   */
  create(opts, runInsideAngularZone = false) {
    if (runInsideAngularZone) {
      Object.getOwnPropertyNames(opts).forEach(key => {
        if (typeof opts[key] === 'function') {
          const fn = opts[key];
          opts[key] = (...props) => this.zone.run(() => fn(...props));
        }
      });
    }
    return createGesture(opts);
  }
}
/** @nocollapse */
GestureController.ɵfac = function GestureController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GestureController)(i0.ɵɵinject(i0.NgZone));
};
/** @nocollapse */
GestureController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: GestureController,
  factory: GestureController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GestureController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
class LoadingController extends OverlayBaseController {
  constructor() {
    super(loadingController);
  }
}
/** @nocollapse */
LoadingController.ɵfac = function LoadingController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoadingController)();
};
/** @nocollapse */
LoadingController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LoadingController,
  factory: LoadingController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class MenuController extends MenuController$1 {
  constructor() {
    super(menuController);
  }
}
/** @nocollapse */
MenuController.ɵfac = function MenuController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuController)();
};
/** @nocollapse */
MenuController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MenuController,
  factory: MenuController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class ModalController extends OverlayBaseController {
  constructor() {
    super(modalController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'modal')
    });
  }
}
/** @nocollapse */
ModalController.ɵfac = function ModalController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ModalController)();
};
/** @nocollapse */
ModalController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ModalController,
  factory: ModalController.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalController, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class PickerController extends OverlayBaseController {
  constructor() {
    super(pickerController);
  }
}
/** @nocollapse */
PickerController.ɵfac = function PickerController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PickerController)();
};
/** @nocollapse */
PickerController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PickerController,
  factory: PickerController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PickerController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class PopoverController extends OverlayBaseController {
  constructor() {
    super(popoverController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'popover')
    });
  }
}
class ToastController extends OverlayBaseController {
  constructor() {
    super(toastController);
  }
}
/** @nocollapse */
ToastController.ɵfac = function ToastController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToastController)();
};
/** @nocollapse */
ToastController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ToastController,
  factory: ToastController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

// TODO(FW-2827): types
const appInitialize = (config, doc, zone) => {
  return () => {
    const win = doc.defaultView;
    if (win && typeof window !== 'undefined') {
      setupConfig({
        ...config,
        _zoneGate: h => zone.run(h)
      });
      const aelFn = '__zone_symbol__addEventListener' in doc.body ? '__zone_symbol__addEventListener' : 'addEventListener';
      return applyPolyfills().then(() => {
        return defineCustomElements(win, {
          exclude: ['ion-tabs', 'ion-tab'],
          syncQueue: true,
          raf,
          jmp: h => zone.runOutsideAngular(h),
          ael(elm, eventName, cb, opts) {
            elm[aelFn](eventName, cb, opts);
          },
          rel(elm, eventName, cb, opts) {
            elm.removeEventListener(eventName, cb, opts);
          }
        });
      });
    }
  };
};
const DIRECTIVES = [IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMenu, IonMenuButton, IonMenuToggle, IonNavLink, IonNote, IonPicker, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar];
const DECLARATIONS = [
// generated proxies
...DIRECTIVES,
// manual proxies
IonModal, IonPopover,
// ngModel accessors
BooleanValueAccessorDirective, NumericValueAccessorDirective, RadioValueAccessorDirective, SelectValueAccessorDirective, TextValueAccessorDirective,
// navigation
IonTabs, IonRouterOutlet, IonBackButton, IonNav, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective,
// validators
IonMinValidator, IonMaxValidator];
class IonicModule {
  static forRoot(config) {
    return {
      ngModule: IonicModule,
      providers: [{
        provide: ConfigToken,
        useValue: config
      }, {
        provide: APP_INITIALIZER,
        useFactory: appInitialize,
        multi: true,
        deps: [ConfigToken, DOCUMENT, NgZone]
      }, provideComponentInputBinding()]
    };
  }
}
/** @nocollapse */
IonicModule.ɵfac = function IonicModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonicModule)();
};
/** @nocollapse */
IonicModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: IonicModule
});
/** @nocollapse */
IonicModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [AngularDelegate, ModalController, PopoverController],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonicModule, [{
    type: NgModule,
    args: [{
      declarations: DECLARATIONS,
      exports: DECLARATIONS,
      providers: [AngularDelegate, ModalController, PopoverController],
      imports: [CommonModule]
    }]
  }], null, null);
})();

// DIRECTIVES

/**
 * Generated bundle index. Do not edit.
 */

export { ActionSheetController, AlertController, AnimationController, BooleanValueAccessorDirective as BooleanValueAccessor, GestureController, ION_MAX_VALIDATOR, ION_MIN_VALIDATOR, IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackButton, IonBackButton as IonBackButtonDelegate, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMaxValidator, IonMenu, IonMenuButton, IonMenuToggle, IonMinValidator, IonModal, IonNav, IonNavLink, IonNote, IonPicker, IonPopover, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRouterOutlet, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar, IonicModule, LoadingController, MenuController, ModalController, NumericValueAccessorDirective as NumericValueAccessor, PickerController, PopoverController, RadioValueAccessorDirective as RadioValueAccessor, RouterLinkDelegateDirective as RouterLinkDelegate, RouterLinkWithHrefDelegateDirective as RouterLinkWithHrefDelegate, SelectValueAccessorDirective as SelectValueAccessor, TextValueAccessorDirective as TextValueAccessor, ToastController };

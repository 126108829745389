import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private darkModeEnabled: boolean;

  constructor() {
    // Check local storage for dark mode preference
    const checkDarkModeStatus = localStorage.getItem('darkMode');
    this.darkModeEnabled = checkDarkModeStatus === '1';

    // Check if dark mode is enabled by default
    if (environment.darkMode.default) {
      this.toggleDarkMode(true);
    }

    // Check local dark mode settings
    if (environment.darkMode.localMode.enable) {
      const nightStart = environment.darkMode.localMode.startTime;
      const nightEnd = environment.darkMode.localMode.endTime;
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour >= nightStart || currentHour < nightEnd) {
        this.toggleDarkMode(true);
      }
    }

    // Auto detect dark mode
    if (environment.darkMode.autoDetect.enable && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.toggleDarkMode(true);
    }
  }

  toggleDarkMode(enabled: boolean): void {
    const pageBody = document.querySelector("body");

    if (enabled) {
      pageBody.classList.add('dark-mode');
      pageBody.classList.remove('bg-white');
      localStorage.setItem('darkMode', '1');
    } else {
      pageBody.classList.remove('dark-mode');
      // pageBody.classList.add('bg-white');
      localStorage.setItem('darkMode', '0');
    }
    this.darkModeEnabled = enabled;
  }

  isDarkModeEnabled(): boolean {
    return this.darkModeEnabled;
  }
}

/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountMemberRole } from './portal-account-member-role';
import { PortalUser } from './portal-user';


export interface PortalAccountTypeRole { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalAccountTypeRole.StatusEnum;
    deactivatedBy?: PortalUser;
    name?: string;
    displayName?: string;
    accountType?: PortalAccountTypeRole.AccountTypeEnum;
    portalAccountMemberRoles?: Array<PortalAccountMemberRole>;
}
export namespace PortalAccountTypeRole {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type AccountTypeEnum = 'SYSTEM' | 'USER';
    export const AccountTypeEnum = {
        SYSTEM: 'SYSTEM' as AccountTypeEnum,
        USER: 'USER' as AccountTypeEnum
    };
}



/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ElectricityOrderDetailsPojo } from './electricity-order-details-pojo';
import { DataOrderDetailsPojo } from './data-order-details-pojo';
import { AirtimeOrderDetailsPojo } from './airtime-order-details-pojo';
import { TvOrderDetailsPojo } from './tv-order-details-pojo';


export interface TransactionPojo { 
    invoiceId?: number;
    invoiceNumber?: string;
    serviceHead?: TransactionPojo.ServiceHeadEnum;
    transactionTime?: string;
    transactionLineItem?: string;
    amount?: number;
    serviceTransactionStatus?: TransactionPojo.ServiceTransactionStatusEnum;
    invoicePaymentStatus?: TransactionPojo.InvoicePaymentStatusEnum;
    paymentTransactionStatus?: TransactionPojo.PaymentTransactionStatusEnum;
    electricityOrderDetails?: ElectricityOrderDetailsPojo;
    airtimeOrderDetails?: AirtimeOrderDetailsPojo;
    dataOrderDetails?: DataOrderDetailsPojo;
    tvOrderDetails?: TvOrderDetailsPojo;
}
export namespace TransactionPojo {
    export type ServiceHeadEnum = 'ELECTRICITY' | 'AIRTIME' | 'DATA' | 'TV' | 'EDUCATION' | 'INSURANCE';
    export const ServiceHeadEnum = {
        ELECTRICITY: 'ELECTRICITY' as ServiceHeadEnum,
        AIRTIME: 'AIRTIME' as ServiceHeadEnum,
        DATA: 'DATA' as ServiceHeadEnum,
        TV: 'TV' as ServiceHeadEnum,
        EDUCATION: 'EDUCATION' as ServiceHeadEnum,
        INSURANCE: 'INSURANCE' as ServiceHeadEnum
    };
    export type ServiceTransactionStatusEnum = 'unstarted' | 'initiated' | 'pending' | 'delivered' | 'failed';
    export const ServiceTransactionStatusEnum = {
        UNSTARTED: 'unstarted' as ServiceTransactionStatusEnum,
        INITIATED: 'initiated' as ServiceTransactionStatusEnum,
        PENDING: 'pending' as ServiceTransactionStatusEnum,
        DELIVERED: 'delivered' as ServiceTransactionStatusEnum,
        FAILED: 'failed' as ServiceTransactionStatusEnum
    };
    export type InvoicePaymentStatusEnum = 'PENDING' | 'PAID' | 'PARTLY_PAID';
    export const InvoicePaymentStatusEnum = {
        PENDING: 'PENDING' as InvoicePaymentStatusEnum,
        PAID: 'PAID' as InvoicePaymentStatusEnum,
        PARTLY_PAID: 'PARTLY_PAID' as InvoicePaymentStatusEnum
    };
    export type PaymentTransactionStatusEnum = 'NOT_YET_RECEIVED' | 'PENDING' | 'SUCCESSFUL' | 'CANCELED' | 'FAILED' | 'REVERSED' | 'PARTIAL';
    export const PaymentTransactionStatusEnum = {
        NOT_YET_RECEIVED: 'NOT_YET_RECEIVED' as PaymentTransactionStatusEnum,
        PENDING: 'PENDING' as PaymentTransactionStatusEnum,
        SUCCESSFUL: 'SUCCESSFUL' as PaymentTransactionStatusEnum,
        CANCELED: 'CANCELED' as PaymentTransactionStatusEnum,
        FAILED: 'FAILED' as PaymentTransactionStatusEnum,
        REVERSED: 'REVERSED' as PaymentTransactionStatusEnum,
        PARTIAL: 'PARTIAL' as PaymentTransactionStatusEnum
    };
}



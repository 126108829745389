import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, Attribute, Optional, SkipSelf, Component, ChangeDetectionStrategy, ViewChild, ContentChild, ContentChildren, inject, Injector, EnvironmentInjector, Injectable, APP_INITIALIZER, forwardRef, HostListener } from '@angular/core';
import * as i2$1 from '@ionic/angular/common';
import { IonRouterOutlet as IonRouterOutlet$1, ProxyCmp as ProxyCmp$1, IonBackButton as IonBackButton$1, IonModal as IonModal$1, IonPopover as IonPopover$1, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective, IonTabs as IonTabs$1, OverlayBaseController, AngularDelegate, ConfigToken, provideComponentInputBinding, MenuController as MenuController$1, IonNav as IonNav$1, ValueAccessor, setIonicClasses } from '@ionic/angular/common';
const _c0 = ["*"];
function IonModal_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementContainer(1, 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template);
  }
}
function IonPopover_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template);
  }
}
const _c1 = ["outlet"];
const _c2 = [[["", "slot", "top"]], "*"];
const _c3 = ["[slot=top]", "*"];
export { Config, DomController, IonicRouteStrategy, NavController, NavParams, Platform } from '@ionic/angular/common';
import { defineCustomElement as defineCustomElement$2 } from '@ionic/core/components/ion-back-button.js';
import { defineCustomElement as defineCustomElement$1 } from '@ionic/core/components/ion-router-outlet.js';
import * as i1 from '@angular/common';
import { CommonModule, DOCUMENT } from '@angular/common';
import * as i2 from '@angular/router';
import { defineCustomElement as defineCustomElement$3 } from '@ionic/core/components/ion-modal.js';
import { defineCustomElement as defineCustomElement$4 } from '@ionic/core/components/ion-popover.js';
import { fromEvent } from 'rxjs';
import { defineCustomElement as defineCustomElement$5 } from '@ionic/core/components/ion-accordion.js';
import { defineCustomElement as defineCustomElement$6 } from '@ionic/core/components/ion-accordion-group.js';
import { defineCustomElement as defineCustomElement$7 } from '@ionic/core/components/ion-action-sheet.js';
import { defineCustomElement as defineCustomElement$8 } from '@ionic/core/components/ion-alert.js';
import { defineCustomElement as defineCustomElement$9 } from '@ionic/core/components/ion-app.js';
import { defineCustomElement as defineCustomElement$a } from '@ionic/core/components/ion-avatar.js';
import { defineCustomElement as defineCustomElement$b } from '@ionic/core/components/ion-backdrop.js';
import { defineCustomElement as defineCustomElement$c } from '@ionic/core/components/ion-badge.js';
import { defineCustomElement as defineCustomElement$d } from '@ionic/core/components/ion-breadcrumb.js';
import { defineCustomElement as defineCustomElement$e } from '@ionic/core/components/ion-breadcrumbs.js';
import { defineCustomElement as defineCustomElement$f } from '@ionic/core/components/ion-button.js';
import { defineCustomElement as defineCustomElement$g } from '@ionic/core/components/ion-buttons.js';
import { defineCustomElement as defineCustomElement$h } from '@ionic/core/components/ion-card.js';
import { defineCustomElement as defineCustomElement$i } from '@ionic/core/components/ion-card-content.js';
import { defineCustomElement as defineCustomElement$j } from '@ionic/core/components/ion-card-header.js';
import { defineCustomElement as defineCustomElement$k } from '@ionic/core/components/ion-card-subtitle.js';
import { defineCustomElement as defineCustomElement$l } from '@ionic/core/components/ion-card-title.js';
import { defineCustomElement as defineCustomElement$m } from '@ionic/core/components/ion-chip.js';
import { defineCustomElement as defineCustomElement$n } from '@ionic/core/components/ion-col.js';
import { defineCustomElement as defineCustomElement$o } from '@ionic/core/components/ion-content.js';
import { defineCustomElement as defineCustomElement$p } from '@ionic/core/components/ion-datetime-button.js';
import { defineCustomElement as defineCustomElement$q } from '@ionic/core/components/ion-fab.js';
import { defineCustomElement as defineCustomElement$r } from '@ionic/core/components/ion-fab-button.js';
import { defineCustomElement as defineCustomElement$s } from '@ionic/core/components/ion-fab-list.js';
import { defineCustomElement as defineCustomElement$t } from '@ionic/core/components/ion-footer.js';
import { defineCustomElement as defineCustomElement$u } from '@ionic/core/components/ion-grid.js';
import { defineCustomElement as defineCustomElement$v } from '@ionic/core/components/ion-header.js';
import { defineCustomElement as defineCustomElement$w } from '@ionic/core/components/ion-img.js';
import { defineCustomElement as defineCustomElement$x } from '@ionic/core/components/ion-infinite-scroll.js';
import { defineCustomElement as defineCustomElement$y } from '@ionic/core/components/ion-infinite-scroll-content.js';
import { defineCustomElement as defineCustomElement$z } from '@ionic/core/components/ion-item.js';
import { defineCustomElement as defineCustomElement$A } from '@ionic/core/components/ion-item-divider.js';
import { defineCustomElement as defineCustomElement$B } from '@ionic/core/components/ion-item-group.js';
import { defineCustomElement as defineCustomElement$C } from '@ionic/core/components/ion-item-option.js';
import { defineCustomElement as defineCustomElement$D } from '@ionic/core/components/ion-item-options.js';
import { defineCustomElement as defineCustomElement$E } from '@ionic/core/components/ion-item-sliding.js';
import { defineCustomElement as defineCustomElement$F } from '@ionic/core/components/ion-label.js';
import { defineCustomElement as defineCustomElement$G } from '@ionic/core/components/ion-list.js';
import { defineCustomElement as defineCustomElement$H } from '@ionic/core/components/ion-list-header.js';
import { defineCustomElement as defineCustomElement$I } from '@ionic/core/components/ion-loading.js';
import { defineCustomElement as defineCustomElement$J } from '@ionic/core/components/ion-menu.js';
import { defineCustomElement as defineCustomElement$K } from '@ionic/core/components/ion-menu-button.js';
import { defineCustomElement as defineCustomElement$L } from '@ionic/core/components/ion-menu-toggle.js';
import { defineCustomElement as defineCustomElement$M } from '@ionic/core/components/ion-nav-link.js';
import { defineCustomElement as defineCustomElement$N } from '@ionic/core/components/ion-note.js';
import { defineCustomElement as defineCustomElement$O } from '@ionic/core/components/ion-picker.js';
import { defineCustomElement as defineCustomElement$P } from '@ionic/core/components/ion-progress-bar.js';
import { defineCustomElement as defineCustomElement$Q } from '@ionic/core/components/ion-refresher.js';
import { defineCustomElement as defineCustomElement$R } from '@ionic/core/components/ion-refresher-content.js';
import { defineCustomElement as defineCustomElement$S } from '@ionic/core/components/ion-reorder.js';
import { defineCustomElement as defineCustomElement$T } from '@ionic/core/components/ion-reorder-group.js';
import { defineCustomElement as defineCustomElement$U } from '@ionic/core/components/ion-ripple-effect.js';
import { defineCustomElement as defineCustomElement$V } from '@ionic/core/components/ion-row.js';
import { defineCustomElement as defineCustomElement$W } from '@ionic/core/components/ion-segment-button.js';
import { defineCustomElement as defineCustomElement$X } from '@ionic/core/components/ion-select-option.js';
import { defineCustomElement as defineCustomElement$Y } from '@ionic/core/components/ion-skeleton-text.js';
import { defineCustomElement as defineCustomElement$Z } from '@ionic/core/components/ion-spinner.js';
import { defineCustomElement as defineCustomElement$_ } from '@ionic/core/components/ion-split-pane.js';
import { defineCustomElement as defineCustomElement$$ } from '@ionic/core/components/ion-tab-bar.js';
import { defineCustomElement as defineCustomElement$10 } from '@ionic/core/components/ion-tab-button.js';
import { defineCustomElement as defineCustomElement$11 } from '@ionic/core/components/ion-text.js';
import { defineCustomElement as defineCustomElement$12 } from '@ionic/core/components/ion-thumbnail.js';
import { defineCustomElement as defineCustomElement$13 } from '@ionic/core/components/ion-title.js';
import { defineCustomElement as defineCustomElement$14 } from '@ionic/core/components/ion-toast.js';
import { defineCustomElement as defineCustomElement$15 } from '@ionic/core/components/ion-toolbar.js';
import { modalController, popoverController, initialize, actionSheetController, alertController, createAnimation, getTimeGivenProgression, createGesture, loadingController, menuController, pickerController, toastController } from '@ionic/core/components';
export { IonicSafeString, IonicSlides, createAnimation, createGesture, getPlatforms, getTimeGivenProgression, iosTransitionAnimation, isPlatform, mdTransitionAnimation } from '@ionic/core/components';
import { defineCustomElement as defineCustomElement$16 } from '@ionic/core/components/ion-nav.js';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { defineCustomElement as defineCustomElement$17 } from '@ionic/core/components/ion-checkbox.js';
import { defineCustomElement as defineCustomElement$18 } from '@ionic/core/components/ion-datetime.js';
import { defineCustomElement as defineCustomElement$19 } from 'ionicons/components/ion-icon.js';
import { defineCustomElement as defineCustomElement$1a } from '@ionic/core/components/ion-input.js';
import { defineCustomElement as defineCustomElement$1b } from '@ionic/core/components/ion-radio-group.js';
import { defineCustomElement as defineCustomElement$1c } from '@ionic/core/components/ion-radio.js';
import { defineCustomElement as defineCustomElement$1d } from '@ionic/core/components/ion-range.js';
import { defineCustomElement as defineCustomElement$1e } from '@ionic/core/components/ion-searchbar.js';
import { defineCustomElement as defineCustomElement$1f } from '@ionic/core/components/ion-segment.js';
import { defineCustomElement as defineCustomElement$1g } from '@ionic/core/components/ion-select.js';
import { defineCustomElement as defineCustomElement$1h } from '@ionic/core/components/ion-textarea.js';
import { defineCustomElement as defineCustomElement$1i } from '@ionic/core/components/ion-toggle.js';
let IonRouterOutlet = class IonRouterOutlet extends IonRouterOutlet$1 {
  /**
   * We need to pass in the correct instance of IonRouterOutlet
   * otherwise parentOutlet will be null in a nested outlet context.
   * This results in APIs such as NavController.pop not working
   * in nested outlets because the parent outlet cannot be found.
   */
  constructor(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet) {
    super(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet);
    this.parentOutlet = parentOutlet;
  }
};
/** @nocollapse */
IonRouterOutlet.ɵfac = function IonRouterOutlet_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRouterOutlet)(i0.ɵɵinjectAttribute('name'), i0.ɵɵinjectAttribute('tabs'), i0.ɵɵdirectiveInject(i1.Location), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(IonRouterOutlet, 12));
};
/** @nocollapse */
IonRouterOutlet.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IonRouterOutlet,
  selectors: [["ion-router-outlet"]],
  features: [i0.ɵɵInheritDefinitionFeature]
});
IonRouterOutlet = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$1
})], IonRouterOutlet);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRouterOutlet, [{
    type: Directive,
    args: [{
      selector: 'ion-router-outlet',
      standalone: true
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['name']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Attribute,
        args: ['tabs']
      }]
    }, {
      type: i1.Location
    }, {
      type: i0.ElementRef
    }, {
      type: i2.Router
    }, {
      type: i0.NgZone
    }, {
      type: i2.ActivatedRoute
    }, {
      type: IonRouterOutlet,
      decorators: [{
        type: SkipSelf
      }, {
        type: Optional
      }]
    }];
  }, null);
})();
let IonBackButton = class IonBackButton extends IonBackButton$1 {
  constructor(routerOutlet, navCtrl, config, r, z, c) {
    super(routerOutlet, navCtrl, config, r, z, c);
  }
};
/** @nocollapse */
IonBackButton.ɵfac = function IonBackButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBackButton)(i0.ɵɵdirectiveInject(IonRouterOutlet, 8), i0.ɵɵdirectiveInject(i2$1.NavController), i0.ɵɵdirectiveInject(i2$1.Config), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
IonBackButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBackButton,
  selectors: [["ion-back-button"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBackButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBackButton = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$2
})], IonBackButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBackButton, [{
    type: Component,
    args: [{
      selector: 'ion-back-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      standalone: true
    }]
  }], function () {
    return [{
      type: IonRouterOutlet,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2$1.NavController
    }, {
      type: i2$1.Config
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();
let IonModal = class IonModal extends IonModal$1 {};
/** @nocollapse */
IonModal.ɵfac = /* @__PURE__ */(() => {
  let ɵIonModal_BaseFactory;
  return function IonModal_Factory(__ngFactoryType__) {
    return (ɵIonModal_BaseFactory || (ɵIonModal_BaseFactory = i0.ɵɵgetInheritedFactory(IonModal)))(__ngFactoryType__ || IonModal);
  };
})();
/** @nocollapse */
IonModal.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonModal,
  selectors: [["ion-modal"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [["class", "ion-delegate-host ion-page", 4, "ngIf"], [1, "ion-delegate-host", "ion-page"], [3, "ngTemplateOutlet"]],
  template: function IonModal_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, IonModal_div_0_Template, 2, 1, "div", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.isCmpOpen || ctx.keepContentsMounted);
    }
  },
  dependencies: [CommonModule, i1.NgIf, i1.NgTemplateOutlet],
  encapsulation: 2,
  changeDetection: 0
});
IonModal = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$3
})], IonModal);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonModal, [{
    type: Component,
    args: [{
      selector: 'ion-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`,
      standalone: true,
      imports: [CommonModule]
    }]
  }], null, null);
})();
let IonPopover = class IonPopover extends IonPopover$1 {};
/** @nocollapse */
IonPopover.ɵfac = /* @__PURE__ */(() => {
  let ɵIonPopover_BaseFactory;
  return function IonPopover_Factory(__ngFactoryType__) {
    return (ɵIonPopover_BaseFactory || (ɵIonPopover_BaseFactory = i0.ɵɵgetInheritedFactory(IonPopover)))(__ngFactoryType__ || IonPopover);
  };
})();
/** @nocollapse */
IonPopover.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonPopover,
  selectors: [["ion-popover"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [[3, "ngTemplateOutlet", 4, "ngIf"], [3, "ngTemplateOutlet"]],
  template: function IonPopover_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, IonPopover_ng_container_0_Template, 1, 1, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.isCmpOpen || ctx.keepContentsMounted);
    }
  },
  dependencies: [CommonModule, i1.NgIf, i1.NgTemplateOutlet],
  encapsulation: 2,
  changeDetection: 0
});
IonPopover = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$4
})], IonPopover);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonPopover, [{
    type: Component,
    args: [{
      selector: 'ion-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`,
      standalone: true,
      imports: [CommonModule]
    }]
  }], null, null);
})();

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonRouterLink extends RouterLinkDelegateDirective {}
/** @nocollapse */
IonRouterLink.ɵfac = /* @__PURE__ */(() => {
  let ɵIonRouterLink_BaseFactory;
  return function IonRouterLink_Factory(__ngFactoryType__) {
    return (ɵIonRouterLink_BaseFactory || (ɵIonRouterLink_BaseFactory = i0.ɵɵgetInheritedFactory(IonRouterLink)))(__ngFactoryType__ || IonRouterLink);
  };
})();
/** @nocollapse */
IonRouterLink.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IonRouterLink,
  selectors: [["", "routerLink", "", 5, "a", 5, "area"]],
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRouterLink, [{
    type: Directive,
    args: [{
      selector: ':not(a):not(area)[routerLink]',
      standalone: true
    }]
  }], null, null);
})();
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonRouterLinkWithHref extends RouterLinkWithHrefDelegateDirective {}
/** @nocollapse */
IonRouterLinkWithHref.ɵfac = /* @__PURE__ */(() => {
  let ɵIonRouterLinkWithHref_BaseFactory;
  return function IonRouterLinkWithHref_Factory(__ngFactoryType__) {
    return (ɵIonRouterLinkWithHref_BaseFactory || (ɵIonRouterLinkWithHref_BaseFactory = i0.ɵɵgetInheritedFactory(IonRouterLinkWithHref)))(__ngFactoryType__ || IonRouterLinkWithHref);
  };
})();
/** @nocollapse */
IonRouterLinkWithHref.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IonRouterLinkWithHref,
  selectors: [["a", "routerLink", ""], ["area", "routerLink", ""]],
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRouterLinkWithHref, [{
    type: Directive,
    args: [{
      selector: 'a[routerLink],area[routerLink]',
      standalone: true
    }]
  }], null, null);
})();

/* eslint-disable */
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let IonAccordion = class IonAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAccordion.ɵfac = function IonAccordion_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAccordion)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAccordion.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAccordion,
  selectors: [["ion-accordion"]],
  inputs: {
    disabled: "disabled",
    mode: "mode",
    readonly: "readonly",
    toggleIcon: "toggleIcon",
    toggleIconSlot: "toggleIconSlot",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAccordion_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAccordion = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$5,
  inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
})], IonAccordion);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAccordion, [{
    type: Component,
    args: [{
      selector: 'ion-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonAccordionGroup = class IonAccordionGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonAccordionGroup.ɵfac = function IonAccordionGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAccordionGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAccordionGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAccordionGroup,
  selectors: [["ion-accordion-group"]],
  inputs: {
    animated: "animated",
    disabled: "disabled",
    expand: "expand",
    mode: "mode",
    multiple: "multiple",
    readonly: "readonly",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAccordionGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAccordionGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$6,
  inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
})], IonAccordionGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAccordionGroup, [{
    type: Component,
    args: [{
      selector: 'ion-accordion-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonActionSheet = class IonActionSheet {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionActionSheetDidPresent', 'ionActionSheetWillPresent', 'ionActionSheetWillDismiss', 'ionActionSheetDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonActionSheet.ɵfac = function IonActionSheet_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonActionSheet)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonActionSheet.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonActionSheet,
  selectors: [["ion-action-sheet"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonActionSheet_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonActionSheet = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$7,
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonActionSheet);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonActionSheet, [{
    type: Component,
    args: [{
      selector: 'ion-action-sheet',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonAlert = class IonAlert {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionAlertDidPresent', 'ionAlertWillPresent', 'ionAlertWillDismiss', 'ionAlertDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonAlert.ɵfac = function IonAlert_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAlert)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAlert.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAlert,
  selectors: [["ion-alert"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    inputs: "inputs",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAlert_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAlert = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$8,
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonAlert);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAlert, [{
    type: Component,
    args: [{
      selector: 'ion-alert',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonApp = class IonApp {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonApp.ɵfac = function IonApp_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonApp)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonApp.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonApp,
  selectors: [["ion-app"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonApp_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonApp = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$9
})], IonApp);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonApp, [{
    type: Component,
    args: [{
      selector: 'ion-app',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonAvatar = class IonAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAvatar.ɵfac = function IonAvatar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonAvatar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonAvatar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonAvatar,
  selectors: [["ion-avatar"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonAvatar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonAvatar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$a
})], IonAvatar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonAvatar, [{
    type: Component,
    args: [{
      selector: 'ion-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBackdrop = class IonBackdrop {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionBackdropTap']);
  }
};
/** @nocollapse */
IonBackdrop.ɵfac = function IonBackdrop_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBackdrop)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBackdrop.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBackdrop,
  selectors: [["ion-backdrop"]],
  inputs: {
    stopPropagation: "stopPropagation",
    tappable: "tappable",
    visible: "visible"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBackdrop_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBackdrop = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$b,
  inputs: ['stopPropagation', 'tappable', 'visible']
})], IonBackdrop);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBackdrop, [{
    type: Component,
    args: [{
      selector: 'ion-backdrop',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['stopPropagation', 'tappable', 'visible'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBadge = class IonBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonBadge.ɵfac = function IonBadge_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBadge.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBadge,
  selectors: [["ion-badge"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBadge_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBadge = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$c,
  inputs: ['color', 'mode']
})], IonBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBadge, [{
    type: Component,
    args: [{
      selector: 'ion-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBreadcrumb = class IonBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonBreadcrumb.ɵfac = function IonBreadcrumb_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBreadcrumb.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBreadcrumb,
  selectors: [["ion-breadcrumb"]],
  inputs: {
    active: "active",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    separator: "separator",
    target: "target"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBreadcrumb_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBreadcrumb = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$d,
  inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
})], IonBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBreadcrumb, [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonBreadcrumbs = class IonBreadcrumbs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCollapsedClick']);
  }
};
/** @nocollapse */
IonBreadcrumbs.ɵfac = function IonBreadcrumbs_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonBreadcrumbs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonBreadcrumbs.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonBreadcrumbs,
  selectors: [["ion-breadcrumbs"]],
  inputs: {
    color: "color",
    itemsAfterCollapse: "itemsAfterCollapse",
    itemsBeforeCollapse: "itemsBeforeCollapse",
    maxItems: "maxItems",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonBreadcrumbs_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonBreadcrumbs = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$e,
  inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
})], IonBreadcrumbs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonBreadcrumbs, [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumbs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonButton = class IonButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonButton.ɵfac = function IonButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonButton,
  selectors: [["ion-button"]],
  inputs: {
    buttonType: "buttonType",
    color: "color",
    disabled: "disabled",
    download: "download",
    expand: "expand",
    fill: "fill",
    form: "form",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    size: "size",
    strong: "strong",
    target: "target",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$f,
  inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
})], IonButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonButton, [{
    type: Component,
    args: [{
      selector: 'ion-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonButtons = class IonButtons {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonButtons.ɵfac = function IonButtons_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonButtons)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonButtons.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonButtons,
  selectors: [["ion-buttons"]],
  inputs: {
    collapse: "collapse"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonButtons_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonButtons = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$g,
  inputs: ['collapse']
})], IonButtons);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonButtons, [{
    type: Component,
    args: [{
      selector: 'ion-buttons',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCard = class IonCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCard.ɵfac = function IonCard_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCard.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCard,
  selectors: [["ion-card"]],
  inputs: {
    button: "button",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    target: "target",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCard_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCard = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$h,
  inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
})], IonCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCard, [{
    type: Component,
    args: [{
      selector: 'ion-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardContent = class IonCardContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardContent.ɵfac = function IonCardContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardContent,
  selectors: [["ion-card-content"]],
  inputs: {
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$i,
  inputs: ['mode']
})], IonCardContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardContent, [{
    type: Component,
    args: [{
      selector: 'ion-card-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardHeader = class IonCardHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardHeader.ɵfac = function IonCardHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardHeader,
  selectors: [["ion-card-header"]],
  inputs: {
    color: "color",
    mode: "mode",
    translucent: "translucent"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$j,
  inputs: ['color', 'mode', 'translucent']
})], IonCardHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardHeader, [{
    type: Component,
    args: [{
      selector: 'ion-card-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'translucent'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardSubtitle = class IonCardSubtitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardSubtitle.ɵfac = function IonCardSubtitle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardSubtitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardSubtitle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardSubtitle,
  selectors: [["ion-card-subtitle"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardSubtitle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardSubtitle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$k,
  inputs: ['color', 'mode']
})], IonCardSubtitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardSubtitle, [{
    type: Component,
    args: [{
      selector: 'ion-card-subtitle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCardTitle = class IonCardTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardTitle.ɵfac = function IonCardTitle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCardTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCardTitle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCardTitle,
  selectors: [["ion-card-title"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCardTitle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCardTitle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$l,
  inputs: ['color', 'mode']
})], IonCardTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCardTitle, [{
    type: Component,
    args: [{
      selector: 'ion-card-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonChip = class IonChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonChip.ɵfac = function IonChip_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonChip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonChip.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonChip,
  selectors: [["ion-chip"]],
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    outline: "outline"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonChip_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonChip = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$m,
  inputs: ['color', 'disabled', 'mode', 'outline']
})], IonChip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonChip, [{
    type: Component,
    args: [{
      selector: 'ion-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'mode', 'outline'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonCol = class IonCol {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCol.ɵfac = function IonCol_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCol)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonCol.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCol,
  selectors: [["ion-col"]],
  inputs: {
    offset: "offset",
    offsetLg: "offsetLg",
    offsetMd: "offsetMd",
    offsetSm: "offsetSm",
    offsetXl: "offsetXl",
    offsetXs: "offsetXs",
    pull: "pull",
    pullLg: "pullLg",
    pullMd: "pullMd",
    pullSm: "pullSm",
    pullXl: "pullXl",
    pullXs: "pullXs",
    push: "push",
    pushLg: "pushLg",
    pushMd: "pushMd",
    pushSm: "pushSm",
    pushXl: "pushXl",
    pushXs: "pushXs",
    size: "size",
    sizeLg: "sizeLg",
    sizeMd: "sizeMd",
    sizeSm: "sizeSm",
    sizeXl: "sizeXl",
    sizeXs: "sizeXs"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCol_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCol = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$n,
  inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
})], IonCol);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCol, [{
    type: Component,
    args: [{
      selector: 'ion-col',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonContent = class IonContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionScrollStart', 'ionScroll', 'ionScrollEnd']);
  }
};
/** @nocollapse */
IonContent.ɵfac = function IonContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonContent,
  selectors: [["ion-content"]],
  inputs: {
    color: "color",
    forceOverscroll: "forceOverscroll",
    fullscreen: "fullscreen",
    scrollEvents: "scrollEvents",
    scrollX: "scrollX",
    scrollY: "scrollY"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$o,
  inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY'],
  methods: ['getScrollElement', 'scrollToTop', 'scrollToBottom', 'scrollByPoint', 'scrollToPoint']
})], IonContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonContent, [{
    type: Component,
    args: [{
      selector: 'ion-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonDatetimeButton = class IonDatetimeButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonDatetimeButton.ɵfac = function IonDatetimeButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonDatetimeButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonDatetimeButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonDatetimeButton,
  selectors: [["ion-datetime-button"]],
  inputs: {
    color: "color",
    datetime: "datetime",
    disabled: "disabled",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonDatetimeButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonDatetimeButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$p,
  inputs: ['color', 'datetime', 'disabled', 'mode']
})], IonDatetimeButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonDatetimeButton, [{
    type: Component,
    args: [{
      selector: 'ion-datetime-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'datetime', 'disabled', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFab = class IonFab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFab.ɵfac = function IonFab_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFab.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFab,
  selectors: [["ion-fab"]],
  inputs: {
    activated: "activated",
    edge: "edge",
    horizontal: "horizontal",
    vertical: "vertical"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFab_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFab = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$q,
  inputs: ['activated', 'edge', 'horizontal', 'vertical'],
  methods: ['close']
})], IonFab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFab, [{
    type: Component,
    args: [{
      selector: 'ion-fab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'edge', 'horizontal', 'vertical'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFabButton = class IonFabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonFabButton.ɵfac = function IonFabButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFabButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFabButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFabButton,
  selectors: [["ion-fab-button"]],
  inputs: {
    activated: "activated",
    closeIcon: "closeIcon",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    show: "show",
    size: "size",
    target: "target",
    translucent: "translucent",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFabButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFabButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$r,
  inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
})], IonFabButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFabButton, [{
    type: Component,
    args: [{
      selector: 'ion-fab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFabList = class IonFabList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFabList.ɵfac = function IonFabList_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFabList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFabList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFabList,
  selectors: [["ion-fab-list"]],
  inputs: {
    activated: "activated",
    side: "side"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFabList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFabList = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$s,
  inputs: ['activated', 'side']
})], IonFabList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFabList, [{
    type: Component,
    args: [{
      selector: 'ion-fab-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'side'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonFooter = class IonFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFooter.ɵfac = function IonFooter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonFooter,
  selectors: [["ion-footer"]],
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonFooter = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$t,
  inputs: ['collapse', 'mode', 'translucent']
})], IonFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonFooter, [{
    type: Component,
    args: [{
      selector: 'ion-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonGrid = class IonGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonGrid.ɵfac = function IonGrid_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonGrid)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonGrid.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonGrid,
  selectors: [["ion-grid"]],
  inputs: {
    fixed: "fixed"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonGrid_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonGrid = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$u,
  inputs: ['fixed']
})], IonGrid);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonGrid, [{
    type: Component,
    args: [{
      selector: 'ion-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fixed'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonHeader = class IonHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonHeader.ɵfac = function IonHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonHeader,
  selectors: [["ion-header"]],
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$v,
  inputs: ['collapse', 'mode', 'translucent']
})], IonHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonHeader, [{
    type: Component,
    args: [{
      selector: 'ion-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonImg = class IonImg {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionImgWillLoad', 'ionImgDidLoad', 'ionError']);
  }
};
/** @nocollapse */
IonImg.ɵfac = function IonImg_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonImg)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonImg.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonImg,
  selectors: [["ion-img"]],
  inputs: {
    alt: "alt",
    src: "src"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonImg_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonImg = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$w,
  inputs: ['alt', 'src']
})], IonImg);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonImg, [{
    type: Component,
    args: [{
      selector: 'ion-img',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alt', 'src'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonInfiniteScroll = class IonInfiniteScroll {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInfinite']);
  }
};
/** @nocollapse */
IonInfiniteScroll.ɵfac = function IonInfiniteScroll_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonInfiniteScroll)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonInfiniteScroll.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonInfiniteScroll,
  selectors: [["ion-infinite-scroll"]],
  inputs: {
    disabled: "disabled",
    position: "position",
    threshold: "threshold"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonInfiniteScroll_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonInfiniteScroll = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$x,
  inputs: ['disabled', 'position', 'threshold'],
  methods: ['complete']
})], IonInfiniteScroll);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonInfiniteScroll, [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'position', 'threshold'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonInfiniteScrollContent = class IonInfiniteScrollContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonInfiniteScrollContent.ɵfac = function IonInfiniteScrollContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonInfiniteScrollContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonInfiniteScrollContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonInfiniteScrollContent,
  selectors: [["ion-infinite-scroll-content"]],
  inputs: {
    loadingSpinner: "loadingSpinner",
    loadingText: "loadingText"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonInfiniteScrollContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonInfiniteScrollContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$y,
  inputs: ['loadingSpinner', 'loadingText']
})], IonInfiniteScrollContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonInfiniteScrollContent, [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['loadingSpinner', 'loadingText'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItem = class IonItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItem.ɵfac = function IonItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItem,
  selectors: [["ion-item"]],
  inputs: {
    button: "button",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    detail: "detail",
    detailIcon: "detailIcon",
    disabled: "disabled",
    download: "download",
    fill: "fill",
    href: "href",
    lines: "lines",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    target: "target",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItem = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$z,
  inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
})], IonItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItem, [{
    type: Component,
    args: [{
      selector: 'ion-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemDivider = class IonItemDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemDivider.ɵfac = function IonItemDivider_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemDivider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemDivider,
  selectors: [["ion-item-divider"]],
  inputs: {
    color: "color",
    mode: "mode",
    sticky: "sticky"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemDivider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemDivider = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$A,
  inputs: ['color', 'mode', 'sticky']
})], IonItemDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemDivider, [{
    type: Component,
    args: [{
      selector: 'ion-item-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'sticky'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemGroup = class IonItemGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemGroup.ɵfac = function IonItemGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemGroup,
  selectors: [["ion-item-group"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$B
})], IonItemGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemGroup, [{
    type: Component,
    args: [{
      selector: 'ion-item-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemOption = class IonItemOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemOption.ɵfac = function IonItemOption_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemOption,
  selectors: [["ion-item-option"]],
  inputs: {
    color: "color",
    disabled: "disabled",
    download: "download",
    expandable: "expandable",
    href: "href",
    mode: "mode",
    rel: "rel",
    target: "target",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemOption = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$C,
  inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
})], IonItemOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemOption, [{
    type: Component,
    args: [{
      selector: 'ion-item-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemOptions = class IonItemOptions {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSwipe']);
  }
};
/** @nocollapse */
IonItemOptions.ɵfac = function IonItemOptions_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemOptions)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemOptions.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemOptions,
  selectors: [["ion-item-options"]],
  inputs: {
    side: "side"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemOptions_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemOptions = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$D,
  inputs: ['side']
})], IonItemOptions);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemOptions, [{
    type: Component,
    args: [{
      selector: 'ion-item-options',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['side'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonItemSliding = class IonItemSliding {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionDrag']);
  }
};
/** @nocollapse */
IonItemSliding.ɵfac = function IonItemSliding_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonItemSliding)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonItemSliding.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonItemSliding,
  selectors: [["ion-item-sliding"]],
  inputs: {
    disabled: "disabled"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonItemSliding_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonItemSliding = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$E,
  inputs: ['disabled'],
  methods: ['getOpenAmount', 'getSlidingRatio', 'open', 'close', 'closeOpened']
})], IonItemSliding);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonItemSliding, [{
    type: Component,
    args: [{
      selector: 'ion-item-sliding',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonLabel = class IonLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonLabel.ɵfac = function IonLabel_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonLabel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonLabel.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonLabel,
  selectors: [["ion-label"]],
  inputs: {
    color: "color",
    mode: "mode",
    position: "position"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonLabel_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonLabel = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$F,
  inputs: ['color', 'mode', 'position']
})], IonLabel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonLabel, [{
    type: Component,
    args: [{
      selector: 'ion-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'position'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonList = class IonList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonList.ɵfac = function IonList_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonList,
  selectors: [["ion-list"]],
  inputs: {
    inset: "inset",
    lines: "lines",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonList = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$G,
  inputs: ['inset', 'lines', 'mode'],
  methods: ['closeSlidingItems']
})], IonList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonList, [{
    type: Component,
    args: [{
      selector: 'ion-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['inset', 'lines', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonListHeader = class IonListHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonListHeader.ɵfac = function IonListHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonListHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonListHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonListHeader,
  selectors: [["ion-list-header"]],
  inputs: {
    color: "color",
    lines: "lines",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonListHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonListHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$H,
  inputs: ['color', 'lines', 'mode']
})], IonListHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonListHeader, [{
    type: Component,
    args: [{
      selector: 'ion-list-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'lines', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonLoading = class IonLoading {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionLoadingDidPresent', 'ionLoadingWillPresent', 'ionLoadingWillDismiss', 'ionLoadingDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonLoading.ɵfac = function IonLoading_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonLoading)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonLoading.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonLoading,
  selectors: [["ion-loading"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    showBackdrop: "showBackdrop",
    spinner: "spinner",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonLoading_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonLoading = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$I,
  inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonLoading);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonLoading, [{
    type: Component,
    args: [{
      selector: 'ion-loading',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonMenu = class IonMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionWillOpen', 'ionWillClose', 'ionDidOpen', 'ionDidClose']);
  }
};
/** @nocollapse */
IonMenu.ɵfac = function IonMenu_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonMenu,
  selectors: [["ion-menu"]],
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    maxEdgeStart: "maxEdgeStart",
    menuId: "menuId",
    side: "side",
    swipeGesture: "swipeGesture",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonMenu = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$J,
  inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type'],
  methods: ['isOpen', 'isActive', 'open', 'close', 'toggle', 'setOpen']
})], IonMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMenu, [{
    type: Component,
    args: [{
      selector: 'ion-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonMenuButton = class IonMenuButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuButton.ɵfac = function IonMenuButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonMenuButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonMenuButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonMenuButton,
  selectors: [["ion-menu-button"]],
  inputs: {
    autoHide: "autoHide",
    color: "color",
    disabled: "disabled",
    menu: "menu",
    mode: "mode",
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonMenuButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonMenuButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$K,
  inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
})], IonMenuButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMenuButton, [{
    type: Component,
    args: [{
      selector: 'ion-menu-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonMenuToggle = class IonMenuToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuToggle.ɵfac = function IonMenuToggle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonMenuToggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonMenuToggle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonMenuToggle,
  selectors: [["ion-menu-toggle"]],
  inputs: {
    autoHide: "autoHide",
    menu: "menu"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonMenuToggle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonMenuToggle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$L,
  inputs: ['autoHide', 'menu']
})], IonMenuToggle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonMenuToggle, [{
    type: Component,
    args: [{
      selector: 'ion-menu-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'menu'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonNavLink = class IonNavLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNavLink.ɵfac = function IonNavLink_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonNavLink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonNavLink.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonNavLink,
  selectors: [["ion-nav-link"]],
  inputs: {
    component: "component",
    componentProps: "componentProps",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonNavLink_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonNavLink = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$M,
  inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
})], IonNavLink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonNavLink, [{
    type: Component,
    args: [{
      selector: 'ion-nav-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonNote = class IonNote {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNote.ɵfac = function IonNote_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonNote)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonNote.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonNote,
  selectors: [["ion-note"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonNote_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonNote = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$N,
  inputs: ['color', 'mode']
})], IonNote);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonNote, [{
    type: Component,
    args: [{
      selector: 'ion-note',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonPicker = class IonPicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionPickerDidPresent', 'ionPickerWillPresent', 'ionPickerWillDismiss', 'ionPickerDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonPicker.ɵfac = function IonPicker_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonPicker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonPicker.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonPicker,
  selectors: [["ion-picker"]],
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    columns: "columns",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    showBackdrop: "showBackdrop",
    trigger: "trigger"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonPicker_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonPicker = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$O,
  inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss', 'getColumn']
})], IonPicker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonPicker, [{
    type: Component,
    args: [{
      selector: 'ion-picker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonProgressBar = class IonProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonProgressBar.ɵfac = function IonProgressBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonProgressBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonProgressBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonProgressBar,
  selectors: [["ion-progress-bar"]],
  inputs: {
    buffer: "buffer",
    color: "color",
    mode: "mode",
    reversed: "reversed",
    type: "type",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonProgressBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonProgressBar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$P,
  inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
})], IonProgressBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonProgressBar, [{
    type: Component,
    args: [{
      selector: 'ion-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRefresher = class IonRefresher {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionRefresh', 'ionPull', 'ionStart']);
  }
};
/** @nocollapse */
IonRefresher.ɵfac = function IonRefresher_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRefresher)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRefresher.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRefresher,
  selectors: [["ion-refresher"]],
  inputs: {
    closeDuration: "closeDuration",
    disabled: "disabled",
    mode: "mode",
    pullFactor: "pullFactor",
    pullMax: "pullMax",
    pullMin: "pullMin",
    snapbackDuration: "snapbackDuration"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRefresher_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRefresher = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Q,
  inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration'],
  methods: ['complete', 'cancel', 'getProgress']
})], IonRefresher);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRefresher, [{
    type: Component,
    args: [{
      selector: 'ion-refresher',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRefresherContent = class IonRefresherContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRefresherContent.ɵfac = function IonRefresherContent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRefresherContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRefresherContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRefresherContent,
  selectors: [["ion-refresher-content"]],
  inputs: {
    pullingIcon: "pullingIcon",
    pullingText: "pullingText",
    refreshingSpinner: "refreshingSpinner",
    refreshingText: "refreshingText"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRefresherContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRefresherContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$R,
  inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
})], IonRefresherContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRefresherContent, [{
    type: Component,
    args: [{
      selector: 'ion-refresher-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonReorder = class IonReorder {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonReorder.ɵfac = function IonReorder_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonReorder)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonReorder.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonReorder,
  selectors: [["ion-reorder"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonReorder_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonReorder = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$S
})], IonReorder);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonReorder, [{
    type: Component,
    args: [{
      selector: 'ion-reorder',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonReorderGroup = class IonReorderGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionItemReorder']);
  }
};
/** @nocollapse */
IonReorderGroup.ɵfac = function IonReorderGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonReorderGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonReorderGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonReorderGroup,
  selectors: [["ion-reorder-group"]],
  inputs: {
    disabled: "disabled"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonReorderGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonReorderGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$T,
  inputs: ['disabled'],
  methods: ['complete']
})], IonReorderGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonReorderGroup, [{
    type: Component,
    args: [{
      selector: 'ion-reorder-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRippleEffect = class IonRippleEffect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRippleEffect.ɵfac = function IonRippleEffect_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRippleEffect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRippleEffect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRippleEffect,
  selectors: [["ion-ripple-effect"]],
  inputs: {
    type: "type"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRippleEffect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRippleEffect = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$U,
  inputs: ['type'],
  methods: ['addRipple']
})], IonRippleEffect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRippleEffect, [{
    type: Component,
    args: [{
      selector: 'ion-ripple-effect',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonRow = class IonRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRow.ɵfac = function IonRow_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonRow.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRow,
  selectors: [["ion-row"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRow_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRow = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$V
})], IonRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRow, [{
    type: Component,
    args: [{
      selector: 'ion-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSegmentButton = class IonSegmentButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSegmentButton.ɵfac = function IonSegmentButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSegmentButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSegmentButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSegmentButton,
  selectors: [["ion-segment-button"]],
  inputs: {
    disabled: "disabled",
    layout: "layout",
    mode: "mode",
    type: "type",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSegmentButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSegmentButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$W,
  inputs: ['disabled', 'layout', 'mode', 'type', 'value']
})], IonSegmentButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSegmentButton, [{
    type: Component,
    args: [{
      selector: 'ion-segment-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'layout', 'mode', 'type', 'value'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSelectOption = class IonSelectOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSelectOption.ɵfac = function IonSelectOption_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSelectOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSelectOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSelectOption,
  selectors: [["ion-select-option"]],
  inputs: {
    disabled: "disabled",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSelectOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSelectOption = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$X,
  inputs: ['disabled', 'value']
})], IonSelectOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSelectOption, [{
    type: Component,
    args: [{
      selector: 'ion-select-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'value'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSkeletonText = class IonSkeletonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSkeletonText.ɵfac = function IonSkeletonText_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSkeletonText)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSkeletonText.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSkeletonText,
  selectors: [["ion-skeleton-text"]],
  inputs: {
    animated: "animated"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSkeletonText_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSkeletonText = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Y,
  inputs: ['animated']
})], IonSkeletonText);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSkeletonText, [{
    type: Component,
    args: [{
      selector: 'ion-skeleton-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSpinner = class IonSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSpinner.ɵfac = function IonSpinner_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSpinner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSpinner.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSpinner,
  selectors: [["ion-spinner"]],
  inputs: {
    color: "color",
    duration: "duration",
    name: "name",
    paused: "paused"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSpinner_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSpinner = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Z,
  inputs: ['color', 'duration', 'name', 'paused']
})], IonSpinner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSpinner, [{
    type: Component,
    args: [{
      selector: 'ion-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'duration', 'name', 'paused'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonSplitPane = class IonSplitPane {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSplitPaneVisible']);
  }
};
/** @nocollapse */
IonSplitPane.ɵfac = function IonSplitPane_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSplitPane)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonSplitPane.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSplitPane,
  selectors: [["ion-split-pane"]],
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    when: "when"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSplitPane_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSplitPane = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$_,
  inputs: ['contentId', 'disabled', 'when']
})], IonSplitPane);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSplitPane, [{
    type: Component,
    args: [{
      selector: 'ion-split-pane',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'when'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonTabBar = class IonTabBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabBar.ɵfac = function IonTabBar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTabBar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonTabBar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTabBar,
  selectors: [["ion-tab-bar"]],
  inputs: {
    color: "color",
    mode: "mode",
    selectedTab: "selectedTab",
    translucent: "translucent"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTabBar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTabBar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$$,
  inputs: ['color', 'mode', 'selectedTab', 'translucent']
})], IonTabBar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTabBar, [{
    type: Component,
    args: [{
      selector: 'ion-tab-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'selectedTab', 'translucent'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonTabButton = class IonTabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabButton.ɵfac = function IonTabButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTabButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonTabButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTabButton,
  selectors: [["ion-tab-button"]],
  inputs: {
    disabled: "disabled",
    download: "download",
    href: "href",
    layout: "layout",
    mode: "mode",
    rel: "rel",
    selected: "selected",
    tab: "tab",
    target: "target"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTabButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTabButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$10,
  inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
})], IonTabButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTabButton, [{
    type: Component,
    args: [{
      selector: 'ion-tab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonText = class IonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonText.ɵfac = function IonText_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonText)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonText.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonText,
  selectors: [["ion-text"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonText_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonText = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$11,
  inputs: ['color', 'mode']
})], IonText);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonText, [{
    type: Component,
    args: [{
      selector: 'ion-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonThumbnail = class IonThumbnail {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonThumbnail.ɵfac = function IonThumbnail_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonThumbnail)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonThumbnail.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonThumbnail,
  selectors: [["ion-thumbnail"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonThumbnail_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonThumbnail = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$12
})], IonThumbnail);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonThumbnail, [{
    type: Component,
    args: [{
      selector: 'ion-thumbnail',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonTitle = class IonTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTitle.ɵfac = function IonTitle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonTitle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTitle,
  selectors: [["ion-title"]],
  inputs: {
    color: "color",
    size: "size"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTitle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTitle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$13,
  inputs: ['color', 'size']
})], IonTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTitle, [{
    type: Component,
    args: [{
      selector: 'ion-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'size'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonToast = class IonToast {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionToastDidPresent', 'ionToastWillPresent', 'ionToastWillDismiss', 'ionToastDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonToast.ɵfac = function IonToast_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonToast)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonToast.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonToast,
  selectors: [["ion-toast"]],
  inputs: {
    animated: "animated",
    buttons: "buttons",
    color: "color",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    icon: "icon",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    layout: "layout",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    position: "position",
    positionAnchor: "positionAnchor",
    swipeGesture: "swipeGesture",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonToast_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonToast = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$14,
  inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonToast);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonToast, [{
    type: Component,
    args: [{
      selector: 'ion-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
let IonToolbar = class IonToolbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonToolbar.ɵfac = function IonToolbar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonToolbar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonToolbar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonToolbar,
  selectors: [["ion-toolbar"]],
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonToolbar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonToolbar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$15,
  inputs: ['color', 'mode']
})], IonToolbar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonToolbar, [{
    type: Component,
    args: [{
      selector: 'ion-toolbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();

// eslint-disable-next-line @angular-eslint/component-class-suffix
class IonTabs extends IonTabs$1 {}
/** @nocollapse */
IonTabs.ɵfac = /* @__PURE__ */(() => {
  let ɵIonTabs_BaseFactory;
  return function IonTabs_Factory(__ngFactoryType__) {
    return (ɵIonTabs_BaseFactory || (ɵIonTabs_BaseFactory = i0.ɵɵgetInheritedFactory(IonTabs)))(__ngFactoryType__ || IonTabs);
  };
})();
/** @nocollapse */
IonTabs.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTabs,
  selectors: [["ion-tabs"]],
  contentQueries: function IonTabs_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, IonTabBar, 5);
      i0.ɵɵcontentQuery(dirIndex, IonTabBar, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabBar = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabBars = _t);
    }
  },
  viewQuery: function IonTabs_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c1, 5, IonRouterOutlet);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.outlet = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c3,
  decls: 6,
  vars: 0,
  consts: [["tabsInner", ""], ["outlet", ""], [1, "tabs-inner"], ["tabs", "true", 3, "stackWillChange", "stackDidChange"]],
  template: function IonTabs_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c2);
      i0.ɵɵprojection(0);
      i0.ɵɵelementStart(1, "div", 2, 0)(3, "ion-router-outlet", 3, 1);
      i0.ɵɵlistener("stackWillChange", function IonTabs_Template_ion_router_outlet_stackWillChange_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onStackWillChange($event));
      })("stackDidChange", function IonTabs_Template_ion_router_outlet_stackDidChange_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onStackDidChange($event));
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵprojection(5, 1);
    }
  },
  dependencies: [IonRouterOutlet],
  styles: ["[_nghost-%COMP%]{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner[_ngcontent-%COMP%]{position:relative;flex:1;contain:layout size style}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTabs, [{
    type: Component,
    args: [{
      selector: 'ion-tabs',
      template: `
    <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner" #tabsInner>
      <ion-router-outlet
        #outlet
        tabs="true"
        (stackWillChange)="onStackWillChange($event)"
        (stackDidChange)="onStackDidChange($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>
  `,
      standalone: true,
      imports: [IonRouterOutlet],
      styles: [":host{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner{position:relative;flex:1;contain:layout size style}\n"]
    }]
  }], null, {
    outlet: [{
      type: ViewChild,
      args: ['outlet', {
        read: IonRouterOutlet,
        static: false
      }]
    }],
    tabBar: [{
      type: ContentChild,
      args: [IonTabBar, {
        static: false
      }]
    }],
    tabBars: [{
      type: ContentChildren,
      args: [IonTabBar]
    }]
  });
})();
class ModalController extends OverlayBaseController {
  constructor() {
    super(modalController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
    defineCustomElement$3();
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'modal')
    });
  }
}
/** @nocollapse */
ModalController.ɵfac = function ModalController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ModalController)();
};
/** @nocollapse */
ModalController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ModalController,
  factory: ModalController.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalController, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class PopoverController extends OverlayBaseController {
  constructor() {
    super(popoverController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
    defineCustomElement$4();
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'popover')
    });
  }
}
const provideIonicAngular = config => {
  /**
   * TODO FW-4967
   * Use makeEnvironmentProviders once Angular 14 support is dropped.
   * This prevents provideIonicAngular from being accidentally referenced in an @Component.
   */
  return [{
    provide: ConfigToken,
    useValue: config
  }, {
    provide: APP_INITIALIZER,
    useFactory: initializeIonicAngular,
    multi: true,
    deps: [ConfigToken, DOCUMENT]
  }, provideComponentInputBinding(), AngularDelegate, ModalController, PopoverController];
};
const initializeIonicAngular = (config, doc) => {
  return () => {
    /**
     * By default Ionic Framework hides elements that
     * are not hydrated, but in the CE build there is no
     * hydration.
     * TODO FW-2797: Remove when all integrations have been
     * migrated to CE build.
     */
    doc.documentElement.classList.add('ion-ce');
    initialize(config);
  };
};
class ActionSheetController extends OverlayBaseController {
  constructor() {
    super(actionSheetController);
    defineCustomElement$7();
  }
}
/** @nocollapse */
ActionSheetController.ɵfac = function ActionSheetController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetController)();
};
/** @nocollapse */
ActionSheetController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ActionSheetController,
  factory: ActionSheetController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class AlertController extends OverlayBaseController {
  constructor() {
    super(alertController);
    defineCustomElement$8();
  }
}
/** @nocollapse */
AlertController.ɵfac = function AlertController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AlertController)();
};
/** @nocollapse */
AlertController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AlertController,
  factory: AlertController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlertController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class AnimationController {
  /**
   * Create a new animation
   */
  create(animationId) {
    return createAnimation(animationId);
  }
  /**
   * EXPERIMENTAL
   *
   * Given a progression and a cubic bezier function,
   * this utility returns the time value(s) at which the
   * cubic bezier reaches the given time progression.
   *
   * If the cubic bezier never reaches the progression
   * the result will be an empty array.
   *
   * This is most useful for switching between easing curves
   * when doing a gesture animation (i.e. going from linear easing
   * during a drag, to another easing when `progressEnd` is called)
   */
  easingTime(p0, p1, p2, p3, progression) {
    return getTimeGivenProgression(p0, p1, p2, p3, progression);
  }
}
/** @nocollapse */
AnimationController.ɵfac = function AnimationController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AnimationController)();
};
/** @nocollapse */
AnimationController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AnimationController,
  factory: AnimationController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimationController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class GestureController {
  constructor(zone) {
    this.zone = zone;
  }
  /**
   * Create a new gesture
   */
  create(opts, runInsideAngularZone = false) {
    if (runInsideAngularZone) {
      Object.getOwnPropertyNames(opts).forEach(key => {
        if (typeof opts[key] === 'function') {
          const fn = opts[key];
          opts[key] = (...props) => this.zone.run(() => fn(...props));
        }
      });
    }
    return createGesture(opts);
  }
}
/** @nocollapse */
GestureController.ɵfac = function GestureController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GestureController)(i0.ɵɵinject(i0.NgZone));
};
/** @nocollapse */
GestureController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: GestureController,
  factory: GestureController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GestureController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
class LoadingController extends OverlayBaseController {
  constructor() {
    super(loadingController);
    defineCustomElement$I();
  }
}
/** @nocollapse */
LoadingController.ɵfac = function LoadingController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoadingController)();
};
/** @nocollapse */
LoadingController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LoadingController,
  factory: LoadingController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class MenuController extends MenuController$1 {
  constructor() {
    super(menuController);
  }
}
/** @nocollapse */
MenuController.ɵfac = function MenuController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuController)();
};
/** @nocollapse */
MenuController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MenuController,
  factory: MenuController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class PickerController extends OverlayBaseController {
  constructor() {
    super(pickerController);
    defineCustomElement$O();
  }
}
/** @nocollapse */
PickerController.ɵfac = function PickerController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PickerController)();
};
/** @nocollapse */
PickerController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PickerController,
  factory: PickerController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PickerController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class ToastController extends OverlayBaseController {
  constructor() {
    super(toastController);
    defineCustomElement$14();
  }
}
/** @nocollapse */
ToastController.ɵfac = function ToastController_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToastController)();
};
/** @nocollapse */
ToastController.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ToastController,
  factory: ToastController.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastController, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
let IonNav = class IonNav extends IonNav$1 {
  constructor(ref, environmentInjector, injector, angularDelegate, z, c) {
    super(ref, environmentInjector, injector, angularDelegate, z, c);
  }
};
/** @nocollapse */
IonNav.ɵfac = function IonNav_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonNav)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.EnvironmentInjector), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i2$1.AngularDelegate), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
IonNav.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonNav,
  selectors: [["ion-nav"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonNav_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
IonNav = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$16
})], IonNav);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonNav, [{
    type: Component,
    args: [{
      selector: 'ion-nav',
      template: '<ng-content></ng-content>',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.EnvironmentInjector
    }, {
      type: i0.Injector
    }, {
      type: i2$1.AngularDelegate
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();
const CHECKBOX_INPUTS = ['checked', 'color', 'disabled', 'indeterminate', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$a = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonCheckbox),
  multi: true
};
let IonCheckbox = class IonCheckbox extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
  writeValue(value) {
    this.elementRef.nativeElement.checked = this.lastValue = value;
    setIonicClasses(this.elementRef);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.checked);
  }
};
/** @nocollapse */
IonCheckbox.ɵfac = function IonCheckbox_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonCheckbox,
  selectors: [["ion-checkbox"]],
  hostBindings: function IonCheckbox_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function IonCheckbox_ionChange_HostBindingHandler($event) {
        return ctx.handleIonChange($event.target);
      });
    }
  },
  inputs: {
    checked: "checked",
    color: "color",
    disabled: "disabled",
    indeterminate: "indeterminate",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$a]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonCheckbox = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$17,
  inputs: CHECKBOX_INPUTS
})], IonCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonCheckbox, [{
    type: Component,
    args: [{
      selector: 'ion-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: CHECKBOX_INPUTS,
      providers: [accessorProvider$a],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();
const DATETIME_INPUTS = ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.

 */
const accessorProvider$9 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonDatetime),
  multi: true
};
let IonDatetime = class IonDatetime extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCancel', 'ionChange', 'ionFocus', 'ionBlur']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonDatetime.ɵfac = function IonDatetime_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonDatetime)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonDatetime.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonDatetime,
  selectors: [["ion-datetime"]],
  hostBindings: function IonDatetime_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function IonDatetime_ionChange_HostBindingHandler($event) {
        return ctx.handleIonChange($event.target);
      });
    }
  },
  inputs: {
    cancelText: "cancelText",
    clearText: "clearText",
    color: "color",
    dayValues: "dayValues",
    disabled: "disabled",
    doneText: "doneText",
    firstDayOfWeek: "firstDayOfWeek",
    highlightedDates: "highlightedDates",
    hourCycle: "hourCycle",
    hourValues: "hourValues",
    isDateEnabled: "isDateEnabled",
    locale: "locale",
    max: "max",
    min: "min",
    minuteValues: "minuteValues",
    mode: "mode",
    monthValues: "monthValues",
    multiple: "multiple",
    name: "name",
    preferWheel: "preferWheel",
    presentation: "presentation",
    readonly: "readonly",
    showClearButton: "showClearButton",
    showDefaultButtons: "showDefaultButtons",
    showDefaultTimeLabel: "showDefaultTimeLabel",
    showDefaultTitle: "showDefaultTitle",
    size: "size",
    titleSelectedDatesFormatter: "titleSelectedDatesFormatter",
    value: "value",
    yearValues: "yearValues"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$9]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonDatetime_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonDatetime = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$18,
  inputs: DATETIME_INPUTS,
  methods: ['confirm', 'reset', 'cancel']
})], IonDatetime);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonDatetime, [{
    type: Component,
    args: [{
      selector: 'ion-datetime',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: DATETIME_INPUTS,
      providers: [accessorProvider$9],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();
let IonIcon = class IonIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonIcon.ɵfac = function IonIcon_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
IonIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonIcon,
  selectors: [["ion-icon"]],
  inputs: {
    color: "color",
    flipRtl: "flipRtl",
    icon: "icon",
    ios: "ios",
    lazy: "lazy",
    md: "md",
    mode: "mode",
    name: "name",
    sanitize: "sanitize",
    size: "size",
    src: "src"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonIcon = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$19,
  inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
})], IonIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonIcon, [{
    type: Component,
    args: [{
      selector: 'ion-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src'],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const INPUT_INPUTS = ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'shape', 'size', 'spellcheck', 'step', 'type', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$8 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonInput),
  multi: true
};
let IonInput = class IonInput extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionBlur', 'ionFocus']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      if (this.type === 'number') {
        /**
         * If the input type is `number`, we need to convert the value to a number
         * when the value is not empty. If the value is empty, we want to treat
         * the value as null.
         */
        fn(value === '' ? null : parseFloat(value));
      } else {
        fn(value);
      }
    });
  }
};
/** @nocollapse */
IonInput.ɵfac = function IonInput_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonInput,
  selectors: [["ion-input"]],
  hostBindings: function IonInput_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionInput", function IonInput_ionInput_HostBindingHandler($event) {
        return ctx.handleIonInput($event.target);
      });
    }
  },
  inputs: {
    accept: "accept",
    autocapitalize: "autocapitalize",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    autofocus: "autofocus",
    clearInput: "clearInput",
    clearOnEdit: "clearOnEdit",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    maxlength: "maxlength",
    min: "min",
    minlength: "minlength",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    pattern: "pattern",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    shape: "shape",
    size: "size",
    spellcheck: "spellcheck",
    step: "step",
    type: "type",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$8]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonInput = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1a,
  inputs: INPUT_INPUTS,
  methods: ['setFocus', 'getInputElement']
})], IonInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonInput, [{
    type: Component,
    args: [{
      selector: 'ion-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: INPUT_INPUTS,
      providers: [accessorProvider$8],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  });
})();
const RADIO_GROUP_INPUTS = ['allowEmptySelection', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE  works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$7 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonRadioGroup),
  multi: true
};
let IonRadioGroup = class IonRadioGroup extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonRadioGroup.ɵfac = function IonRadioGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRadioGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonRadioGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRadioGroup,
  selectors: [["ion-radio-group"]],
  hostBindings: function IonRadioGroup_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function IonRadioGroup_ionChange_HostBindingHandler($event) {
        return ctx.handleIonChange($event.target);
      });
    }
  },
  inputs: {
    allowEmptySelection: "allowEmptySelection",
    name: "name",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$7]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRadioGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRadioGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1b,
  inputs: RADIO_GROUP_INPUTS
})], IonRadioGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRadioGroup, [{
    type: Component,
    args: [{
      selector: 'ion-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: RADIO_GROUP_INPUTS,
      providers: [accessorProvider$7],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();
const RADIO_INPUTS = ['color', 'disabled', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$6 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonRadio),
  multi: true
};
let IonRadio = class IonRadio extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
  handleIonSelect(el) {
    /**
     * The `el` type is any to access the `checked` state property
     * that is not exposed on the type interface.
     */
    this.handleValueChange(el, el.checked);
  }
};
/** @nocollapse */
IonRadio.ɵfac = function IonRadio_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRadio)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonRadio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRadio,
  selectors: [["ion-radio"]],
  hostBindings: function IonRadio_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionSelect", function IonRadio_ionSelect_HostBindingHandler($event) {
        return ctx.handleIonSelect($event.target);
      });
    }
  },
  inputs: {
    color: "color",
    disabled: "disabled",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$6]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRadio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRadio = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1c,
  inputs: RADIO_INPUTS
})], IonRadio);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRadio, [{
    type: Component,
    args: [{
      selector: 'ion-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: RADIO_INPUTS,
      providers: [accessorProvider$6],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonSelect: [{
      type: HostListener,
      args: ['ionSelect', ['$event.target']]
    }]
  });
})();
const RANGE_INPUTS = ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'label', 'labelPlacement', 'legacy', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$5 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonRange),
  multi: true
};
let IonRange = class IonRange extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionFocus', 'ionBlur', 'ionKnobMoveStart', 'ionKnobMoveEnd']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonRange.ɵfac = function IonRange_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonRange)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonRange.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonRange,
  selectors: [["ion-range"]],
  hostBindings: function IonRange_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionInput", function IonRange_ionInput_HostBindingHandler($event) {
        return ctx.handleIonInput($event.target);
      });
    }
  },
  inputs: {
    activeBarStart: "activeBarStart",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    dualKnobs: "dualKnobs",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    min: "min",
    mode: "mode",
    name: "name",
    pin: "pin",
    pinFormatter: "pinFormatter",
    snaps: "snaps",
    step: "step",
    ticks: "ticks",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$5]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonRange_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonRange = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1d,
  inputs: RANGE_INPUTS
})], IonRange);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonRange, [{
    type: Component,
    args: [{
      selector: 'ion-range',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: RANGE_INPUTS,
      providers: [accessorProvider$5],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  });
})();
const SEARCHBAR_INPUTS = ['animated', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'mode', 'name', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$4 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonSearchbar),
  multi: true
};
let IonSearchbar = class IonSearchbar extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionCancel', 'ionClear', 'ionBlur', 'ionFocus']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonSearchbar.ɵfac = function IonSearchbar_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSearchbar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonSearchbar.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSearchbar,
  selectors: [["ion-searchbar"]],
  hostBindings: function IonSearchbar_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionInput", function IonSearchbar_ionInput_HostBindingHandler($event) {
        return ctx.handleIonInput($event.target);
      });
    }
  },
  inputs: {
    animated: "animated",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    cancelButtonIcon: "cancelButtonIcon",
    cancelButtonText: "cancelButtonText",
    clearIcon: "clearIcon",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    inputmode: "inputmode",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    searchIcon: "searchIcon",
    showCancelButton: "showCancelButton",
    showClearButton: "showClearButton",
    spellcheck: "spellcheck",
    type: "type",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$4]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSearchbar_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSearchbar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1e,
  inputs: SEARCHBAR_INPUTS,
  methods: ['setFocus', 'getInputElement']
})], IonSearchbar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSearchbar, [{
    type: Component,
    args: [{
      selector: 'ion-searchbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: SEARCHBAR_INPUTS,
      providers: [accessorProvider$4],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  });
})();
const SEGMENT_INPUTS = ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$3 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonSegment),
  multi: true
};
let IonSegment = class IonSegment extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonSegment.ɵfac = function IonSegment_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSegment)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonSegment.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSegment,
  selectors: [["ion-segment"]],
  hostBindings: function IonSegment_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function IonSegment_ionChange_HostBindingHandler($event) {
        return ctx.handleIonChange($event.target);
      });
    }
  },
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    scrollable: "scrollable",
    selectOnFocus: "selectOnFocus",
    swipeGesture: "swipeGesture",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$3]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSegment_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSegment = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1f,
  inputs: SEGMENT_INPUTS
})], IonSegment);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSegment, [{
    type: Component,
    args: [{
      selector: 'ion-segment',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: SEGMENT_INPUTS,
      providers: [accessorProvider$3],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();
const SELECT_INPUTS = ['cancelText', 'color', 'compareWith', 'disabled', 'expandedIcon', 'fill', 'interface', 'interfaceOptions', 'justify', 'label', 'labelPlacement', 'legacy', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'shape', 'toggleIcon', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$2 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonSelect),
  multi: true
};
let IonSelect = class IonSelect extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionCancel', 'ionDismiss', 'ionFocus', 'ionBlur']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonSelect.ɵfac = function IonSelect_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonSelect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonSelect,
  selectors: [["ion-select"]],
  hostBindings: function IonSelect_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function IonSelect_ionChange_HostBindingHandler($event) {
        return ctx.handleIonChange($event.target);
      });
    }
  },
  inputs: {
    cancelText: "cancelText",
    color: "color",
    compareWith: "compareWith",
    disabled: "disabled",
    expandedIcon: "expandedIcon",
    fill: "fill",
    interface: "interface",
    interfaceOptions: "interfaceOptions",
    justify: "justify",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    okText: "okText",
    placeholder: "placeholder",
    selectedText: "selectedText",
    shape: "shape",
    toggleIcon: "toggleIcon",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$2]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonSelect = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1g,
  inputs: SELECT_INPUTS,
  methods: ['open']
})], IonSelect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonSelect, [{
    type: Component,
    args: [{
      selector: 'ion-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: SELECT_INPUTS,
      providers: [accessorProvider$2],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();
const TEXTAREA_INPUTS = ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'shape', 'spellcheck', 'value', 'wrap'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$1 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonTextarea),
  multi: true
};
let IonTextarea = class IonTextarea extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionBlur', 'ionFocus']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonTextarea.ɵfac = function IonTextarea_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonTextarea)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonTextarea.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonTextarea,
  selectors: [["ion-textarea"]],
  hostBindings: function IonTextarea_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionInput", function IonTextarea_ionInput_HostBindingHandler($event) {
        return ctx.handleIonInput($event.target);
      });
    }
  },
  inputs: {
    autoGrow: "autoGrow",
    autocapitalize: "autocapitalize",
    autofocus: "autofocus",
    clearOnEdit: "clearOnEdit",
    color: "color",
    cols: "cols",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    maxlength: "maxlength",
    minlength: "minlength",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    rows: "rows",
    shape: "shape",
    spellcheck: "spellcheck",
    value: "value",
    wrap: "wrap"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider$1]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonTextarea_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonTextarea = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1h,
  inputs: TEXTAREA_INPUTS,
  methods: ['setFocus', 'getInputElement']
})], IonTextarea);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonTextarea, [{
    type: Component,
    args: [{
      selector: 'ion-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: TEXTAREA_INPUTS,
      providers: [accessorProvider$1],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  });
})();
const TOGGLE_INPUTS = ['checked', 'color', 'disabled', 'enableOnOffLabels', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonToggle),
  multi: true
};
let IonToggle = class IonToggle extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
  writeValue(value) {
    this.elementRef.nativeElement.checked = this.lastValue = value;
    setIonicClasses(this.elementRef);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.checked);
  }
};
/** @nocollapse */
IonToggle.ɵfac = function IonToggle_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonToggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector));
};
/** @nocollapse */
IonToggle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IonToggle,
  selectors: [["ion-toggle"]],
  hostBindings: function IonToggle_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("ionChange", function IonToggle_ionChange_HostBindingHandler($event) {
        return ctx.handleIonChange($event.target);
      });
    }
  },
  inputs: {
    checked: "checked",
    color: "color",
    disabled: "disabled",
    enableOnOffLabels: "enableOnOffLabels",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  features: [i0.ɵɵProvidersFeature([accessorProvider]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function IonToggle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
IonToggle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1i,
  inputs: TOGGLE_INPUTS
})], IonToggle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonToggle, [{
    type: Component,
    args: [{
      selector: 'ion-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: TOGGLE_INPUTS,
      providers: [accessorProvider],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  }, {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ActionSheetController, AlertController, AnimationController, GestureController, IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackButton, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMenu, IonMenuButton, IonMenuToggle, IonModal, IonNav, IonNavLink, IonNote, IonPicker, IonPopover, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRouterLink, IonRouterLinkWithHref, IonRouterOutlet, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar, LoadingController, MenuController, ModalController, PickerController, PopoverController, ToastController, provideIonicAngular };

<div id="appCapsule" class="full-height">

  <div class="section mt-2">
    <div class="card">
      @switch (order.serviceType) {
        @case (ServiceHead.ELECTRICITY) {
          <electricity-order-details [order]="order"/>
        }
        @case (ServiceHead.AIRTIME) {
          <airtime-order-details [order]="order"/>
        }
        @case (ServiceHead.DATA) {
          <data-order-details [order]="order"/>
        }
        @case (ServiceHead.TV) {
          <television-order-details [order]="order"/>
        }
      }
    </div>
  </div>

  <div class="section mt-2 mb-2">
    <div class="section-title text-center fontsize-headingLarge">Select Payment Method</div>
    <div class="row">
      <div class="col-6">
        <div class="selector-box"
             [class.selected]="selectedMethod === 'wallet'"
             (click)="selectMethod('wallet')"
        >
          <div class="title">Wallet</div>
          <div class="value text-center fontsize-headingXLarge">
            <ion-icon name="wallet-outline"></ion-icon>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="selector-box"
             [class.selected]="selectedMethod === 'card'"
             (click)="selectMethod('card')"
        >
          <div class="title">Card</div>
          <div class="value text-center fontsize-headingXLarge">
            <ion-icon name="card-outline"></ion-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">

  <button
    (click)="proceed();"
    type="button" class="btn btn-primary rounded me-2 d-flex"
  >
    @if (proceeding) {
      <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
    }
    Pay
  </button>
  </div>

</div>

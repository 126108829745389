/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { Currency } from './currency';
import { Workflow } from './workflow';
import { BinaryData } from './binary-data';
import { PortalUser } from './portal-user';


export interface Invoice { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: Invoice.StatusEnum;
    deactivatedBy?: PortalUser;
    purpose?: string;
    description?: string;
    paymentStatus?: Invoice.PaymentStatusEnum;
    identifier?: string;
    dueOn?: string;
    totalGrossAmount?: number;
    cumulativeAmountPaid?: number;
    totalAmountPayable?: number;
    systemGenerated?: boolean;
    generatedOn?: string;
    datePaid?: string;
    invoiceNumber?: string;
    totalDiscountAmount?: number;
    type?: Invoice.TypeEnum;
    paymentLink?: string;
    provider?: Invoice.ProviderEnum;
    qrCode?: BinaryData;
    externalInvoice?: BinaryData;
    workflow?: Workflow;
    portalAccount?: PortalAccount;
    currency?: Currency;
    dispatchedBy?: PortalUser;
}
export namespace Invoice {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type PaymentStatusEnum = 'PENDING' | 'PAID' | 'PARTLY_PAID';
    export const PaymentStatusEnum = {
        PENDING: 'PENDING' as PaymentStatusEnum,
        PAID: 'PAID' as PaymentStatusEnum,
        PARTLY_PAID: 'PARTLY_PAID' as PaymentStatusEnum
    };
    export type TypeEnum = 'ELECTRICITY' | 'AIRTIME' | 'DATA' | 'TV' | 'EDUCATION' | 'INSURANCE';
    export const TypeEnum = {
        ELECTRICITY: 'ELECTRICITY' as TypeEnum,
        AIRTIME: 'AIRTIME' as TypeEnum,
        DATA: 'DATA' as TypeEnum,
        TV: 'TV' as TypeEnum,
        EDUCATION: 'EDUCATION' as TypeEnum,
        INSURANCE: 'INSURANCE' as TypeEnum
    };
    export type ProviderEnum = 'VT_PASS';
    export const ProviderEnum = {
        VT_PASS: 'VT_PASS' as ProviderEnum
    };
}



import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-airtime',
    templateUrl: './airtime.component.html',
    styleUrls: ['./airtime.component.scss'],
    imports: [RouterOutlet]
})
export class AirtimeComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Constant} from "../../models/enum/constant";
import { AuthenticationService } from '../../services/authentication.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {

  constructor(private router: Router,
              private keycloakService: KeycloakService,
              private authenticationService: AuthenticationService) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.keycloakService.isLoggedIn()) {
      this.router.navigate(['dashboard']);
      return false;
    } else {
      return true;
    }
  }
}

<div class="modal-content">
  <div class="modal-body">
    <div class="action-sheet-content">
      <div class="iconbox text-success">
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
      <div class="text-center p-2">
        <h3>{{ title }}</h3>
        <p>{{ message }}</p>
      </div>
      <a class="btn btn-primary btn-lg btn-block" (click)="done()">Done</a>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import {
  QueryResultsTransactionPojo,
  TransactionPojo,
  TransactionsControllerService
} from '../../../../sdk/util-sdk';
import { CurrencyPipe, NgClass, UpperCasePipe } from '@angular/common';
import { TransactionsHelper } from '../transactions/TransactionsHelper';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    imports: [
        RouterLink,
        IonicModule,
        CurrencyPipe,
        UpperCasePipe,
        NgClass
    ]
})
export class HomeComponent implements OnInit {

  transactions: QueryResultsTransactionPojo;

  loadingTransactions = false;

  constructor(
    private router: Router,
    private transactionsController: TransactionsControllerService) { }

  ngOnInit() {
    this.getTransactions()
  }


  getTransactions(): void {
    this.loadingTransactions = true;
    this.transactionsController.getTransactions({
      limit: 6
    }).subscribe({
      next: (result) => {
        this.transactions = result
      }
    }).add(() => this.loadingTransactions = false);
  }

  transactionStatus(transaction: TransactionPojo): string {
    return TransactionsHelper.transactionStatus(transaction);
  }

  statusColor(transaction: TransactionPojo): string {
    return TransactionsHelper.statusColor(transaction);
  }

  imageIcon(transaction: TransactionPojo) {
    return TransactionsHelper.getProviderIcon(transaction);
  }

  viewTransactionDetails(transaction: TransactionPojo): void {
    this.router.navigate(['/transactions', transaction.invoiceNumber, 'details'], {
      state: {
        transaction
      }
    });
  }
}

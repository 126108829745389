import { Component, Input, OnInit } from '@angular/core';
import { AirtimeOrderDetailsPojo } from '../../../../../sdk/util-sdk';
import { Utils } from '../../../shared/utils/utils';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'airtime-order-details',
    templateUrl: './airtime-order-details.component.html',
    styleUrls: ['./airtime-order-details.component.scss'],
    standalone: true,
    imports: [
        DatePipe,
        CurrencyPipe
    ]
})
export class AirtimeOrderDetailsComponent implements OnInit {

  @Input() showLogo = true;
  @Input() order: AirtimeOrderDetailsPojo;

  constructor() { }

  ngOnInit() {
    console.log(this.order);
  }

  icon(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkIconPath(disco);
  }

  name(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkName(disco);
  }

  now(): Date {
    return new Date();
  }


}

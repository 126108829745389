/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DataOrderDetailsPojo { 
    phoneNumber: string;
    serviceId: string;
    variationCode: string;
    total?: number;
    dataAmountMb?: number;
    validityDays?: number;
    provider?: DataOrderDetailsPojo.ProviderEnum;
    serviceType?: DataOrderDetailsPojo.ServiceTypeEnum;
}
export namespace DataOrderDetailsPojo {
    export type ProviderEnum = 'AIRTEL' | 'MTN' | 'GLO' | 'NINE_MOBILE' | 'SPECTRANET' | 'SMILE';
    export const ProviderEnum = {
        AIRTEL: 'AIRTEL' as ProviderEnum,
        MTN: 'MTN' as ProviderEnum,
        GLO: 'GLO' as ProviderEnum,
        NINE_MOBILE: 'NINE_MOBILE' as ProviderEnum,
        SPECTRANET: 'SPECTRANET' as ProviderEnum,
        SMILE: 'SMILE' as ProviderEnum
    };
    export type ServiceTypeEnum = 'ELECTRICITY' | 'AIRTIME' | 'DATA' | 'TV' | 'EDUCATION' | 'INSURANCE';
    export const ServiceTypeEnum = {
        ELECTRICITY: 'ELECTRICITY' as ServiceTypeEnum,
        AIRTIME: 'AIRTIME' as ServiceTypeEnum,
        DATA: 'DATA' as ServiceTypeEnum,
        TV: 'TV' as ServiceTypeEnum,
        EDUCATION: 'EDUCATION' as ServiceTypeEnum,
        INSURANCE: 'INSURANCE' as ServiceTypeEnum
    };
}



/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    enableSubscriptions?: boolean;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    accountLogoId?: number;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'SYSTEM' | 'USER';
    export const AccountTypeEnum = {
        SYSTEM: 'SYSTEM' as AccountTypeEnum,
        USER: 'USER' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'UPDATE_USER' | 'VIEW_USER' | 'DEACTIVATE_USER' | 'UPDATE_SETTING' | 'VIEW_SETTING' | 'VIEW_LOG' | 'REVIEW_REQUISITION';
    export const PermissionsEnum = {
        CREATE_USER: 'CREATE_USER' as PermissionsEnum,
        UPDATE_USER: 'UPDATE_USER' as PermissionsEnum,
        VIEW_USER: 'VIEW_USER' as PermissionsEnum,
        DEACTIVATE_USER: 'DEACTIVATE_USER' as PermissionsEnum,
        UPDATE_SETTING: 'UPDATE_SETTING' as PermissionsEnum,
        VIEW_SETTING: 'VIEW_SETTING' as PermissionsEnum,
        VIEW_LOG: 'VIEW_LOG' as PermissionsEnum,
        REVIEW_REQUISITION: 'REVIEW_REQUISITION' as PermissionsEnum
    };
}



<div class="section mt-2">
  @if (showLogo) {
    <div class="listed-detail mt-3">
      <div class="icon-wrapper">
        <img [src]="icon(order?.provider)" alt="image" class="imaged w48 rounded">
      </div>
      <h3 class="text-center mt-1">{{ name(order.provider) }}</h3>
    </div>
  }

  <ul class="listview flush transparent simple-listview no-space mt-3">


    <li>
      <strong>Phone Number</strong>
      <span>{{ order?.phoneNumber }}</span>
    </li>

    <li>
      <strong>Network</strong>
      <span>{{ name(order?.provider) }}</span>
    </li>


    <li>
      <strong>Date</strong>
      <span>{{ now() | date }}</span>
    </li>
    <li>
      <strong>Amount</strong>
      <h3 class="m-0 text-primary">{{ order?.total | currency : '₦' : 'symbol' : '1.0-0'}}</h3>
    </li>

  </ul>
</div>

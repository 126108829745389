import { Routes } from '@angular/router';
import {
  ElectricityComponent
} from './components/electricity/electricity.component';
import { SignupComponent } from './components/signup/signup/signup.component';
import { LoggedInGuard } from './shared/guards/logged-in.guard';
import { TransactionsComponent } from './components/transactions/transactions.component';
import {
  PaymentMethodComponent
} from './components/electricity/payment-method/payment-method.component';
import { HomeComponent } from './components/home/home.component';
import { TransactionComponent } from './components/electricity/transaction/transaction.component';
import { NotLoggedInGuard } from './shared/guards/not-logged-in.guard';
import {
  ElectricityOrderComponent
} from './components/electricity/electricity-order/electricity-order.component';
import { AirtimeComponent } from './components/airtime/airtime.component';
import { AirtimeOrderComponent } from './components/airtime/airtime-order/airtime-order.component';
import { TransactionsListComponent } from './components/transactions/transactions-list/transactions-list.component';
import {
  TransactionDetailsComponent
} from './components/transactions/transaction-details/transaction-details.component';
import { DataComponent } from './components/data/data.component';
import { DataOrderComponent } from './components/data/data-order/data-order.component';
import { TelevisionComponent } from './components/television/television.component';
import { TelevisionOrderComponent } from './components/television/television-order/television-order.component';

export const routes: Routes = [
  {
    path: '',
    component: SignupComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'dashboard',
    component: HomeComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'airtime',
    component: AirtimeComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        component: AirtimeOrderComponent
      },
      {
        path: 'pay',
        component: PaymentMethodComponent,
        title: 'Payment Method'
      }
    ]
  },
  {
    path: 'data',
    component: DataComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        component: DataOrderComponent
      }
    ]
  },
  {
    path: 'television',
    component: TelevisionComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        component: TelevisionOrderComponent,
        data: {
          title: 'Television'
        }

      }
    ]
  },
  {
    path: 'electricity',
    component: ElectricityComponent,
    data: {
      title: 'Electricity'
    },
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        component: ElectricityOrderComponent,

      },
      {
        path: 'pay',
        component: PaymentMethodComponent,
        title: 'Payment Method'
      },
      {
        path: 'transaction/:reference',
        component: TransactionComponent,
        title: 'Transaction'
      }
    ]
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    data: {
      title: 'Transactions'
    },
    children: [
      {
        path: '',
        component: TransactionsListComponent
      },
      {
        path: ':reference/details',
        component: TransactionDetailsComponent,
        data: {
          title: 'Transaction Details'
        }

      }
    ]
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [LoggedInGuard]
  }
];

/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SmartCardPojo } from './smart-card-pojo';


export interface TvOrderDetailsPojo { 
    smartCard?: SmartCardPojo;
    phoneNumber?: string;
    amount?: number;
    serviceCharge?: number;
    total?: number;
    quantity?: number;
    serviceId: string;
    variationCode?: string;
    renewal?: boolean;
    voucherCode?: string;
    serviceType?: TvOrderDetailsPojo.ServiceTypeEnum;
    provider?: TvOrderDetailsPojo.ProviderEnum;
}
export namespace TvOrderDetailsPojo {
    export type ServiceTypeEnum = 'ELECTRICITY' | 'AIRTIME' | 'DATA' | 'TV' | 'EDUCATION' | 'INSURANCE';
    export const ServiceTypeEnum = {
        ELECTRICITY: 'ELECTRICITY' as ServiceTypeEnum,
        AIRTIME: 'AIRTIME' as ServiceTypeEnum,
        DATA: 'DATA' as ServiceTypeEnum,
        TV: 'TV' as ServiceTypeEnum,
        EDUCATION: 'EDUCATION' as ServiceTypeEnum,
        INSURANCE: 'INSURANCE' as ServiceTypeEnum
    };
    export type ProviderEnum = 'DSTV' | 'GOTV' | 'STARTIMES' | 'SHOWMAX';
    export const ProviderEnum = {
        DSTV: 'DSTV' as ProviderEnum,
        GOTV: 'GOTV' as ProviderEnum,
        STARTIMES: 'STARTIMES' as ProviderEnum,
        SHOWMAX: 'SHOWMAX' as ProviderEnum
    };
}



import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { PasswordValidator } from "../../../validators/password.validator";
import { ConfirmFieldValidator } from "../../../validators/confirm-field-validator";
import { PasswordCustomErrorComponent } from '../password-custom-error/password-custom-error.component';

@Component({
    selector: 'password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.css'],
    imports: [
        PasswordCustomErrorComponent,
        ReactiveFormsModule
    ]
})
export class PasswordComponent implements OnInit {
  form: FormGroup;
  @Input() validate$: BehaviorSubject<boolean>;
  @Output() password: EventEmitter<string> = new EventEmitter();

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      password: ['',
      [
        Validators.required,
        PasswordValidator.mustContain(/[a-z]/,  { noLowerCase: true }),
        PasswordValidator.mustContain(/[A-Z]/,  { noUpperCase: true }),
        PasswordValidator.mustContain(/[0-9]/,  { noNumber: true }),
        PasswordValidator.mustContain(/[\W_]/,  { noSymbol: true }),
        PasswordValidator.mustBeValidLength(6,  { invalidLengthMin: true }),
        PasswordValidator.mustBeValidLength(15, { invalidLengthMax: true }),
      ],
    ],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        ConfirmFieldValidator.matchField('password', 'passwordMismatch')
      ])],
    });

    this.form.controls.password.valueChanges.pipe(debounceTime(500)).subscribe(v => {
      this.form.controls.password.markAsTouched();
      this.form.controls.confirmPassword.reset();
      this.emitPassword();
    });

    this.form.controls.confirmPassword.valueChanges.pipe(debounceTime(500)).subscribe(v => {
      this.form.controls.confirmPassword.markAsTouched();
      this.emitPassword();
    });

    if(this.validate$) {
      this.validate$.subscribe(v=>{
        if(v){
          for (let controlsKey in this.form.controls) {
            this.form.controls[controlsKey].markAsDirty();
            this.form.controls[controlsKey].markAsTouched();
          }
        }
      })
    }
  }

  emitPassword() {
    if (this.form.valid) {
      this.password.emit(this.form.value.password);
    } else {
      this.password.emit('');
    }
  }

}

<div id="appCapsule">
  <div class="section mt-3">
    <div class="section-title">Network Provider</div>
    <div class="row">
      @for (provider of providers; track provider) {
        <div class="col-3">
          <a (click)="selectProvider(provider)">
            <div class="service-box" [class.highlighted]="selectedProvider == provider">
              <img [src]=icon(provider) alt="" />
            </div>
            <h5 class="mt-2 text-center dark-text">{{ name(provider) }}</h5>
          </a>
        </div>
      }
    </div>
    <div class="mt-2">
      @if (selectedProvider != TvProvider.SHOWMAX) {
        <div class="title-row">
          <div class="section-title">Smart Card Number</div>
          <span class="beneficiaries" (click)="selectBeneficiary()"> Beneficiaries
              <ion-icon class="beneficiaries ion-icon" name="chevron-forward-outline" />
          </span>
        </div>
        <form [formGroup]="form">
          <div class="form-group basic">
            <div class="input-wrapper">
              <input
                class="form-control"
                [formControlName]="'cardNumber'"
                [type]="'text'"
                [placeholder]="'Enter Card Number'">
              @if (validatingSmartCard) {
                <div class="form-spinner-wrapper">
                  <i class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></i>
                </div>
              }
            </div>
            <error-message
              [controlName]="'cardNumber'"
              [singularError]="true"
              [form]="form"
            />
          </div>
        </form>
      }

      @if (selectedCard) {
        <div class="section full mb-3">
          <div class="section-title">Card Details</div>
          <div class="card">
            <div class="card-body">
              <smart-card-details [smartCard]="selectedCard" [showLogo]="false" />
            </div>
          </div>
        </div>
        @if (canRenew) {
          <div class="card mt-2">
            <ul class="listview image-listview transparent flush">
              <li>
                <div class="item">
                  <div class="icon-box bg-success">
                    <ion-icon [name]="'refresh-outline'"></ion-icon>
                  </div>
                  <div class="in" [formGroup]="form">
                    <div class="fontsize-sub">Will you like to renew this subscription?</div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="SwitchCheckDefault2"
                             [formControlName]="'renewal'" />
                      <label class="form-check-label" for="SwitchCheckDefault2"></label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            @if (isRenewal) {
              <ul class="listview flush transparent simple-listview">
                <li>
                  <strong>Renewal Amount</strong>
                  <h3 class="m-0 text-success">
                    {{ selectedCard?.renewalAmount | currency : '₦' : 'symbol' : '1.0-0' }}
                  </h3>
                </li>
              </ul>
            }
          </div>
        }

      }


      @if (!isRenewal && (selectedCard || selectedProvider == TvProvider.SHOWMAX)) {
        <div class="section full mb-3">
          <div class="section-title">Bouquets</div>
          <!-- carousel small -->
          <div class="carousel-small splide">
            <div class="splide__track">
              <ul class="splide__list">
                @for (plan of providerPlans; track plan) {
                  <li class="splide__slide">
                    @for (i of plan; track i) {
                      <div class="plan-box" [class.selected]="planSelected(i)"
                           (click)="selectPlan(i)">
                        <div class="title"> {{ i.name }}</div>
                        <div class="value"> {{ i.amount | currency :  '₦' : 'symbol' : '1.0-0' }}</div>
                      </div>
                    }
                  </li>
                }
              </ul>
            </div>
          </div>
          <!-- * carousel small -->
        </div>
      }

      <button
        (click)="validateOrder();"
        class="btn btn-outline-primary btn-block btn-lg mt-2">
        @if (validatingOrder) {
          <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
        }
        Pay
      </button>
    </div>
  </div>
</div>

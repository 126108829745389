import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IonicModule } from '@ionic/angular';
import {
  ElectricityOrderDetailsPojo
} from '../../../../../sdk/util-sdk';
import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { OrderDetailsComponent } from '../order-details/order-details.component';

@Component({
    selector: 'order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.scss'],
    imports: [
        IonicModule,
        OrderDetailsComponent
    ]
})
export class OrderDialogComponent implements OnInit {

  @Input() order: ElectricityOrderDetailsPojo;

  constructor(private modalRef: BsModalRef,
              private router: Router) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalRef.hide();
  }

  selectPaymentMethod(): void {
    this.router.navigate(['/electricity/pay'], {
      state: {
        order: this.order
      }
    })
    this.close();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DiscoPojo, ElectricityOrderDetailsPojo } from '../../../../../sdk/util-sdk';
import { Utils } from '../../../shared/utils/utils';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'electricity-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  standalone: true,
  imports: [
    DatePipe,
    CurrencyPipe
  ]
})
export class OrderDetailsComponent implements OnInit {

  @Input() showLogo = true;
  @Input() order: ElectricityOrderDetailsPojo;

  constructor() {
  }

  ngOnInit() {
  }

  icon(disco: DiscoPojo.DiscoEnum): string {
    return Utils.discoIconPath(disco);
  }

  name(disco: DiscoPojo.DiscoEnum): string {
    return Utils.discoName(disco);
  }

  now(): Date {
    return new Date();
  }


}

<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Order Details</h5>
    <a (click)="close()">Close</a>
  </div>
  <div class="modal-body">
    @switch (order.serviceType) {
      @case (ServiceHead.AIRTIME) {
        <airtime-order-details [order]="order"/>
      }
      @case (ServiceHead.DATA) {
        <data-order-details [order]="order"/>
      }
      @case (ServiceHead.TV) {
        <television-order-details [order]="order"/>
      }
    }
    <button
      (click)="selectPaymentMethod()"
      class="btn btn-outline-primary btn-block btn-lg mt-2">
      Pay
    </button>
  </div>
</div>

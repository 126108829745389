/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeterPojo } from './meter-pojo';


export interface ElectricityOrderDetailsPojo { 
    serviceType?: ElectricityOrderDetailsPojo.ServiceTypeEnum;
    meterDetails?: MeterPojo;
    phoneNumber?: string;
    amount?: number;
    serviceCharge?: number;
    discount?: number;
    total?: number;
    token?: string;
}
export namespace ElectricityOrderDetailsPojo {
    export type ServiceTypeEnum = 'ELECTRICITY' | 'AIRTIME' | 'DATA' | 'TV' | 'EDUCATION' | 'INSURANCE';
    export const ServiceTypeEnum = {
        ELECTRICITY: 'ELECTRICITY' as ServiceTypeEnum,
        AIRTIME: 'AIRTIME' as ServiceTypeEnum,
        DATA: 'DATA' as ServiceTypeEnum,
        TV: 'TV' as ServiceTypeEnum,
        EDUCATION: 'EDUCATION' as ServiceTypeEnum,
        INSURANCE: 'INSURANCE' as ServiceTypeEnum
    };
}



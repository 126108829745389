import { AirtimeOrderDetailsPojo, DiscoPojo, SmartCardPojo, TransactionPojo } from '../../../../sdk/util-sdk';
import PaymentTransactionStatus = TransactionPojo.PaymentTransactionStatusEnum;
import TransactionStatus = TransactionPojo.ServiceTransactionStatusEnum;
import PaymentStatus = TransactionPojo.InvoicePaymentStatusEnum;
import { Utils } from '../../shared/utils/utils';
import DiscoEnum = DiscoPojo.DiscoEnum;
import ProviderEnum = AirtimeOrderDetailsPojo.ProviderEnum;
import ServiceHead = TransactionPojo.ServiceHeadEnum;

export class TransactionsHelper {

  public static transactionStatus(transaction: TransactionPojo): string {
    if (transaction.invoicePaymentStatus == PaymentStatus.PAID) {
      switch (transaction.serviceTransactionStatus) {

        case TransactionStatus.DELIVERED:
          return 'Successful';

        case TransactionStatus.UNSTARTED:
        case TransactionStatus.PENDING:
        case TransactionStatus.INITIATED:
          return 'Pending';

        case TransactionStatus.FAILED:
          return 'Failed';
        default:
          return ''
      }
    } else {
      const paymentStatus = transaction.paymentTransactionStatus;
      switch (paymentStatus) {
        case PaymentStatus.PENDING:
          return 'Pending Payment';
        case PaymentTransactionStatus.CANCELED:
          return 'Payment Cancelled';
        case PaymentTransactionStatus.FAILED:
          return 'Payment Failed';
        default:
          return '';

      }
    }
  }

  public static name(transaction): string {
    switch (transaction.serviceHead) {
      case ServiceHead.ELECTRICITY:
        return Utils.discoName(transaction.transactionLineItem as DiscoEnum);
      case ServiceHead.AIRTIME:
      case ServiceHead.DATA:
        return Utils.networkName(transaction.transactionLineItem as ProviderEnum);
      case ServiceHead.TV:
        return Utils.tvName(transaction.transactionLineItem as SmartCardPojo.ProviderEnum);
      default:
        return '';
    }
  }

  public static action(transaction: TransactionPojo): TransactionAction {
    switch (this.transactionStatus(transaction)) {
      case 'Pending Payment':
      case 'Payment Cancelled':
      case 'Payment Failed':
        return TransactionAction.Pay;
      case 'Pending':
        return TransactionAction.Refresh;
      case 'Failed':
        return TransactionAction.Retry;
      default:
        return null;
    }
  }

  public static actionIcon(transaction: TransactionPojo): string {
    switch (this.action(transaction)) {
      case TransactionAction.Pay:
        return 'card-outline';
      case TransactionAction.Refresh:
        return 'refresh-outline';
      case TransactionAction.Retry:
        return 'refresh-outline';
      default:
        return '';
    }
  }

  public static statusIcon(transaction: TransactionPojo): string {
    switch (this.transactionStatus(transaction)) {
      case 'Successful':
        return 'checkmark-circle-outline';
      case 'Pending':
        return 'time-outline';
      case 'Failed':
        return 'close-circle-outline';
      case 'Pending Payment':
        return 'time-outline';
      case 'Payment Cancelled':
        return 'close-circle-outline';
      case 'Payment Failed':
        return 'close-circle-outline';
      default:
        return '';
    }
  }

  public static statusColor(transaction: TransactionPojo): string {
    switch (this.transactionStatus(transaction)) {
      case 'Successful':
        return 'text-success';
      case 'Pending':
        return 'text-warning';
      case 'Pending Payment':
        return 'text-warning';
      case 'Failed':
        return 'text-danger';
      case 'Payment Failed':
        return 'text-danger';
      case 'Payment Cancelled':
        return 'text-danger';
      default:
        return '';
    }
  }

  public static getProviderIcon(transaction: TransactionPojo): string {
    switch (transaction.serviceHead) {
      case ServiceHead.ELECTRICITY:
        return Utils.discoIconPath(transaction.transactionLineItem as DiscoEnum);
      case ServiceHead.AIRTIME:
      case ServiceHead.DATA:
        return Utils.networkIconPath(transaction.transactionLineItem as ProviderEnum);
      case ServiceHead.TV:
        return Utils.tvIconPath(transaction.transactionLineItem as SmartCardPojo.ProviderEnum);
      default:
        return '';
    }
  }
}

export enum TransactionAction {
  Pay = 'Pay', Refresh = 'Refresh', Retry = 'Retry'
}

import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection, inject, provideAppInitializer } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {HttpInterceptorService} from "./services/http-interceptor.service";
import {AuthenticationService} from "./services/authentication.service";
import { KeycloakEventType, KeycloakEventTypeLegacy, KeycloakService } from 'keycloak-angular';
import {environment} from "./environments/environment";
import {filter, mergeMap} from "rxjs/operators";
import { provideIonicAngular } from '@ionic/angular/standalone';
import {provideToastr} from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH } from '../../sdk/util-sdk';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({eventCoalescing: true}),
    KeycloakService,
    {
      provide: BASE_PATH,
      useValue: environment.siteUrl
    },
    provideAppInitializer(() => {
        const initializerFn = (initializeKeycloak)(inject(KeycloakService), inject(AuthenticationService));
        return initializerFn();
      }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    importProvidersFrom([BrowserAnimationsModule]),
    provideIonicAngular({}),
    provideToastr()
  ]
};

/**
 *
 * @param keycloak
 * @param authenticationService
 */
function initializeKeycloak(
  keycloak: KeycloakService,
  authenticationService: AuthenticationService
): () => Promise<boolean> {
  keycloak.keycloakEvents$
    .pipe(
      filter((value) => value.type === KeycloakEventTypeLegacy.OnAuthSuccess),
      mergeMap((value) => {
        return authenticationService.fetchUser();
      })
    )
    .subscribe();
  const orgOpen = window.open;
  window.open = function (
    url?: string,
    target?: string,
    features?: string,
    replace?: boolean
  ): WindowProxy | null {
    return orgOpen(url, target, features);
  };
  return (): Promise<boolean> => {
    return keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        enableLogging: !environment.production,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    });
  };
}

<div class="section mt-2">
  @if(showLogo){
    <div class="listed-detail mt-3">
      <div class="icon-wrapper">
        <img [src]="icon(order?.smartCard?.provider)" alt="image" class="imaged w48 rounded">
      </div>
      <h3 class="text-center mt-1">{{ name(order?.smartCard?.provider) }}</h3>
    </div>
  }
  <ul class="listview flush transparent simple-listview no-space mt-3">
    @if (order?.smartCard) {
      @if (order?.smartCard?.name ) {
        <li>
          <strong>Name</strong>
          <span>{{ order?.smartCard?.name }}</span>
        </li>
      }
      <li>
        <strong>Smart Card Number</strong>
        <span>{{ order?.smartCard?.cardNumber }}</span>
      </li>
    }

    <li>
      <strong>Date</strong>
      <span>{{ now() | date }}</span>
    </li>
    <li>
      <strong>Amount</strong>
      <h3 class="m-0 text-primary">{{ order?.amount | currency : '₦' : 'symbol' : '1.0-0'}}</h3>
    </li>
    <li>
      <strong>Service Charge</strong>
      <h3 class="m-0 text-primary">{{ order?.serviceCharge | currency : '₦' : 'symbol' : '1.0-0'}}</h3>
    </li>
    <li>
      <strong>Total</strong>
      <h3 class="m-0 text-success">{{ order?.total | currency : '₦' : 'symbol' : '1.0-0'}}</h3>
    </li>
  </ul>
</div>

@if (beneficiaries) {
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Select Beneficiary</h5>
      <a (click)="close()">Close</a>
    </div>
    <div class="modal-body">
      <ul class="listview image-listview inset">
        @for (beneficiary of beneficiaries; track beneficiary) {
          <li>
            <div class="item" (click)="selectBeneficiary(beneficiary)">
              <div class="in">
                <div>{{ beneficiary.name + ' | ' + beneficiary.meterNumber  }}</div>
                <input class="form-check-input" type="radio"
                       [checked]="beneficiary == selectedBeneficiary">
              </div>
            </div>
          </li>
        }
      </ul>
    </div>
  </div>
}

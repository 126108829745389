/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameCodePojo } from './name-code-pojo';


export interface SmartCardPojo { 
    name?: string;
    cardNumber?: string;
    status?: SmartCardPojo.StatusEnum;
    provider?: SmartCardPojo.ProviderEnum;
    dueDate?: string;
    renewalAmount?: number;
    currentBouquets?: Array<NameCodePojo>;
}
export namespace SmartCardPojo {
    export type StatusEnum = 'Open' | 'Closed';
    export const StatusEnum = {
        OPEN: 'Open' as StatusEnum,
        CLOSED: 'Closed' as StatusEnum
    };
    export type ProviderEnum = 'DSTV' | 'GOTV' | 'STARTIMES' | 'SHOWMAX';
    export const ProviderEnum = {
        DSTV: 'DSTV' as ProviderEnum,
        GOTV: 'GOTV' as ProviderEnum,
        STARTIMES: 'STARTIMES' as ProviderEnum,
        SHOWMAX: 'SHOWMAX' as ProviderEnum
    };
}



<div class="section">
  @if (showLogo) {
    <div class="listed-detail">
      <div class="icon-wrapper">
        <img [src]="icon(smartCard?.provider)" alt="image" class="imaged w48 rounded">
      </div>
      <h3 class="text-center mt-1">{{ name(smartCard?.provider) }}</h3>
    </div>
  }
  <div class="scale-down">
    <ul class="listview flush transparent simple-listview no-space fontsize-sub">
    <li>
      <strong>Name</strong>
      <span>{{ smartCard?.name }}</span>
    </li>

    <li>
      <strong>Smart Number</strong>
      <span>{{ smartCard?.cardNumber }}</span>
    </li>

    <li>
      <strong>Due Date </strong>
      <span>{{ smartCard?.dueDate | date }}</span>
    </li>
  </ul>
  </div>
</div>

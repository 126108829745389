<!-- App Capsule -->
<div id="appCapsule">

  <!-- Services -->
  <div class="section mt-4">
    <div class="section-heading">
      <h2 class="title">Services</h2>
<!--      <a href="app-transactions.html" class="link">View All</a>-->
    </div>
    <div class="row">
      <div class="col-3">
        <a [routerLink]="'/electricity'">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-activity service-icon">
              <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Electricity</h5>
        </a>
      </div>

      <div class="col-3">
        <a [routerLink]="'/airtime'">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-tablet service-icon">
              <rect x="4" y="2" width="16" height="20" rx="2" ry="2"></rect>
              <line x1="12" y1="18" x2="12.01" y2="18"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Airtime</h5>
        </a>
      </div>
      <div class="col-3">
        <a [routerLink]="'/data'">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-wifi service-icon">
              <path d="M5 12.55a11 11 0 0 1 14.08 0"></path>
              <path d="M1.42 9a16 16 0 0 1 21.16 0"></path>
              <path d="M8.53 16.11a6 6 0 0 1 6.95 0"></path>
              <line x1="12" y1="20" x2="12.01" y2="20"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Mobile Data</h5>
        </a>
      </div>

      <div class="col-3">
        <a [routerLink]="'/television'">
          <div class="service-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-monitor service-icon">
              <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
              <line x1="8" y1="21" x2="16" y2="21"></line>
              <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
          </div>
          <h5 class="mt-2 text-center dark-text">Television</h5>
        </a>
      </div>
    </div>
  </div>
  <!-- * Services -->

  <!-- Transactions -->
  <div class="section mt-4">
    <div class="section-heading">
      <h2 class="title">Transactions</h2>
      <a class="link" [routerLink]="'/transactions'">View All</a>
    </div>
    <div class="transactions">
      @if(transactions) {
        @for(transaction of transactions.results; track $index) {
          <a class="item" (click)="viewTransactionDetails(transaction)">
            <div class="detail">
              <img [src]="imageIcon(transaction)" alt="img" class="image-block imaged w48">
              <div>
                <strong>{{ transaction.transactionLineItem }}</strong>
                <p>{{ transaction.serviceHead | uppercase }}</p>
              </div>
            </div>
            <div class="right text-end">
              <div class="price"> {{ transaction?.amount | currency : '₦' : 'symbol' : '1.0-0' }} </div>
              <div class="fontsize-sub" [ngClass]="statusColor(transaction)">
                {{ transactionStatus(transaction) }}
              </div>
            </div>
          </a>
        }
      }
    </div>
  </div>
  <!-- * Transactions -->
</div>
<!-- * App Capsule -->

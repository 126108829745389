import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of, BehaviorSubject, timer } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  MeterValidationControllerService,
  MeterPojo,
  ElectricityOrderDto,
  SmartCardPojo, SmartCardValidationControllerService
} from '../../../../sdk/util-sdk';
import DiscoEnum = ElectricityOrderDto.DiscoEnum;

@Injectable({
  providedIn: 'root'
})
export class SmartCardValidator {

  private latestResponseSubject: BehaviorSubject<SmartCardPojo | null> = new BehaviorSubject<SmartCardPojo | null>(null);
  private isValidating: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private validationService: SmartCardValidationControllerService) {}

  smartCardExistsValidator(form: FormGroup): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      const provider = form?.controls?.provider?.value;

      return timer(500).pipe(
        distinctUntilChanged(),
        tap(() => this.isValidating.next(true)), // Start loader
        switchMap(() =>
          this.validationService.validateSmartCardNumber({
            smartCardValidationDto: { cardNumber: control.value, provider }
          }).pipe(
            map((v: SmartCardPojo) => {
              this.latestResponseSubject.next(v);
              this.isValidating.next(false);
              return v && v.name ? null : { invalidMeter: 'Invalid smart card number' };
            }),
            catchError((error) => {
              this.isValidating.next(false);
              this.latestResponseSubject.next(null);
              return this.handleError(error);
            })
          )
        )
      );
    };
  }

  handleError(error: any): Observable<ValidationErrors | null> {
    if (error.status === 404) {
      return of({ notFound: true });
    } else if (error.status === 401) {
      return of({ unauthorized: true });
    } else if (error.status >= 500) {
      return of({ serverError: true });
    } else if (error.status === 400) {
      const message = error.error?.message || 'Invalid smart card number';
      return of({ invalidRequest: message });
    }
    return of({ unknownError: true });
  }

  getLatestValidationResponse(): Observable<SmartCardPojo | null> {
    return this.latestResponseSubject.asObservable();
  }

  getValidationOngoing(): Observable<boolean> {
    return this.isValidating.asObservable();
  }

  clearLatestValidationResponse(): void {
    this.latestResponseSubject.next(null);
  }
}

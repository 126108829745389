import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-television',
  templateUrl: './television.component.html',
  styleUrls: ['./television.component.scss'],
  imports: [
    RouterOutlet
  ],
  standalone: true
})
export class TelevisionComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}

/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { QueryResultsTransactionPojo } from '../model/query-results-transaction-pojo';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetTransactionsRequestParams {
    datePaid?: string;
    dueOn?: string;
    purpose?: string;
    description?: string;
    cumulativeAmountPaid?: number;
    totalGrossAmount?: number;
    paymentLink?: string;
    type?: 'ELECTRICITY' | 'AIRTIME' | 'DATA' | 'TV' | 'EDUCATION' | 'INSURANCE';
    dispatchedBy?: string;
    portalAccount?: string;
    createdAt?: string;
    generatedOn?: string;
    qrCode?: string;
    provider?: 'VT_PASS';
    totalAmountPayable?: number;
    invoiceNumber?: string;
    currency?: string;
    id?: number;
    paymentStatus?: 'PENDING' | 'PAID' | 'PARTLY_PAID';
    identifier?: string;
    workflow?: string;
    totalDiscountAmount?: number;
    systemGenerated?: boolean;
    externalInvoice?: string;
    offset?: number;
    limit?: number;
    createdBefore?: string;
    createdAfter?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class TransactionsControllerService {

    protected basePath = 'http://localhost:3281';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransactions(requestParameters?: GetTransactionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsTransactionPojo>;
    public getTransactions(requestParameters?: GetTransactionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsTransactionPojo>>;
    public getTransactions(requestParameters?: GetTransactionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsTransactionPojo>>;
    public getTransactions(requestParameters?: GetTransactionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const datePaid = requestParameters?.datePaid;
        const dueOn = requestParameters?.dueOn;
        const purpose = requestParameters?.purpose;
        const description = requestParameters?.description;
        const cumulativeAmountPaid = requestParameters?.cumulativeAmountPaid;
        const totalGrossAmount = requestParameters?.totalGrossAmount;
        const paymentLink = requestParameters?.paymentLink;
        const type = requestParameters?.type;
        const dispatchedBy = requestParameters?.dispatchedBy;
        const portalAccount = requestParameters?.portalAccount;
        const createdAt = requestParameters?.createdAt;
        const generatedOn = requestParameters?.generatedOn;
        const qrCode = requestParameters?.qrCode;
        const provider = requestParameters?.provider;
        const totalAmountPayable = requestParameters?.totalAmountPayable;
        const invoiceNumber = requestParameters?.invoiceNumber;
        const currency = requestParameters?.currency;
        const id = requestParameters?.id;
        const paymentStatus = requestParameters?.paymentStatus;
        const identifier = requestParameters?.identifier;
        const workflow = requestParameters?.workflow;
        const totalDiscountAmount = requestParameters?.totalDiscountAmount;
        const systemGenerated = requestParameters?.systemGenerated;
        const externalInvoice = requestParameters?.externalInvoice;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const createdBefore = requestParameters?.createdBefore;
        const createdAfter = requestParameters?.createdAfter;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (datePaid !== undefined && datePaid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>datePaid, 'datePaid');
        }
        if (dueOn !== undefined && dueOn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dueOn, 'dueOn');
        }
        if (purpose !== undefined && purpose !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>purpose, 'purpose');
        }
        if (description !== undefined && description !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>description, 'description');
        }
        if (cumulativeAmountPaid !== undefined && cumulativeAmountPaid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cumulativeAmountPaid, 'cumulativeAmountPaid');
        }
        if (totalGrossAmount !== undefined && totalGrossAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>totalGrossAmount, 'totalGrossAmount');
        }
        if (paymentLink !== undefined && paymentLink !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentLink, 'paymentLink');
        }
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (dispatchedBy !== undefined && dispatchedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dispatchedBy, 'dispatchedBy');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (generatedOn !== undefined && generatedOn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>generatedOn, 'generatedOn');
        }
        if (qrCode !== undefined && qrCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>qrCode, 'qrCode');
        }
        if (provider !== undefined && provider !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>provider, 'provider');
        }
        if (totalAmountPayable !== undefined && totalAmountPayable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>totalAmountPayable, 'totalAmountPayable');
        }
        if (invoiceNumber !== undefined && invoiceNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceNumber, 'invoiceNumber');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (paymentStatus !== undefined && paymentStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentStatus, 'paymentStatus');
        }
        if (identifier !== undefined && identifier !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>identifier, 'identifier');
        }
        if (workflow !== undefined && workflow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>workflow, 'workflow');
        }
        if (totalDiscountAmount !== undefined && totalDiscountAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>totalDiscountAmount, 'totalDiscountAmount');
        }
        if (systemGenerated !== undefined && systemGenerated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>systemGenerated, 'systemGenerated');
        }
        if (externalInvoice !== undefined && externalInvoice !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>externalInvoice, 'externalInvoice');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/transactions`;
        return this.httpClient.request<QueryResultsTransactionPojo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-success-dialog',
    templateUrl: './success-dialog.component.html',
    styleUrls: ['./success-dialog.component.scss'],
    imports: [
        IonicModule
    ]
})
export class SuccessDialogComponent  implements OnInit {

  @Input() title: string = 'Success';
  @Input() message: string = 'Operation was successful';
  @Input() buttonText: string = 'Done';

  @Output() doneEvent = new EventEmitter<any>();

  constructor(private modalRef: BsModalRef) { }

  ngOnInit() {}

  done(): void {
    this.doneEvent.emit(true);
    this.modalRef.hide();
  }
}

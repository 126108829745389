<div id="appCapsule" class="full-height">
  <div class="section mt-2">
    <div class="card">
      <!-- Display order details if order is available -->
      @if (order) {
        <electricity-order-details [order]="order"/>
      }
    </div>
  </div>

  <!-- Display payment processing spinner if checkingPayment is true -->
  @if (checkingPayment) {
    <div class="section mt-2">
      <div class="card">
        <div class="d-flex justify-content-center mt-3">
          <div class="spinner-border d-flex" role="status"></div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <h3>Processing Payment</h3>
        </div>
      </div>
    </div>
  }

  <!-- Display fulfilling order spinner if fulfillingOrder is true -->
  @if (fulfillingOrder) {
    <div class="section mt-2">
      <div class="card">
        <div class="d-flex justify-content-center mt-3">
          <div class="spinner-border d-flex" role="status"></div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <h3>Fulfilling Order</h3>
        </div>
      </div>
    </div>
  }

  <!-- Display token information if token is available -->
  @if (token) {
    <div class="section mt-2">
      <div class="card">
        <div class="card-body">
          <h3>Token Received</h3>
          <p>Your electricity token: <strong>{{ token.electricityOrderDetails.token }}</strong></p>
<!--          <p>Units purchased: <strong>{{ token.units }}</strong></p>-->
<!--          <p>Transaction reference: <strong>{{ token.transactionRef }}</strong></p>-->
        </div>
      </div>
    </div>
  }
</div>

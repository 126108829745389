import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[MoneyInput]',
  standalone: true,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MoneyInputDirective),
    multi: true
  }]
})
export class MoneyInputDirective implements ControlValueAccessor {

  private onChange: (value: number) => void;
  private onTouched: () => void;
  private rawValue: string = '';

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.rawValue = value.replace(/,/g, '').replace(/[^0-9.]/g, '');
    const formattedValue = this.formatCurrency(this.rawValue);
    this.el.nativeElement.value = formattedValue;
    const numericValue = parseFloat(this.rawValue) || 0;
    this.onChange(numericValue);
  }

  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  writeValue(value: number): void {
    if (value) {
      this.rawValue = value.toString();
      const formattedValue = this.formatCurrency(this.rawValue);
      this.el.nativeElement.value = formattedValue;
    } else {
      this.el.nativeElement.value = '';
    }
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  private formatCurrency(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

<div id="appCapsule">
  <div class="section mt-3">
    <div class="section-title">Service Provider</div>
    <ul class="listview image-listview">
      <li>
        <a class="item" (click)="selectDisco()">
          <img [src]="discoIcon()" alt="image" class="image">
          <div class="in">
            <div>{{ discoName() }}</div>
          </div>
        </a>
      </li>
    </ul>
    <ul class="nav nav-tabs capsuled mt-3" role="tablist">
      @for (tab of tabData; track tab) {
        <li class="nav-item">
          <a class="nav-link" [class.active]="tab == activeTab" (click)="setActiveTab(tab)">
            {{ tab }}
          </a>
        </li>
      }
    </ul>
    <div class="mt-2">
      <div class="title-row">
        <div class="section-title">Meter Number</div>
        <span class="beneficiaries" (click)="selectBeneficiary()"> Beneficiaries
            <ion-icon class="beneficiaries ion-icon" name="chevron-forward-outline" />
        </span>
      </div>
      <form [formGroup]="form">
        <div class="form-group basic">
          <div class="input-wrapper">
            <input
              class="form-control"
              [formControlName]="'meterNumber'"
              [type]="'text'"
              [placeholder]="'Enter Meter Number'">
            @if (validatingMeter) {
              <div class="form-spinner-wrapper">
                <i class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></i>
              </div>
            }
          </div>
          <error-message
            [controlName]="'meterNumber'"
            [singularError]="true"
            [form]="form"
          />
        </div>
      </form>

      @if (selectedMeter) {
        <div class="card mt-1">
          <div class="card-body">
            {{ selectedMeter.name }}
          </div>
        </div>
      }

      <div class="section-title mt-2">Select Amount</div>

      <div class="row mt-2">
        @for (amount of preSelectAmounts; track amount) {
          <div class="col-4" (click)="selectAmount(amount)">
            <div class="amount-box" [class.selected]="amountSelected(amount)">
              <div class="value"> {{ amount | currency : '₦' : 'symbol' : '1.0-0'}} </div>
            </div>
          </div>
        }
      </div>
      <form [formGroup]="form" class="mt-3">
        <div class="form-group basic">
          <div class="input-wrapper">
            <input
              MoneyInput
              [formControlName]="'amount'"
              type="text" class="form-control" [placeholder]="'Enter Amount'">
            <i class="clear-input">
              <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
            </i>
          </div>
        </div>
      </form>
      <button
        (click)="validateOrder();"
        class="btn btn-outline-primary btn-block btn-lg mt-2">
        @if (validatingOrder) {
          <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
        }
        Pay
      </button>
    </div>
  </div>
</div>

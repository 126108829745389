import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  BuyTvControllerService,
  ServicePlan,
  SmartCardPojo,
  SmartCardValidationControllerService, TvOrderDetailsPojo, TvOrderDto, VariationsControllerService
} from '../../../../../sdk/util-sdk';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SharedModule } from '../../../shared/shared.module';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import ProviderEnum = SmartCardPojo.ProviderEnum;
import { UnsubscribeOnDestroyAdapter } from '../../../shared/utils/unsubscribe-on-destroy-adapter';
import { SmartCardValidator } from '../../../shared/validators/smart-card.validator';
import { Utils } from '../../../shared/utils/utils';
import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { EmComponent } from '../../../shared/components/em/em.component';
import { IonicModule } from '@ionic/angular';
import { BeneficiariesComponent } from '../../infra/beneficiaries/beneficiaries.component';
import { OrderDialogComponent } from '../../airtime/order-dialog/order-dialog.component';
import { SmartCardDetailsComponent } from '../smart-card-details/smart-card-details.component';
import ServiceHeadEnum = ServicePlan.ServiceHeadEnum;
import { addIcons } from 'ionicons';

declare var Splide: any

@Component({
  selector: 'television-order',
  templateUrl: './television-order.component.html',
  styleUrls: ['./television-order.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    CurrencyPipe,
    EmComponent,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    SmartCardDetailsComponent
  ]
})
export class TelevisionOrderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  form: FormGroup
  validatingSmartCard = false;
  validatingOrder = false;
  selectedCard: SmartCardPojo | null = null;

  beneficiariesSubject: Subject<SmartCardPojo[]> = new Subject();
  beneficiaries$: Observable<SmartCardPojo[]> = this.beneficiariesSubject.asObservable();

  loadingPlans: any = false;
  providers: ProviderEnum[] = Utils.enumValues(ProviderEnum);
  plans: Map<ProviderEnum, ServicePlan[]> = new Map<ProviderEnum, ServicePlan[]>();



  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private variationsService: VariationsControllerService,
              private tvPurchaseService: BuyTvControllerService,
              private cardValidator: SmartCardValidator,
              private modalService: BsModalService,
              private smartCardService: SmartCardValidationControllerService) {

    super();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      provider: [ProviderEnum.DSTV],
      cardNumber: [''],
      amount: [''],
      variationCode: [],
      renewal: []
    });

    this.form.controls.provider.valueChanges.subscribe(v => {

      this.cardValidator.clearLatestValidationResponse();

      this.form.patchValue({ cardNumber: '', amount: '', renewal: '', variationCode: '' });

      if (!this.plans.get(v)) this.getPlans(v);

      else this.selectProviderPlans();
    });

    this.form.patchValue({ provider: this.providers[0] });

    this.form.controls.cardNumber.addAsyncValidators([this.cardValidator.smartCardExistsValidator(this.form)]);

    this.subscriptions.sink = this.cardValidator.getLatestValidationResponse().subscribe((response) => {
      this.selectedCard = response;
      this.form.patchValue({
        renewal: this.canRenew,
        amount: this.canRenew ? this.selectedCard?.renewalAmount : 0
      });
    });

    this.subscriptions.sink = this.cardValidator.getValidationOngoing().subscribe((validatingSmartCard) => {
      this.validatingSmartCard = validatingSmartCard;
    });

    this.subscriptions.sink = this.form.controls.disco?.valueChanges.subscribe(() => {
      this.form.controls.cardNumber?.updateValueAndValidity();
    });

    this.subscriptions.sink = this.form.controls.renewal.valueChanges.subscribe((renewal) => {
      this.form.patchValue({ amount: renewal ? this.selectedCard?.renewalAmount : null });
      this.cdr.detectChanges();
      this.initializeSplide();
    });

  }

  validateOrder(): void {

    if (this.validatingOrder) {
      return;
    }

    if (this.validatingSmartCard) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    this.validatingOrder = true;

    const sub = this.subscriptions.sink = this.tvPurchaseService
      .validateTelevisionOrder({ tvOrderDto: this.getDto() })
      .subscribe({
        next: (order) => {
          this.showDetails(order);
        }, error: () => {}
      });

    sub.add(() => this.validatingOrder = false);

  }

  getDto(): TvOrderDto {
    const tvPlan = this.selectedServicePlan();
    const data = this.form.getRawValue() as TvOrderDto;
    data.serviceId = tvPlan?.serviceId || Utils.tvServiceCodes(this.selectedProvider);
    return data;
  }


  showDetails(order: TvOrderDetailsPojo): void {
    this.modalService.show(OrderDialogComponent, {
      initialState: {
        order
      },
      class: 'modal-bottom-drawer',
    });
  }

  private loadBeneficiaries(): void {
    this.smartCardService.getSmartCardBeneficiaries()
      .subscribe({
        next: (v: SmartCardPojo[]) => {
          this.beneficiariesSubject.next(v);
        }
      });
  }

  selectBeneficiary(): void {

    this.loadBeneficiaries();

    const ref = this.modalService.show(BeneficiariesComponent, {
      initialState: {
        beneficiaries$: this.beneficiaries$ as any
      },
      class: 'modal-bottom-drawer',
    });

    ref.content.selectedBeneficiaryChange.subscribe((beneficiary) => {
      this.form.patchValue({ validatingSmartCard: beneficiary.meterNumber });
    });

  }

  getPlans(provider: ProviderEnum): void {
    this.loadingPlans = provider;
    this.variationsService.getVtServiceVariations({ serviceCode: Utils.tvServiceCodes(provider), serviceType: ServiceHeadEnum.TV })
      .subscribe({
        next: (v: ServicePlan[]) => {
          this.plans.set(provider, v);
          this.selectProviderPlans();
        }
      }).add(() => this.loadingPlans = false);
  }

  selectedServicePlan(): ServicePlan | undefined {
    const variationCode = this.form.controls.variationCode.value;
    return this.plans.get(this.selectedProvider)
      ?.find(plan => plan.variationCode === variationCode);

  }

  icon(provider: ProviderEnum): string {
    return Utils.tvIconPath(provider);
  }

  name(name: ProviderEnum): string {
    return Utils.tvName(name);
  }

  selectProvider(provider: string): void {
    this.form.controls.provider.setValue(provider);
  }

  selectPlan(plan: ServicePlan): void {
    this.form.patchValue({ variationCode: plan.variationCode, amount: '' });
  }

  planSelected(plan: ServicePlan): boolean {
    return this.form.controls.variationCode.value === plan.variationCode;
  }

  get selectedProvider(): ProviderEnum {
    return this.form.controls.provider.value;
  }

  get canRenew(): boolean {
    return this.selectedCard?.renewalAmount > 0;
  }

  get isRenewal(): boolean {
    return this.form.controls?.renewal?.value;
  }

  providerPlans: ServicePlan[][];

  selectProviderPlans(): void {
    this.providerPlans = this.pairPlans(this.plans.get(this.selectedProvider) || []);
    this.cdr.detectChanges();
    this.initializeSplide();
  }

  pairPlans(plans: ServicePlan[]): ServicePlan[][] {
    return plans.reduce((acc, item, index) => {
      if (index % 2 === 0) {
        acc.push([item]);
      } else {
        acc[acc.length - 1].push(item);
      }
      return acc;
    }, []);
  }

  splideInstance;
  initializeSplide(): void {
    if (this.splideInstance) {
      this.splideInstance.destroy();
    }

    console.log(this.isRenewal, this.selectedCard);

    if (this.isRenewal || (!this.selectedCard && this.selectedProvider != this.TvProvider.SHOWMAX)) return;

    this.splideInstance = new Splide('.carousel-small',
      {
        perPage: 9,
        rewind: false,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 2
          },
          991: {
            perPage: 3
          }
        }
      }).mount();
    // this.setEqualHeight();

  }

  setEqualHeight(): void {
    const items = document.querySelectorAll('.splide__slide .plan-box');
    let maxHeight = 0;
    // Calculate the maximum height
    items.forEach(item => {
      const height = item.clientHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    // Set all items to the maximum height
    items.forEach(item => {
      (item as HTMLElement).style.height = `${maxHeight}px`;
    });
  }

  TvProvider = SmartCardPojo.ProviderEnum;

}

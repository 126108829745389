import { Component, Input, OnInit } from '@angular/core';
import { AirtimeOrderDetailsPojo, DataOrderDetailsPojo } from '../../../../../sdk/util-sdk';
import { Utils } from '../../../shared/utils/utils';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'data-order-details',
  templateUrl: './data-order-details.component.html',
  styleUrls: ['./data-order-details.component.scss'],
  imports: [
    CurrencyPipe,
    DatePipe
  ],
  standalone: true
})
export class DataOrderDetailsComponent  implements OnInit {

  @Input() showLogo = true;
  @Input() order: DataOrderDetailsPojo;

  constructor() { }

  ngOnInit() {
  }

  icon(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkIconPath(disco);
  }

  name(disco: AirtimeOrderDetailsPojo.ProviderEnum): string {
    return Utils.networkName(disco);
  }

  now(): Date {
    return new Date();
  }

  dataAmount(dataAmountMb: number): string {
    return Utils.dataPlanTitle(dataAmountMb);
  }

  duration(validityDays: number): string {
    return Utils.periodTitle(validityDays);
  }
}

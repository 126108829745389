/**
 * Tickets API
 * The Ticketing API RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TvOrderDto { 
    cardNumber?: string;
    amount?: number;
    provider?: TvOrderDto.ProviderEnum;
    renewal?: boolean;
    serviceId?: string;
    variationCode?: string;
    phoneNumber?: string;
}
export namespace TvOrderDto {
    export type ProviderEnum = 'DSTV' | 'GOTV' | 'STARTIMES' | 'SHOWMAX';
    export const ProviderEnum = {
        DSTV: 'DSTV' as ProviderEnum,
        GOTV: 'GOTV' as ProviderEnum,
        STARTIMES: 'STARTIMES' as ProviderEnum,
        SHOWMAX: 'SHOWMAX' as ProviderEnum
    };
}



import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'electricity-component',
    templateUrl: './electricity.component.html',
    styleUrls: ['./electricity.component.scss'],
    imports: [RouterOutlet]
})

export class ElectricityComponent {

}
